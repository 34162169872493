import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import 'whatwg-fetch';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Modal from 'components/Modals/Modal';
import { actions, select } from 'store/toolkit';

import { checkInvalidTextField } from 'utils/utils';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';

const RATING_OPTIONS = [
  {
    val: 1,
    label: 'Very difficult',
  },
  {
    val: 2,
    label: 'Somewhat difficult',
  },
  {
    val: 3,
    label: 'Neither easy nor difficult',
  },
  {
    val: 4,
    label: 'Somewhat easy',
  },
  {
    val: 5,
    label: 'Very easy',
  },
];

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    display: 'block',
    textAlign: 'left',
    fontWeight: 400,
    maxWidth: '90%',
    margin: '20px auto',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  feedbackText: {
    width: '100%',
    margin: '0 0 1rem',
    '& textarea': {
      lineHeight: 1.5,
    },
  },
  buttonSpacer: {
    margin: '10px 5px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 13px 0',
    },
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  ratingSelection: {
    background: theme.palette.grey[50],
    width: 40,
    height: 40,
    borderRadius: '100%',
    lineHeight: 0,
    position: 'relative',
    border: `2px solid ${theme.palette.grey[600]}`,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: 52,
      height: 52,
    },
    '& span:first-child': {
      position: 'absolute',
      bottom: ' 50%',
      width: '100%',
      textAlign: 'center',
      fontSize: '1.5rem',
    },
    '@media (forced-colors: active)': {
      '&:focus': {
        borderWidth: 5,
      },
      '&$selected': {
        fontWeight: 900,
      },
    },
    '&:focus-visible': {
      outlineOffset: 2,
    },
  },
  selected: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '& span': {
      color: theme.palette.common.white,
    },
  },
  textFieldLabel: {
    display: 'inline-block',
    padding: '13px 0 5px',
  },
  checkmark: {
    width: 50,
    height: 50,
  },
}));

const FEEDBACK_MODAL_ID = 'share-feedback';
const FEEDBACK_FORM_TITLE = 'share-feedback-description';

function ShareFeedback() {
  const dispatch = useDispatch();

  const appName = useSelector(select.content.appName);

  // axios instance
  const axios = useSelector(select.axios.axiosInstance);

  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [message, setMessage] = useState('');
  const [ratingSelection, setRatingSelection] = useState(null);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [messageError, setMessageError] = useState('');

  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const feedbackProvided = ratingSelection !== null;

  const handleCheckMessage = ({ target: { value } }) => {
    const errorMessage = checkInvalidTextField(value);
    setMessageError(errorMessage);
    setInvalidMessage(Boolean(errorMessage));
  };

  const handleChangeMessage = ({ target: { value } }) => {
    setMessage(value);
  };

  const feedbackSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSending(false); // hide intersitial
      setSuccess(false); // reset interstitial text and image
      dispatch(actions.ui.closeFeedbackModal()); // hide feedback modal and set local storage
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      overallExperience: ratingSelection,
      message,
    };

    if (feedbackProvided) {
      setSending(true);

      axios
        .post('/feedback/', payload)
        .then(() => {
          feedbackSuccess();
        })
        .catch((error) => {
          Sentry.captureException(error);
          feedbackSuccess();

          console.error(error);
        });
    }
  };

  const handleSelect = (val) => {
    setRatingSelection(val);
  };

  const handleKeyDown = (e, val) => {
    if (e.key === ' ' || e.key === 'Enter') {
      handleSelect(val);
    }
  };

  return (
    <>
      <Modal
        open
        handleClose={() => dispatch(actions.ui.closeFeedbackModal())}
        fullWidth
        ariaId={FEEDBACK_MODAL_ID}
        fullScreen={smDown}
      >
        <Typography variant="srOnly" id={`${FEEDBACK_MODAL_ID}-title`}>
          Share feedback on {appName}
        </Typography>
        <Container component="form" maxWidth="sm" style={{ padding: smDown ? 20 : 0 }}>
          <div role="radiogroup" aria-labelledby={FEEDBACK_FORM_TITLE}>
            <Typography id={FEEDBACK_FORM_TITLE} variant="h2" className={classes.modalTitle}>
              How easy was it to find what you were looking for?
            </Typography>

            <Grid container justifyContent="center">
              <div className={classes.innerWrapper}>
                <Grid container justifyContent="center">
                  {RATING_OPTIONS.map(({ val, label }) => (
                    <Grid item key={val} className={classes.buttonSpacer}>
                      <div
                        role="radio"
                        tabIndex="0"
                        aria-checked={ratingSelection === val}
                        onClick={() => handleSelect(val)}
                        onKeyDown={(e) => handleKeyDown(e, val)}
                        className={`${classes.ratingSelection} ${
                          ratingSelection === val && classes.selected
                        }`}
                      >
                        <span>{val}</span>
                        <Typography variant="srOnly">{label}</Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Grid container justifyContent="space-between" aria-hidden="true">
                  <span>{RATING_OPTIONS[0].label}</span>
                  <span>{RATING_OPTIONS[RATING_OPTIONS.length - 1].label}</span>
                </Grid>

                <Typography
                  className={classes.textFieldLabel}
                  component="label"
                  htmlFor="feedback-message"
                >
                  How can we improve?
                </Typography>

                <TextField
                  id="feedback-message"
                  multiline
                  value={message}
                  minRows="5"
                  onChange={handleChangeMessage}
                  error={invalidMessage}
                  helperText={messageError}
                  onBlur={handleCheckMessage}
                  margin="dense"
                  variant="outlined"
                  className={classes.feedbackText}
                  inputProps={{
                    maxLength: 1024,
                  }}
                />

                <Button
                  disabled={!feedbackProvided || invalidMessage}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    display: 'block',
                    margin: '1rem auto 20px',
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </div>
        </Container>
      </Modal>

      <Modal open={sending} ariaId="feedback-success" showX={false}>
        <div style={{ textAlign: 'center', padding: '8px 24px' }}>
          <Typography id="feedback-success-title" style={{ paddingBottom: 10 }}>
            {success ? 'Thank you!' : 'Sending...'}
          </Typography>
          {success ? (
            <CheckOrXIcon checked className={classes.checkmark} />
          ) : (
            <CircularProgress size={50} thickness={2} />
          )}
        </div>
      </Modal>
    </>
  );
}

export default ShareFeedback;
