import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';

import { select, thunks } from 'store/toolkit';
import { ASSISTANT } from 'store/slices/chat/chatConstants';
import TextInput from 'components/Forms/TextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import CheckboxInput from 'components/Forms/CheckboxInput';
import AutocompleteInput from 'components/Forms/AutocompleteInput';

const useStyles = makeStyles((theme) => ({
  rejectedButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  chip: { marginRight: theme.spacing(0.5) },
  thunkButton: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

export default function AnalyzeChatTool() {
  const classes = useStyles();
  const [errorText, setErrorText] = useState('');

  const { latitude, longitude } = useSelector(select.location.latLong);
  const city = useSelector(select.location.city);
  const stateAbbreviation = useSelector(select.location.state);
  const sendingChatOverview = useSelector(select.chat.sendingChatOverview);

  const specialties = useSelector(select.specialties.providerSpecialtiesArray);
  const specialtyOptions = useMemo(
    () =>
      specialties.map(({ specialtyName, specialtyId }) => ({
        specialtyName,
        specialtyId,
      })),
    [specialties]
  );
  const subspecialties = useSelector(select.specialties.providerSubspecialtiesArray);
  const subspecialtyOptions = useMemo(
    () =>
      subspecialties.map(({ subspecialtyName, subspecialtyId, parentSpecialtyId }) => ({
        subspecialtyName,
        subspecialtyId,
        specialtyId: parentSpecialtyId,
        specialtyName: specialties[parentSpecialtyId]?.specialtyName,
      })),
    [subspecialties, specialties]
  );

  const dispatch = useDispatch();

  const handlePending = useCallback(() => dispatch(thunks.chat.analyzeChat.pending()), [dispatch]);

  const handleErrorTextInput = useCallback((e) => setErrorText(e.target.value), [setErrorText]);

  const handleRejected = useCallback(() => {
    dispatch(thunks.chat.analyzeChat.rejected(errorText));
    setErrorText('');
  }, [dispatch, errorText, setErrorText]);

  const handleDispatchThunk = useCallback(() => dispatch(thunks.chat.analyzeChat()), [dispatch]);

  const handleSubmit = useCallback(
    async (values, formikTools) => {
      const { content, latitude: lat, longitude: lon, end, isEmergency, ...rest } = values;
      dispatch(
        thunks.chat.analyzeChat.fulfilled({
          chatResponse: { content: content, role: ASSISTANT },
          end,
          isEmergency,
          parameters: {
            location: { lat, lon },
            ...rest,
          },
        })
      );

      formikTools?.resetForm();
    },
    [dispatch]
  );

  const formInitialValues = {
    content: '',
    isEmergency: false,
    end: true,
    radiusInMiles: 25,
    latitude,
    longitude,
    city,
    stateAbbreviation,
    specialties: [],
    subspecialties: [],
  };

  const buttonProps = { size: 'small', variant: 'contained' };
  const gridContainers = { spacing: 1 };
  const textInputProps = { variant: 'standard', size: 'small' };

  return (
    <>
      {/* Pending */}
      <Grid container {...gridContainers}>
        <Grid
          item
          xs={12}
          component={Button}
          onClick={handlePending}
          color="secondary"
          {...buttonProps}
        >
          Dispatch Pending
        </Grid>
      </Grid>

      {/* Fulfilled */}
      <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
        <Grid container component={Form} autoComplete="off" {...gridContainers}>
          <Grid item xs={12}>
            <CheckboxInput name="isEmergency" label="Emergency" CheckboxProps={{ size: 'small' }} />
            <CheckboxInput name="end" label="End" CheckboxProps={{ size: 'small' }} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            component={AutocompleteInput}
            name="specialties"
            multiple
            options={specialtyOptions}
            defaultValue={formInitialValues.specialties}
            getOptionLabel={(opt) => opt.specialtyName}
            ChipProps={{ size: 'small' }}
            renderInput={(params) => <TextField {...params} label="Specialties" />}
          />

          <Grid
            item
            xs={12}
            sm={6}
            component={AutocompleteInput}
            name="subspecialties"
            multiple
            options={subspecialtyOptions}
            defaultValue={formInitialValues.subspecialties}
            getOptionLabel={(opt) => opt.subspecialtyName}
            ChipProps={{ size: 'small' }}
            renderInput={(params) => <TextField {...params} label="Subspecialties" />}
          />

          <Grid item xs={12} component={Typography} variant="h5">
            Location
          </Grid>

          <Grid
            item
            xs={2}
            sm={1}
            component={TextInput}
            name="radiusInMiles"
            label="Radius"
            type="number"
            {...textInputProps}
          />
          <Grid
            item
            xs={5}
            sm={3}
            component={TextInput}
            name="latitude"
            label="Lat"
            type="number"
            {...textInputProps}
          />
          <Grid
            item
            xs={5}
            sm={3}
            component={TextInput}
            name="longitude"
            label="Lon"
            type="number"
            {...textInputProps}
          />
          <Grid
            item
            xs={8}
            sm={4}
            component={TextInput}
            name="city"
            label="City"
            {...textInputProps}
          />
          <Grid
            item
            xs={4}
            sm={1}
            component={TextInput}
            name="stateAbbreviation"
            label="State"
            {...textInputProps}
          />

          <Grid
            item
            xs={9}
            component={TextInput}
            name="content"
            label="Message Content"
            variant="standard"
          />
          <Grid item xs={12} sm={3} component={SubmitButton} {...buttonProps}>
            Dispatch Fulfilled
          </Grid>
        </Grid>
      </Formik>

      {/* Rejected */}
      <Grid container {...gridContainers}>
        <Grid
          item
          xs={12}
          sm={9}
          component={TextField}
          label="Error Message (payload)"
          value={errorText}
          onChange={handleErrorTextInput}
          {...textInputProps}
        />
        <Grid
          item
          component={Button}
          xs={12}
          sm={3}
          onClick={handleRejected}
          {...buttonProps}
          classes={{ root: classes.rejectedButton, hover: classes.rejectedButtonHover }}
        >
          Dispatch Rejected
        </Grid>
      </Grid>

      {/* Dispatch full thunk */}
      <Grid container {...gridContainers}>
        <Grid
          item
          component={Button}
          xs={12}
          onClick={handleDispatchThunk}
          color="primary"
          {...buttonProps}
          variant="outlined"
          disabled={sendingChatOverview}
          className={classes.thunkButton}
        >
          {sendingChatOverview && <CircularProgress size={12} className={classes.spinner} />}
          Dispatch {thunks.chat.analyzeChat.typePrefix}
        </Grid>
        <Grid item xs={12}>
          *This will run analysis on all of the values currently in state
        </Grid>
      </Grid>
    </>
  );
}
