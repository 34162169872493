import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Collapse, Paper, Container } from '@material-ui/core';
import { select, thunks } from 'store/toolkit';
import ChatQuickFeedbackForm from './ChatQuickFeedbackForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));
export default function ChatQuickFeedback() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isPerformingAllSearches = useSelector(select.chat.isPerformingAllSearches);

  const handleSubmit = useCallback(
    (payload) => {
      dispatch(thunks.chat.submitQuickFeedback(payload));
    },
    [dispatch]
  );

  return (
    <Collapse classes={classes} component={Paper} elevation={0} in={!isPerformingAllSearches}>
      <Container maxWidth="md">
        <ChatQuickFeedbackForm handleSubmit={handleSubmit} />
      </Container>
    </Collapse>
  );
}
