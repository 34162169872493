import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatGeolocate } from 'utils/utils';
import * as Sentry from '@sentry/react';
import { ATLAS_GEOSEARCH_ENTITY_TYPE } from 'utils/constants';
import { select, actions } from 'store/toolkit';
import { values as selectConfigValues } from '../config/selectConfig';

// eslint-disable-next-line import/prefer-default-export
export const geolocateMapCenter = createAsyncThunk('geolocateMapCenter', async (args, thunkApi) => {
  const { mapCenter } = args;
  const { getState } = thunkApi;
  const { AZURE_MAPS_KEY } = selectConfigValues(getState());

  let addressComponents;
  // fallback locationInput value
  let locationInput = `Near ${mapCenter.latitude.toFixed(2)}, ${mapCenter.longitude.toFixed(2)}`;
  try {
    let res = await fetch(
      `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${AZURE_MAPS_KEY}&api-version=1.0&query=${mapCenter.latitude},${mapCenter.longitude}`
    );
    res = await res.json();
    addressComponents = formatGeolocate(res);
  } catch {
    // do nothing
  }

  const { city, state, zip, neighbourhood, street, isOutsideOfUSA, country } =
    addressComponents || {};
  if (neighbourhood) {
    locationInput = `Near ${neighbourhood}, ${city} ${state}`;
  } else if (street) {
    locationInput = `Near ${street}, ${city} ${state}`;
  } else if (city && state) {
    locationInput = `Near ${city}, ${state}`;
  }

  if (isOutsideOfUSA && country) locationInput = `${locationInput}, ${country}`;

  return {
    locationInput,
    locationComponents: { city, state, zip },
    latLong: mapCenter,
  };
});

export const fetchDefaultLocationFromZipcode = createAsyncThunk(
  'fetchDefaultLocationFromZipcode',
  async (args, thunkApi) => {
    const { networkSlug, zipcode } = args;
    const { getState, rejectWithValue, dispatch } = thunkApi;
    const state = getState();
    const azureMapsKey = select.tokens.azureMaps(state);
    const defaultLocation = select.networks.defaultLocationBySlug(networkSlug)(state);
    const url = `https://atlas.microsoft.com/search/fuzzy/json?subscription-key=${azureMapsKey}&api-version=1.0&countrySet=US,PR,VI&query=${zipcode}`;
    let matchedLocation;

    try {
      let atlasRes = await fetch(url);
      atlasRes = await atlasRes.json();
      matchedLocation = atlasRes.results.find(
        (result) =>
          result.address.postalCode === zipcode && result.entityType === ATLAS_GEOSEARCH_ENTITY_TYPE
      );
    } catch (e) {
      Sentry.captureException(e);
      dispatch(
        actions.notifications.create({
          message: `An error occurred when fetching your location from your zipcode. Returning you to ${defaultLocation.city}, ${defaultLocation.state}`,
          duration: 5000,
          returnFocusKey: 'searchButton',
          severity: 'error',
        })
      );
      return rejectWithValue({ defaultLocation });
    }

    if (!matchedLocation) {
      dispatch(
        actions.notifications.create({
          message: `We didn't find that zipcode in our records. Returning you to ${defaultLocation.city}, ${defaultLocation.state}`,
          duration: 5000,
          returnFocusKey: 'searchButton',
          severity: 'warning',
        })
      );
      return rejectWithValue({ defaultLocation });
    }
    return {
      locationComponents: {
        city: matchedLocation.address.postalName,
        zip: matchedLocation.address.postalCode,
        state: matchedLocation.address.countrySubdivision,
      },
      locationInput: matchedLocation.address.freeformAddress,
      latLong: { latitude: matchedLocation.position.lat, longitude: matchedLocation.position.lon },
    };
  }
);
