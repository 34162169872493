import * as Sentry from '@sentry/react';

import { getClient, getEnv, detectWebGLSupport } from 'utils/utils';
import beforeBreadcrumb from './breadcrumbs';

const env = getEnv();
const client = getClient();

export function beforeSend(event, hint) {
  try {
    const { originalException } = hint;
    if (originalException.sentryMessage) {
      // when errors have a dedicated sentryMessage property, send that rather than the default "message" property
      event.exception.values[0].value = originalException.sentryMessage;
    }
  } catch (e) {
    // do nothing
  }

  if (env === 'local') {
    console.log('Sentry Event:', event);
    return null;
  }
  return event;
}

export default function initializeSentry() {
  Sentry.init({
    dsn: 'https://4acc2d0cb9ba4af586739550b525ea43@sentry.io/1875299',
    // eslint-disable-next-line new-cap
    integrations: [new Sentry.browserTracingIntegration()],
    release: process.env.REACT_APP_GIT_HASH,
    environment: `${env}`,
    tracesSampleRate: env !== 'production' ? 0.1 : 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'Non-Error promise rejection captured'],
    beforeBreadcrumb,
    beforeSend,
  });
  Sentry.setTag('client', `${client}`);

  if (!detectWebGLSupport()) {
    Sentry.setContext('WebGL-disabled', true);
  }
}
