import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import useUpdateTitle from 'hooks/useUpdateTitle';
import { select } from 'store/toolkit';
import { SUPPORT_ROUTE } from '../../../navigation/navigationConstants';

function SubmitTicketLink() {
  return (
    <Link to={`${SUPPORT_ROUTE}#ticket`} component={RouterLink}>
      submit a ticket
    </Link>
  );
}

function ReferralGuideAppContact() {
  const supportName = useSelector(select.content.supportName);
  const supportPhone = useSelector(select.content.supportPhoneNumber);
  const supportEmail = useSelector(select.content.supportEmail);
  const showSupportLink = supportName && (supportPhone || supportEmail);
  const href = supportEmail ? `mailto:${supportEmail}` : `tel:${supportPhone}`;
  const linkText = supportEmail || supportPhone;

  return (
    <>
      {showSupportLink && (
        <Typography>
          If you have questions about Embold Health or Referral Guide, you can contact {supportName}{' '}
          at <Link href={href}>{linkText}</Link>.
        </Typography>
      )}

      <Typography>
        If you have any further questions, you can <SubmitTicketLink /> or call Embold Customer
        Service at <Link href="tel:877-361-3888">877-361-3888</Link>.
      </Typography>
    </>
  );
}

function AppContact() {
  const appName = useSelector(select.content.appName);
  return (
    <Typography>
      If you have questions about Embold Health or {appName}, you can <SubmitTicketLink />.
    </Typography>
  );
}

export default function AppContactSection() {
  const isReferralGuide = useSelector(select.featureFlags.isReferralGuide);

  useUpdateTitle('Support');

  return isReferralGuide ? <ReferralGuideAppContact /> : <AppContact />;
}
