export const ASSISTANT = 'assistant';
export const USER = 'user';
export const CHAT_MAX_MESSAGES = 32;

export const CHAT_URL = 'chat2/';
export const ANALYZE_CHAT_URL = 'analyze-chat/';
export const QUICK_FEEDBACK_URL = 'chat-quick-feedback/';

export const RESTART = 'restart';
export const DISMISS_EMERGENCY = 'dismiss';
export const SEARCH_IN_PG = 'searchInPg';
export const START_OVER = 'startOver';
export const TRY_AGAIN = 'tryAgain';
export const RETRY = 'retry';

export const GENERIC_ERROR_MESSAGE = 'There was a problem processing your request';
export const CHAT_GENERIC_ERROR_MESSAGE =
  'There was an unexpected error and we were not able to process your message. Would you like to Start Over, Try Again, or Retry the same message?';
export const CHAT_CONTENT_ERROR_MESSAGE =
  "If you think you're experiencing a medical emergency, dial 911 immediately. Your message has triggered OpenAI's content filtering and cannot be processed. If you think you’ve received this response by mistake, please reword your message any try again.";
export const API_CONTENT_ERROR_MESSAGE = 'OpenAI API request was invalid';

export const CONVERSATION_STARTER = {
  role: ASSISTANT,
  content:
    'Hi, my name is Eva. I am here to help you find the right doctor. In a few sentences, tell me whats up?',
};

export const CONVERSATION_STARTER_WHITELABELED = {
  role: ASSISTANT,
  content:
    "Hi, I'm your Virtual Assistant. I am here to help you find the right doctor. In a few sentences, tell me whats up?",
};

export const MAX_CHAT_LENGTH_MESSAGE = {
  role: ASSISTANT,
  content:
    'Unfortunately we were not able to make any provider recommendations based off of our current conversation. Please restart the chat to try again or try searching in the application.',
};

export const EMBOLD_FACTS_TIMEOUT = 3000; // time before showing facts carousel
export const EMBOLD_FACTS_INTERVAL = 8000; // time before showing next fact

// contain no references to "Eva"
export const EMBOLD_FACTS_GENERIC = [
  "For the most accurate information, please confirm details with a medical professional. Your health is our utmost priority and we're here to support you on your journey of seeking quality care.",
  'We value your patience as we continuously improve our services. We strive to ensure a safe and consistent experience.',
  'Always consult with a medical professional for your healthcare issues.',
  'If you believe you are experiencing an emergency, seek emergency medical evaluation right away.',
];

// @TODO: tests
// contain references to "Eva". When you add a new fact to this array, make sure to add a generic version to the `EMBOLD_FACTS_WHITELABELED` array
export const EMBOLD_FACTS_EVA = [
  'Like any technology, Eva has its limits. For specific treatment recommendations, always talk to a medical professional first.',
  'Eva is designed to streamline finding the right doctors that can address your needs. Please defer to a medical professional for recommendations on appropriate care and treatment',
  'Unlike complicated benefit navigation tools, Eva processes language in an intuitive and natural way to streamline the experience of finding the best providers.',
  'For decisions about your health, the insight of a medical professional is invaluable. Eva is not intended to diagnose or provide treatment recommendations.',
  'Unlike customer service lines, Eva is available 24 hours a day and seven days a week.',
  'Eva can detect and respond to multiple languages. Simply send a message in your native language and Eva will reply in the same language.',
  'If you are looking for a provider with specific qualities, just include the criteria in your message and Eva will custom tailor your provider results.',
  'During your conversation, Eva may remind you of additional benefits available, such as virtual health services.',
  'Eva may ask you to enter your geographic location and maximum distance you are willing to travel to narrow down your results.',
  'Eva adheres to the standards and principles intended to protect you and promote your general well-being. Always defer to the recommendations of a medical professional when making healthcare decisions.',
  'Eva is designed to guide you to the appropriate specialist and is NOT intended to replace the advice of medical professionals.',
  'Eva operates on data and might not capture all personal nuances. We encourage you to share your healthcare needs with a medical professional who can offer clinically appropriate recommendations.',
  'Eva leverages the power of a large language model (LLM) to connect you with high-quality providers.',
  'Tell Eva the symptoms you are experiencing, a condition you have, or a procedure you need to find the best providers to address your unique needs.',
  'Once Eva determines what type of provider you need, you will be presented with a list of top providers near you.',
  'With Eva, you can close the chat and re-open it during the same session to resume the conversation right where you left off.',
];

// duplicates of `EMBOLD_FACTS_NON_WHITELABELED` with references to "Eva" replaced with "the Virtual Assistant"
export const EMBOLD_FACTS_WHITELABELED = [
  'Like any technology, the Virtual Assistant has its limits. For specific treatment recommendations, always talk to a medical professional first.',
  'The Virtual Assistant is designed to streamline finding the right doctors that can address your needs. Please defer to a medical professional for recommendations on appropriate care and treatment',
  'Unlike complicated benefit navigation tools, the Virtual Assistant processes language in an intuitive and natural way to streamline the experience of finding the best providers.',
  'For decisions about your health, the insight of a medical professional is invaluable. The Virtual Assistant is not intended to diagnose or provide treatment recommendations.',
  'Unlike customer service lines, the Virtual Assistant is available 24 hours a day and seven days a week.',
  'The Virtual Assistant can detect and respond to multiple languages. Simply send a message in your native language and Eve will reply in the same language.',
  'If you are looking for a provider with specific qualities, just include the criteria in your message and the Virtual Assistant will custom tailor your provider results.',
  'During your conversation, the Virtual Assistant may remind you of additional benefits available, such as virtual health services.',
  'The Virtual Assistant may ask you to enter your geographic location and maximum distance you are willing to travel to narrow down your results.',
  'The Virtual Assistant adheres to the standards and principles intended to protect you and promote your general well-being. Always defer to the recommendations of a medical professional when making healthcare decisions.',
  'The Virtual Assistant is designed to guide you to the appropriate specialist and is NOT intended to replace the advice of medical professionals.',
  'The Virtual Assistant operates on data and might not capture all personal nuances. We encourage you to share your healthcare needs with a medical professional who can offer clinically appropriate recommendations.',
  'The Virtual Assistant leverages the power of a large language model (LLM) to connect you with high-quality providers.',
  'Tell the Virtual Assistant the symptoms you are experiencing, a condition you have, or a procedure you need to find the best providers to address your unique needs.',
  'Once the Virtual Assistant determines what type of provider you need, you will be presented with a list of top providers near you.',
  'With the Virtual Assistant, you can close the chat and re-open it during the same session to resume the conversation right where you left off.',
];

export const EMBOLD_ANALYSIS_MESSAGE_INTERVAL = 4000; // timeout for final loading messages
export const EMBOLD_ANALYSIS_MESSAGES = [
  'Searching healthcare database',
  'Sorting for best matched providers',
  'Delivering your results',
];
