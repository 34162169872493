import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { _analyticsActions } from 'analytics/index';

import GradeRoundedIcon from '@material-ui/icons/GradeRounded';

import { VALID_HEADING_VARIANTS, getNextHeadingLevel } from 'utils/utils';
import ExpandingPill from 'components/ExpandingPill';
import FeaturedBenefitHeader from './FeaturedBenefitTitle';
import BenefitDetails from './BenefitDetails';

const useStyles = makeStyles((theme) => ({
  details: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default function FeaturedBenefit({ benefit, headingLevel, isMobile, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_analyticsActions.promotionResultView(benefit.programName));
  }, [benefit.programName, dispatch]);

  const handleOpen = useCallback(() => {
    dispatch(_analyticsActions.promotionProfileView(benefit.programName));
  }, [benefit, dispatch]);

  if (!benefit) return null;

  return (
    <ExpandingPill
      label="Featured Benefit"
      labelIcon={<GradeRoundedIcon color="primary" />}
      onOpen={handleOpen}
      variant={isMobile ? 'mobile' : 'desktop'}
      title={
        <FeaturedBenefitHeader
          title={benefit.programName}
          subtitle={benefit.programSubname}
          logo={benefit.logo}
          headingLevel={headingLevel}
          benefit={benefit}
          isMobile={isMobile}
        />
      }
      {...props}
    >
      <BenefitDetails
        classes={{ root: classes.details }}
        headingLevel={getNextHeadingLevel(headingLevel)}
        benefit={benefit}
        isMobile={isMobile}
      />
    </ExpandingPill>
  );
}

FeaturedBenefit.propTypes = {
  benefit: PropTypes.shape({
    logo: PropTypes.string,
    programName: PropTypes.string.isRequired,
    programSubname: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS), // This is to set the highest heading level for this component. Nested headings will be based off of this value.
};

FeaturedBenefit.defaultProps = {
  isMobile: false,
  headingLevel: 'h3',
};
