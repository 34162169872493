import React from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { PROVIDER_TYPE, PLACE_TYPE } from 'utils/constants';

import SearchAppendix from 'components/Modals/SearchAppendixModals/SearchAppendix';
import PageTemplate from './PageTemplate';

const useStyles = makeStyles((theme) => ({
  root: {
    height: ' calc(100vh - 235px)', // header, footer, "go back" bar
    [theme.breakpoints.down('md')]: {
      height: ' calc(100vh - 155px)', // header, "go back" bar
    },
    [theme.breakpoints.down('xs')]: {
      height: ' calc(100vh - 145px)', // header, "go back" bar
    },
  },
}));
function SearchAppendixPage() {
  const { resultType } = useParams();
  const classes = useStyles();
  const history = useHistory();

  if ([PROVIDER_TYPE, PLACE_TYPE].indexOf(resultType) === -1) {
    console.error(`Missing or invalid URL path: ${resultType}`);
    history.push('/');
    return null;
  }
  return (
    <Grid container direction="column" classes={classes}>
      <SearchAppendix resultType={resultType} />
    </Grid>
  );
}

export default withRouter(PageTemplate(SearchAppendixPage, 'Search Specialties'));
