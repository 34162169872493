import { DEFAULT_LOCATIONS, MEMBER_CREDENTIALS, MICROSOFT } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import msftHeaderLogo from 'assets/Microsoft-logo-white.svg';
import MSFTLoginLogo from 'assets/Microsoft_logo.svg';

export const apiTokens = {
  dev: 'Token 4811089698748406a665f4f4761e43bdbcdfda67',
  staging: 'Token 6c6f3aac043a87ece9b28e5af88dbbcf04990202',
  prod: 'Token c5abc04e074368a1249aa076596f93a97c9267cb',
};

const token = selectToken(apiTokens);

const microsoftConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'microsoft',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'microsoft',
  EMBOLD_CLIENT: MICROSOFT,
  EMBOLD_CLIENT_SLUG: 'msft',

  // toggles
  SHOW_DISCLAIMER: true,
  USE_GENDER_ICONS: false,
  ENABLE_PLACE_SEARCH: true,

  // login
  LOGIN_NETWORK_NAME: 'msftUserId',
  SHOW_REGION_SELECTOR: false,
  LOGIN_COOKIE_AUTH_REGEX: ['MSJ|YMJ|MSQ|SUR', 'i'],

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Seattle },

  // content
  DISPLAY_NAME: 'Microsoft',
  SUPPORT_LINK: 'https://microsoftsupport.emboldhealth.com/portal/home',
  HEADER_LOGO: msftHeaderLogo,
  FORM_VARIANT: MEMBER_CREDENTIALS,
  SCHEDULE_COPY: `Connecting you to Embold customer service to assist with finding a provider and Provider Guide technical support.`,
  SCHEDULE_PHONE_NUMBER: '877-671-1471',
  SEARCH_ERROR_TEXT:
    'This pilot version of Provider Guide currently evaluates providers from 9 specialties within the Puget Sound. Please change your search criteria to try again.',
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Microsoft and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: MSFTLoginLogo,
  NO_WELCOME_LINK: false,
};

export default microsoftConfig;
