import { select, actions, thunks } from 'store/toolkit';
import { CARE_CATEGORY_OPTIONS } from 'utils/constants';
import { SEARCH_SUBMISSION, EXPAND_SEARCH, LOCATION_SEARCH_SUBMISSION } from './analyticsConstants';

/* ***************************** */
/* *** 100-199 Search Events *** */
/* ***************************** */

export default {
  searchFormSubmitted: {
    code: 100,
    category: SEARCH_SUBMISSION,
    description: 'A user performed a search by submitting the standard search form',
    getEventData: (state) => {
      const searchInput = select.search.text(state);
      const careCategory = select.search.type(state);
      return {
        ...select.search.slice(state),
        eventAction: 'Autocomplete',
        eventLabel: `Search Term: ${searchInput}`,
        search_category: CARE_CATEGORY_OPTIONS[careCategory].analyticsCategory,
      };
    },
    labelDescription: 'Search Term: <searchInput>',
    actionDescription: '<specialty|name|facility|facility name|service> search',
  },

  topSearch: {
    code: 101,
    category: SEARCH_SUBMISSION,
    description: 'A user performed a search by selected one of the Top Searches on the home page',
    getEventData: (state, action) => ({
      searchText: action.meta?.arg?.name,
    }),
    triggers: [thunks.results.executeTopSearch.pending],
  },

  searchThisArea: {
    code: 102,
    category: SEARCH_SUBMISSION,
    description: "A user performed a bounding box search using the map's Search This Area button",
    getEventData: (state, action) => {
      const searchInput = select.search.text(state);
      return {
        searchText: searchInput,
        mapCenter: `${action.meta?.arg?.mapCenter?.latitude}, ${action.meta?.arg?.mapCenter?.longitude}`,
      };
    },
    triggers: [thunks.results.searchThisArea.pending],
  },

  appliedFiltersSearch: {
    code: 103,
    category: SEARCH_SUBMISSION,
    description: 'A user applied new filters and performed a search with those filters',
    getEventData: (state) => {
      const filters = select.filters.asQueryParams(state);
      const searchInput = select.search.text(state);
      const careCategory = select.search.type(state);
      return {
        filters,
        eventAction: 'Update Search Filters',
        eventLabel: `Search Term: ${searchInput}`,
        search_category: CARE_CATEGORY_OPTIONS[careCategory].analyticsCategory,
      };
    },
    labelDescription: 'Update Search Filters',
    actionDescription: 'Search Term: <searchInput>',
  },

  /**
   * Since we need to access the previously searched subspecialty and parent specialty at the same time before the
   * results slice could possibly be updated, we need to pass them both to this event.
   * @param {object} action Expected payload { searchedSubspecialty: subspecialty name, parentSpecialty: parent specialty name }
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.parentSpecialtySearch({ searched_radius: radius from previous search, expanded_radius: radius for expanded search}));
   */
  parentSpecialtySearch: {
    code: 104,
    category: SEARCH_SUBMISSION,
    description:
      'A user who had already performed a subspecialty search clicked the "breadcrumb" button to perform a new search on the parent specialty',
    getEventData: (state, { payload = {} }) => {
      const searchInput = select.search.text(state);
      const careCategory = select.search.type(state);
      return {
        ...payload,
        eventAction: 'Parent Specialty Breadcrumb Search',
        eventLabel: `Search Term: ${searchInput}`,
        search_category: CARE_CATEGORY_OPTIONS[careCategory].analyticsCategory,
      };
    },
    labelDescription: 'Parent Specialty Breadcrumb Search',
    actionDescription: 'Search Term: <searchInput>',
  },

  /**
   * Since we need to access the previously searched radius and the updated radius at the same time before the
   * results slice could possibly be updated, we need to pass them both to this event.
   * @param {object} action Expected payload { searched_radius: radius from previous search, expanded_radius: radius for expanded search}
   * @example
   * Action dispatch
   * We are using snake case here to match the previous events.
   * dispatch(_analyticsActions.expandRadiusSearch({ searched_radius: radius from previous search, expanded_radius: radius for expanded search}));
   */
  expandRadiusSearch: {
    code: 105,
    category: EXPAND_SEARCH,
    description:
      'A user who already performed a search that returned no results clicked the expand radius button to perform a new search with an expanded radius',
    getEventData: (state) => {
      const searchInput = select.search.text(state);
      const careCategory = select.search.type(state);
      const filters = select.filters.asQueryParams(state);
      const expandedRadius = select.results.suggestedExpandedRadius(state);
      const searchedRadius = select.results.searchRadius(state);
      return {
        filters,
        eventAction: 'Expand Search',
        eventLabel: `Expand Search ${searchInput}`,
        search_category: CARE_CATEGORY_OPTIONS[careCategory].analyticsCategory,
        searched_radius: searchedRadius,
        expanded_radius: expandedRadius,
      };
    },
    labelDescription: 'Expand Search',
    actionDescription: 'Expand Terms <searchInput>',
    triggers: [thunks.results.expandedRadiusSearch.pending],
  },

  chatSearch: {
    code: 106,
    category: SEARCH_SUBMISSION,
    description:
      'The chat has returned multiple subspecialties or specialties to search for providers. Lists of subspecialty and specialty names are added to the event attributes.',
    getEventData: (state) => {
      const { subspecialties = [], specialties = [] } = select.chat.searchParameters(state) || {};
      const subspecialtyNames = subspecialties.map((ss) => ss.subspecialtyName).join();
      const specialtyNames = specialties.map((s) => s.specialtyName).join();
      return {
        subspecialties: subspecialtyNames,
        specialties: specialtyNames,
        eventAction: 'Chat Search Submission',
        eventLabel: `Chat Provider Searches`,
        search_category: 'specialty search',
      };
    },
    labelDescription: 'Chat Search Submission',
    actionDescription: 'Chat Provider Searches',
    triggers: [thunks.chat.performAllSearches.pending],
  },

  chatSearchInProviderGuide: {
    code: 107,
    category: SEARCH_SUBMISSION,
    description:
      'A user has clicked on a specialty or subspecialty Explore More Providers button in chat',
    getEventData: (state, { payload = {} }) => {
      const { specialty } = payload;
      const name = specialty?.subspecialtyName || specialty?.specialtyName;

      return {
        eventAction: 'Chat Explore More Button Click',
        eventLabel: `Search Term: ${name}`,
        search_category: 'specialty search',
      };
    },
    labelDescription: 'Search Term: <specialty|subspecialty name>',
    actionDescription: 'Chat Explore More Button Click',
    triggers: [actions.chat.specialtySearchInPg],
  },

  /**
   * Since we don't store Azure Atlas Api entity type information for locations, we will need to send it in this action.
   * @param {string} action Azure Atlas Api entity type string depending on the type of response
   * @example
   * Action dispatch
   * If the the location suggestion returned from autocomplete is a Point of Interest we will want to use the first poi category -> suggestion.poi.categories[0]
   * Otherwise we can use the suggestions entityType
   * dispatch(_analyticsActions.locationAutocompleteSelected(suggestion.poi ? suggestion.poi.categories[0] : entityType));
   */
  locationAutocompleteSelected: {
    code: 108,
    category: LOCATION_SEARCH_SUBMISSION,
    description: 'A user has selected a location from the location autocomplete dropdown',
    getEventData: (state, { payload = '' }) => ({
      eventLabel: 'Autocomplete',
      eventAction: payload,
    }),
    labelDescription: 'Autocomplete',
    actionDescription: '<location type>',
  },

  alternateSearch: {
    code: 109,
    category: SEARCH_SUBMISSION,
    description: 'A user was recommended an alternate search and chose to perform that search',
    getEventData: (state, action) => {
      const {
        specialtyId: previousSpecialtyId,
        subspecialtyId: previousSubspecialtyId,
        text: previousText,
      } = select.results.search(state);
      const {
        specialtyId: newSpecialtyId,
        subspecialtyId: newSubspecialtyId,
        name: newText,
      } = action?.meta?.arg || {};

      return {
        previousSpecialtyId,
        previousSubspecialtyId,
        previousText,
        newSpecialtyId,
        newSubspecialtyId,
        newText,
      };
    },
    labelDescription: 'Alternate Search',
    triggers: [thunks.results.alternateSuggestedSearch.pending],
  },
};
