import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Layers from '@material-ui/icons/Layers';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { getCignaCostLink } from 'store/slices/results/resultUtils';

import { select } from 'store/toolkit';
import Attribute from '../../Attribute';

export function getPlaceAttributeList({
  showNetworkStatus,
  networkText,
  networkIcon,
  tierDesignation,
  enableCignaCostLink,
  cignaCostLink,
  palette,
}) {
  const list = [];

  // 1. network designation
  if (showNetworkStatus) {
    list.push({
      key: 'attribute-network',
      isListItem: false,
      text: networkText,
      icon: networkIcon,
      TypographyProps: {
        style: {
          fontWeight: 'bold',
          color: palette.success.dark,
        },
      },
    });
  }

  // 2. tier designation
  if (tierDesignation) {
    list.push({
      key: 'attribute-tier-designation',
      isListItem: false,
      text: tierDesignation.text,
      TooltipProps: { message: tierDesignation.tooltipMessage },
      icon: <Layers />,
    });
  }

  // 3. Cigna Cost link
  if (enableCignaCostLink) {
    list.push({
      key: 'cigna-cost',
      text: 'See Cost Estimate',
      icon: <OpenInBrowserIcon color="secondary" />,
      LinkProps: { href: cignaCostLink },
    });
  }

  return list;
}

function PlaceAttributeList({ placeId, showNetworkStatus: showNetworkStatusProp }) {
  const facilityData = useSelector(select.place(placeId).data);
  const networkContent = useSelector(select.place(placeId).networkContent);
  const tierDesignation = useSelector(select.place(placeId).tierDesignation);
  const enableCignaCostLink = useSelector(select.featureFlags.enableCignaCostLink);
  const cignaCostLink = useMemo(() => getCignaCostLink(facilityData), [facilityData]);

  const showNetworkStatus = useMemo(
    () => networkContent?.showNetworkStatus && showNetworkStatusProp,
    [showNetworkStatusProp, networkContent?.showNetworkStatus]
  );

  const { palette } = useTheme();

  const attributeList = useMemo(
    () =>
      getPlaceAttributeList({
        showNetworkStatus,
        networkText: networkContent.networkText,
        networkIcon: networkContent.getIcon(palette),
        tierDesignation,
        enableCignaCostLink,
        cignaCostLink,
        palette,
      }),
    [
      cignaCostLink,
      enableCignaCostLink,
      networkContent,
      palette,
      showNetworkStatus,
      tierDesignation,
    ]
  );

  return (
    <Box>
      {attributeList.map(({ key, ...attribute }) => (
        <Attribute key={`${key}-${placeId}`} {...attribute} />
      ))}
    </Box>
  );
}

PlaceAttributeList.propTypes = {
  placeId: PropTypes.string.isRequired,
  showNetworkStatus: PropTypes.bool,
};

PlaceAttributeList.defaultProps = {
  showNetworkStatus: true,
};

export default React.memo(PlaceAttributeList);
