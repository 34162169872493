import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  screenReaderText: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
}));

export default function ScreenReaderErrors({ errorLabelMap }) {
  const classes = useStyles();
  const formik = useFormikContext();
  // take the formik error object, stringify it, and format it so that it will make sense to the user when read using the values from errorLabelMap
  const prepareAriaLiveMessage = useCallback(
    (errorObj) => {
      const errCopy = cloneDeep(errorObj);
      const preparedResult = {};

      for (const key in errorLabelMap) {
        if (errCopy[key]) {
          preparedResult[errorLabelMap[key]] = errCopy[key];
        }
      }

      const formattedResult = Object.entries(preparedResult).join().replaceAll(',', ' ');
      return formattedResult;
    },
    [errorLabelMap]
  );

  if (Object.keys(formik.errors).length === 0) return null;

  return (
    <div role="alert" className={classes.screenReaderText}>
      {prepareAriaLiveMessage(formik.errors)}
    </div>
  );
}

ScreenReaderErrors.propTypes = {
  errorLabelMap: PropTypes.shape({}).isRequired,
};
