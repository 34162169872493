import React from 'react';
import PropTypes, { ChildrenType } from 'propTypes/index';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    marginBottom: theme.spacing(2),
  },
  title: {
    letterSpacing: '.1rem',
    textTransform: 'uppercase',
  },
}));

function LandingPageSectionWrapper({ title, subtitle, id, hidden, children }) {
  const classes = useStyles();

  if (hidden) return null;

  return (
    // "id" used in hash routing. If none is provided use the title but strip spaces
    <section id={id || title.replaceAll(' ', '')} tabIndex={-1}>
      <div className={classes.sectionHeader}>
        <Typography variant="h5" component="h3" className={classes.title}>
          {title}
        </Typography>
        {Boolean(subtitle) && <Typography variant="body2">{subtitle}</Typography>}
      </div>
      {children}
    </section>
  );
}

LandingPageSectionWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: ChildrenType.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
  hidden: PropTypes.bool,
};

LandingPageSectionWrapper.defaultProps = {
  id: null,
  subtitle: null,
  hidden: false,
};

export default LandingPageSectionWrapper;
