/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { isNotNullish, logDevMessage } from 'utils/utils';
import { BOOL_FILTER_TYPE, ARRAY_FILTER_TYPE } from './filterConstants';
import { FILTERS_SLICE_NAME } from '../slicesNames';
import filterKeyMap from './filterKeyMap';
import { booleanFilterCustomMappings } from './filterCustomMappings';

// Basic selectors
export const slice = (state) => state[FILTERS_SLICE_NAME];
export const all = (state) => state[FILTERS_SLICE_NAME].filters;
export const providerSort = (state) => state[FILTERS_SLICE_NAME].sort.provider;
export const placeSort = (state) => state[FILTERS_SLICE_NAME].sort.place;
export const predictedResults = (state) => state[FILTERS_SLICE_NAME].predictedResults;
export const baseParamPredictedResults = (state) =>
  state[FILTERS_SLICE_NAME].baseParamPredictedResults;
export const optionsLoading = (state) => state[FILTERS_SLICE_NAME].options.isLoading;
export const optionsInvalidated = (state) => state[FILTERS_SLICE_NAME].options.isInvalidated;
export const optionsError = (state) => state[FILTERS_SLICE_NAME].options.error;
export const radius = (state) => state[FILTERS_SLICE_NAME].radius;

// Advanced selectors
export function byKey(key) {
  return createSelector([all], (filters) => filters[key] || undefined);
}

/** Returns an object where the key is the filter key, the value is the current value of that filter */
export const values = createSelector([all], (filters) => {
  const result = {};

  for (const [filterKey, filter] of Object.entries(filters)) {
    /* TODO TECH-2876
     * Once  client config is been migrated to Redux, we should uncomment the IF check here.
     * It is removed because on URL direct searches, the search was executing before the client
     * config was loaded. The means that filters that are disabled by default may have a value from the
     * URL, but not be enabled yet and therefore not applied to the results slice.
     * ...
     * For the time being, resultsSlice.request.filters will be passed several values that are actually disabled filters.
     * This is applied in resultsThunks.executeSearch
     */

    // if (!filter.disabled) {
    result[filterKey] = filter.value;
    // }
  }
  return result;
});

// showExclusions should return true if any filter with a prefix of 'exclude' is NOT disabled
export const showExclusions = createSelector([all], (filters) => {
  for (const filterKey in filters) {
    if (filterKey.startsWith('exclude') && !filters[filterKey].disabled) {
      return true;
    }
  }

  return false;
});

// Returns a string of all filter values to be added to a Fusion querystring
export const asQueryParams = createSelector([all], (filters) => {
  const paramStrings = [];

  // iterate through filters.
  for (const [filterKey, filter] of Object.entries(filters)) {
    /* TODO TECH-2876
     * Once  client config is been migrated to Redux, we should uncomment the IF check here.
     * It is removed because on URL direct searches, the search was executing before the client
     * config was loaded. The means that filters that are disabled by default may have a value from the
     * URL, but not be enabled yet and therefore not applied to the results slice.
     * ...
     * For the time being, resultsSlice.request.filters will be passed several values that are actually disabled filters.
     * This is applied in resultsThunks.executeSearch
     */
    // if (!filter.disabled) {
    const fusionFilterKey = filterKeyMap[filterKey];
    if (!fusionFilterKey) {
      logDevMessage(
        `No fusion filterKey specified for "${filterKey}". Add this property to filterKeyMap.js`
      );
      continue;
    }
    switch (filter.type) {
      case ARRAY_FILTER_TYPE:
        // array types should add param if length is > 0
        if (filter.value.length > 0) {
          let paramString = `${fusionFilterKey}=`; // add key
          paramString += filter.value.map((val) => encodeURIComponent(val)).join(','); // add values
          paramStrings.push(paramString);
        }
        break;
      case BOOL_FILTER_TYPE:
        // boolean types add the filter if value is true
        if (filter.value === true) {
          const customMapping = booleanFilterCustomMappings[filterKey];
          const hasCustomMapping = isNotNullish(customMapping);
          const paramString = `${fusionFilterKey}=${hasCustomMapping ? customMapping : true}`; // add key
          paramStrings.push(paramString);
        }
        break;
      default:
        logDevMessage(`Unhandled filter type ${filter.type} in asQueryParams`);
        break;
    }
    // }
  }

  const queryParamsString = paramStrings.join('&'); // add & between each filter param and join to 1 string

  return queryParamsString;
});

/** Returns an object of all filterObjects used for providers */
export const providerFilters = createSelector([all], (allFilters) => {
  const result = {};
  for (const filterKey in allFilters) {
    if (allFilters[filterKey].providers) result[filterKey] = allFilters[filterKey];
  }
  return result;
});

/** Returns a string of all of the provider type filters that can be sent to Fusion */
export const providerFiltersAsQueryParams = createSelector(
  [providerFilters],
  asQueryParams.resultFunc
);

/** Returns an object where the key is the filter key, the value is the filter value. Only includes provider filters. */
export const providerFilterValues = createSelector([providerFilters], values.resultFunc);

/** Returns an object of all filterObjects used for places */
export const placeFilters = createSelector([all], (allFilters) => {
  const result = {};
  for (const filterKey in allFilters) {
    if (allFilters[filterKey].places) result[filterKey] = allFilters[filterKey];
  }
  return result;
});

/** Returns a string of all of the provider type filters that can be sent to Fusion */
export const placeFiltersAsQueryParams = createSelector([placeFilters], asQueryParams.resultFunc);

export const placeFilterValues = createSelector([placeFilters], values.resultFunc);

export const radiusInMeters = createSelector([radius], (radiusInMiles) => radiusInMiles * 1609.34);
