import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  header: {
    color: theme.palette.primary.main,
  },
  descriptionSection: {
    paddingTop: theme.spacing(2),
  },
  image: {
    minWidth: 300,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
  },
}));

function AboutProviderScores({ image, description }) {
  const classes = useStyles();
  return (
    <Grid component="section" id="about-scores" tabIndex={-1} classes={{ root: classes.root }}>
      <Typography variant="h2" className={classes.header}>
        How Embold Health Scores Providers
      </Typography>
      <Typography>
        Provider Guide offers quality ratings, locations, and contact information for healthcare
        providers, with filters to match your personal criteria. Search results are sorted to
        highlight the highest-quality providers first.
      </Typography>
      {image && (
        <Grid className={classes.imageContainer}>
          <img className={classes.image} src={image} alt="provider profile example" />
        </Grid>
      )}
      {description && <Typography className={classes.descriptionSection}>{description}</Typography>}
      <Typography className={classes.descriptionSection}>
        Within a provider’s profile, you can view detailed information such as credentials,
        specialties, and quality rankings, with options to compare providers within the same
        specialty. Additional features like interactive maps and advanced filters enable a seamless
        experience, helping you confidently choose the right provider.
      </Typography>
    </Grid>
  );
}

export default AboutProviderScores;

AboutProviderScores.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
};

AboutProviderScores.defaultProps = {
  image: '',
  description: '',
};
