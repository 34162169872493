import React from 'react';
import { FilterMenuProps } from 'propTypes/index';
import { Typography, FormGroup } from '@material-ui/core';

import SwitchFilter from 'components/Results/Filters/SwitchFilter';
import RadiusSlider from './RadiusSlider';

function PlaceFilterMenu({ chipOpen, handleCloseChip }) {
  return (
    <>
      <FormGroup row component="fieldset">
        <Typography component="legend" variant="srOnly">
          Facility Filters
        </Typography>
        <SwitchFilter filter="featuredFacility" />
      </FormGroup>

      <RadiusSlider chipOpen={chipOpen} onChipClose={handleCloseChip} />
    </>
  );
}

PlaceFilterMenu.propTypes = FilterMenuProps;

export default PlaceFilterMenu;
