import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import emboldIcon from 'assets/embold-icon.svg';
import DisclaimerText from './DisclaimerText';

const useStyles = makeStyles((theme) => ({
  hamburgerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  logoWrapper: {
    fontSize: 9,
    display: 'flex',
    alignItems: 'center',
  },
  emboldIcon: {
    height: 16,
    paddingRight: 2,
  },
}));

export default function HamburgerFooter() {
  const classes = useStyles();
  return (
    <Grid className={classes.hamburgerFooter}>
      <DisclaimerText />
      <Typography className={classes.logoWrapper}>
        <img src={emboldIcon} className={classes.emboldIcon} alt="" />
        Powered by Embold Health
      </Typography>
    </Grid>
  );
}
