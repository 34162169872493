import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Slider, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { actions, select } from 'store/toolkit';

import {
  SLIDER_MARKS,
  SLIDER_VALUE_TO_RADIUS,
  RADIUS_TO_SLIDER_VALUE,
} from 'store/slices/filters/filterConstants';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 10,
  },
  slider: {
    width: '90%',
  },
  chip: {
    marginLeft: 10,
    height: 25,
    lineHeight: 25,
  },
  distanceLabel: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.grey[700],
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
}));

function RadiusSlider({ chipOpen, onChipClose }) {
  const classes = useStyles();

  const defaultSliderValue = useSelector(select.config.defaultRadiusSliderValue);
  const dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState(defaultSliderValue);
  const filterRadius = useSelector(select.filters.radius);
  const resultLocation = useSelector(select.results.location);

  useEffect(() => {
    // reset filter & location state to match that of result set
    setSliderValue(RADIUS_TO_SLIDER_VALUE[resultLocation.radius] || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSliderValue]);

  useEffect(() => {
    if (chipOpen) {
      setSliderValue(defaultSliderValue);
    }
  }, [chipOpen, setSliderValue, defaultSliderValue]);

  const SLIDER_MARKS_MAXIMUM = SLIDER_MARKS.reduce((acc, obj) => {
    if (obj.value > acc) acc = obj.value;
    return acc;
  }, 0);

  const handleSliderChange = useCallback((e, value) => {
    setSliderValue(value);
  }, []);

  const handleSliderChangeCommitted = useCallback(
    (e, value) => {
      // only perform store updates if the slider is moved to a new value
      if (SLIDER_VALUE_TO_RADIUS[value] !== filterRadius) {
        dispatch(actions.filters.setFilterRadius(SLIDER_VALUE_TO_RADIUS[value]));
      }
    },
    [dispatch, filterRadius]
  );

  return (
    <div>
      <Typography className={classes.distanceLabel} component="strong" id="radius-label">
        Distance in Miles{' '}
        {chipOpen && (
          <Chip
            className={classes.chip}
            label="CUSTOM"
            aria-label="Custom search area. Click to return to radius search"
            onDelete={onChipClose}
            // pass the onClick prop to handle screen reader and keyboard users
            // even though the chip is not clickable, a keyboard user can use "Enter" to remove rather than being required to use "delete" key
            onClick={onChipClose}
          />
        )}
      </Typography>
      <div className={classes.sliderContainer}>
        <Slider
          disabled={chipOpen}
          className={classes.slider}
          value={sliderValue}
          onChangeCommitted={handleSliderChangeCommitted}
          onChange={handleSliderChange}
          getAriaValueText={(val) => `${SLIDER_VALUE_TO_RADIUS[val]} miles`}
          aria-labelledby="radius-label"
          max={SLIDER_MARKS_MAXIMUM}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => SLIDER_VALUE_TO_RADIUS[value]}
          marks={SLIDER_MARKS}
          step={null}
        />
      </div>
    </div>
  );
}

RadiusSlider.propTypes = {
  chipOpen: PropTypes.bool.isRequired,
  onChipClose: PropTypes.func.isRequired,
};

export default RadiusSlider;
