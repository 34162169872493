import React, { createContext, useMemo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef {Object|null} Ref
 * @property {Any?} current
 */

/** The focus anchors object that is stored in the FocusRefContext provider
 * @typedef {Object} FocusAnchors
 * @property {Ref} locationInput
 * @property {Ref} searchButton
 * @property {Ref} sortButton
 * @property {Ref} filterButton
 * @property {Ref} logo
 * @property {Ref} results
 * @property {Ref} resultCard
 * @property {Ref} resultCardMap
 * @property {Ref} latestResultCard
 * @property {Ref} activeResultDrawer
 * @property {Ref} showAllLocationsCheckbox
 * @property {Ref} profileButton
 * @property {Ref} profileDetailFirstElement
 * @property {Ref} radiusButton
 * @property {Ref} modalContent
 * @property {Ref} searchInput
 * @property {Ref} careCategorySelect
 * @property {Ref} compareButton
 * @property {Ref} tooltip
 * @property {Ref} tooltipAnchor
 * @property {Ref} prefixField
 * @property {Ref} idField
 * @property {Ref} suffixField
 * @property {Ref} termsChecked
 * @property {Function} handleFocus
 * @property {Function} profileModalReturnFocus
 */

// initialize the context
export const FocusRefContext = createContext(null);

// create a provider that provides a value to consumer children
export function FocusAnchorProvider({ children }) {
  let focusAnchors = useMemo(() => ({}), []);

  focusAnchors = {
    locationDummy: useRef(),
    locationInput: useRef(),
    searchButton: useRef(),
    sortButton: useRef(),
    filterButton: useRef(),
    logo: useRef(),
    results: useRef(),
    resultCard: useRef(),
    resultCardMap: useRef(),
    latestResultCard: useRef(),
    activeResultDrawer: useRef(),
    showAllLocationsCheckbox: useRef(),
    profileButton: useRef(),
    profileDetailFirstElement: useRef(),
    radiusButton: useRef(),
    modalContent: useRef(),
    searchInput: useRef(),
    careCategorySelect: useRef(),
    compareButton: useRef(),
    tooltip: null, // this gets set to the ref on a specific tooltip click target in Tooltip.jsx
    tooltipAnchor: null, // this gets set to the ref on a specific tooltip icon in Tooltip.jsx
    prefixField: useRef(),
    idField: useRef(),
    suffixField: useRef(),
    termsChecked: useRef(),
    handleFocus(ref) {
      if (ref?.current) {
        ref.current.focus();
        return ref;
      }
      return false;
    },
    // this is a function that runs after the profile modal has closed
    profileModalReturnFocus: () => {},
    tooltipReturnFocus: () => {},
  };
  return <FocusRefContext.Provider value={focusAnchors}>{children}</FocusRefContext.Provider>;
}

FocusAnchorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * A hook that returns the value of the FocusAnchors context
 * @returns {FocusAnchors} */
export default function useFocusAnchors() {
  return useContext(FocusRefContext);
}

/**
 * Returns an object of functions for sending focus to a given anchor.
 */
export function useFocusTo() {
  const focusAnchors = useFocusAnchors();

  const focusTo = useMemo(
    () => ({
      locationDummy: () => focusAnchors.handleFocus(focusAnchors.locationDummy),
      locationInput: () => focusAnchors.handleFocus(focusAnchors.locationInput),
      searchButton: () => focusAnchors.handleFocus(focusAnchors.searchButton),
      sortButton: () => focusAnchors.handleFocus(focusAnchors.sortButton),
      filterButton: () => focusAnchors.handleFocus(focusAnchors.filterButton),
      logo: () => focusAnchors.handleFocus(focusAnchors.logo),
      results: () => focusAnchors.handleFocus(focusAnchors.results),
      resultCard: () => focusAnchors.handleFocus(focusAnchors.resultCard),
      resultCardMap: () => focusAnchors.handleFocus(focusAnchors.resultCardMap),
      latestResultCard: () => focusAnchors.handleFocus(focusAnchors.latestResultCard),
      activeResultDrawer: () => focusAnchors.handleFocus(focusAnchors.activeResultDrawer),
      showAllLocationsCheckbox: () =>
        focusAnchors.handleFocus(focusAnchors.showAllLocationsCheckbox),
      profileButton: () => focusAnchors.handleFocus(focusAnchors.profileButton),
      profileDetailFirstElement: () =>
        focusAnchors.handleFocus(focusAnchors.profileDetailFirstElement),
      radiusButton: () => focusAnchors.handleFocus(focusAnchors.radiusButton),
      modalContent: () => focusAnchors.handleFocus(focusAnchors.modalContent),
      searchInput: () => focusAnchors.handleFocus(focusAnchors.searchInput),
      careCategorySelect: () => focusAnchors.handleFocus(focusAnchors.careCategorySelect),
      compareButton: () => focusAnchors.handleFocus(focusAnchors.compareButton),
      tooltip: () => focusAnchors.handleFocus(focusAnchors.tooltip),
      tooltipAnchor: () => focusAnchors.handleFocus(focusAnchors.tooltipAnchor),
      profileModalReturn: () => focusAnchors.profileModalReturnFocus(),
      prefixField: () => focusAnchors.handleFocus(focusAnchors.prefixField),
      idField: () => focusAnchors.handleFocus(focusAnchors.idField),
      suffixField: () => focusAnchors.handleFocus(focusAnchors.suffixField),
      termsChecked: () => focusAnchors.handleFocus(focusAnchors.termsChecked),
      key: (key) => focusAnchors.handleFocus(focusAnchors[key]),
    }),
    [focusAnchors]
  );

  return focusTo;
}

/** This is used if you need to store the key of a focus anchor (as a string).
 * @returns {FocusAnchors} An object with matching keys to Focus anchors and the string value of the keys */
export function useFocusAnchorKeys() {
  const focusAnchors = useFocusAnchors();

  return useMemo(() => {
    const keysObj = {};
    for (const key of Object.keys(focusAnchors)) {
      keysObj[key] = key;
    }
    return keysObj;
  }, [focusAnchors]);
}
