import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import ConditionalWrapper from 'components/ConditionalWrapper';

const useStyles = makeStyles({
  root: {
    margin: '6px 16px 6px 0',
    lineHeight: 1.2,
    fontSize: '1rem',
    '&:last-child': {
      marginRight: 0,
    },
  },
  iconButton: {
    display: 'inline-block',
    padding: 0,
    borderRadius: 0,
  },
  underlineText: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
  },
});

/** @deprecated */
function Badge({ children, src, customSvg, onClick, innerRef, ...props }) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} {...props}>
      <ConditionalWrapper
        condition={Boolean(onClick)}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(innerButton) => (
          <Button onClick={onClick} className={classes.iconButton} ref={innerRef}>
            {innerButton}
          </Button>
        )}
      >
        {customSvg || <img src={src} alt="" style={{ width: 56, height: 42 }} aria-hidden="true" />}
        <br aria-hidden="true" />
        <Typography
          variant="caption"
          style={{ display: 'block' }}
          className={clsx({
            [classes.underlineText]: Boolean(onClick),
          })}
        >
          {children}
        </Typography>
      </ConditionalWrapper>
    </Grid>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  src: PropTypes.node,
  customSvg: PropTypes.node,
  onClick: PropTypes.func,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

Badge.defaultProps = {
  children: [],
  customSvg: null,
  onClick: null,
  src: null,
  innerRef: undefined,
};

export default React.forwardRef((props, ref) => <Badge {...props} innerRef={ref} />);
