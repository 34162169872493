/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { buildLocationUrl, distance } from 'utils/utils';
import { Table, TableHead, TableBody, TableRow, TableCell, Button, Link } from '@material-ui/core';
import AccessibleForwardOutlinedIcon from '@material-ui/icons/AccessibleForwardOutlined';
import CalendarIcon from 'assets/calendar-icon.svg';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { format } from 'date-fns';
import { get } from 'lodash';
import useLocationPhoneNumberOverride from 'hooks/useLocationPhoneNumberOverride';
import useFocusAnchors from 'utils/FocusRefContext';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

/** @deprecated */
function Location({
  classes,
  closestLocation,
  showQualitySection,
  showDistance,
  GridIconRow,
  handleDirectionsClick,
  handlePhoneClick,
  MAPS_URL,
  latLong,
  isPlaceSearch,
}) {
  const {
    city,
    state,
    zipcode,
    address1,
    address2,
    latitude,
    longitude,
    phone,
    hours_of_operation: hoursOfOperation,
    isWheelchairAccessible,
    acceptingNewPatients,
    clientFeaturedLocation,
  } = closestLocation;

  const resultType = useSelector(select.results.resultsType);
  const showPhoneNumber = useSelector(select.featureFlags.showPhoneNumber);
  const focusAnchors = useFocusAnchors();

  // TODO: Remove when handle this in the redux provider fetch
  const phoneNumber = useLocationPhoneNumberOverride(phone, clientFeaturedLocation);

  const formatTime = (time) => {
    const hour = time.slice(0, time.length - 2);
    const minute = time.slice(-2);
    if (minute === '00') {
      // 2022, 6, 30 is a meaningless placeholder in order to use Date()
      return format(new Date(2022, 6, 30, hour), 'hA', new Date());
    }
    return format(new Date(2022, 6, 30, hour, minute), 'h:mmA', new Date());
  };
  // eslint-disable-next-line prefer-regex-literals
  const hoursRegex = new RegExp('([01]?[0-9]|2[0-3])[0-5][0-9]-([01]?[0-9]|2[0-3])[0-5][0-9]');
  const displayHoursRows =
    hoursOfOperation &&
    hoursOfOperation.map((day) => {
      const validHours = hoursRegex.test(day.hours);
      const is24Hour = day.hours === '0000-2359' || day.hours === '0000-0000';
      const splitHours = day.hours.split('-');
      const [start, end] = splitHours;
      return (
        <TableRow>
          <TableCell style={{ borderBottom: 'none' }}>{day.dayOfWeek}</TableCell>
          <TableCell style={{ borderBottom: 'none' }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {is24Hour
              ? 'Open 24 hours'
              : validHours
              ? `${formatTime(start)}-${formatTime(end)}`
              : 'N/A'}
          </TableCell>
        </TableRow>
      );
    });
  //   const distanceFromLatLong = 4;
  const distanceInMiles = isPlaceSearch
    ? get(closestLocation, 'distanceInMiles[0]')
    : get(closestLocation, 'distanceInMiles');

  const distanceFromLatLong =
    distanceInMiles || distance(latLong.latitude, latLong.longitude, latitude, longitude);
  const roundedDistance = parseFloat(distanceFromLatLong)?.toFixed(2);
  return (
    <>
      {address1 && (
        <div style={{ marginBottom: '14px' }}>
          <GridIconRow Icon={RoomOutlinedIcon} id="geo-tag">
            <Typography variant="body1">
              <Link
                href={buildLocationUrl(closestLocation, MAPS_URL)}
                target="_blank"
                rel="noreferrer"
                ref={showQualitySection ? undefined : focusAnchors.profileDetailFirstElement}
                aria-label={`Location address: ${address1} ${city} ${state} ${zipcode}. ${
                  showDistance && distanceFromLatLong && `${roundedDistance} miles away`
                }. opens in new tab.`}
                onClick={handleDirectionsClick}
              >
                {/* {name && <br aria-hidden="true" />} */}
                {address1}
                {address2 && <br aria-hidden="true" />}
                {address2}
                <br aria-hidden="true" />
                {city}, {state} {zipcode}
                <br aria-hidden="true" />
              </Link>
              {showDistance && distanceFromLatLong && (
                <span className="profile-address-distance" style={{ display: 'block' }}>
                  {roundedDistance} miles away
                </span>
              )}
            </Typography>
          </GridIconRow>
        </div>
      )}

      {showPhoneNumber && phoneNumber && (
        <GridIconRow Icon={CallOutlinedIcon} id="call">
          <Button
            role="link"
            className={`${classes.buttonLink} profile-phone-number`}
            onClick={() => handlePhoneClick(phoneNumber)}
            style={{ userSelect: 'text' }}
          >
            {phoneNumber}
          </Button>
        </GridIconRow>
      )}

      {resultType !== 'place' &&
        (acceptingNewPatients ? (
          <GridIconRow Icon={CheckCircleOutlineOutlinedIcon} id="accepting-new-patients">
            <Typography variant="body2" className="profile-accepting">
              Accepting Patients
            </Typography>
          </GridIconRow>
        ) : (
          typeof acceptingNewPatients === 'boolean' && (
            <GridIconRow Icon={HighlightOffIcon} id="not-accepting-new-patients">
              <Typography variant="body2" className="profile-not-accepting">
                Not Accepting Patients
              </Typography>
            </GridIconRow>
          )
        ))}

      {hoursOfOperation && (
        <GridIconRow img={CalendarIcon} id="hours-of-operation">
          <Typography variant="body1" className="profile-gender">
            <Table padding="none">
              <TableHead>Hours of Operation</TableHead>
              <TableBody>{displayHoursRows}</TableBody>
            </Table>
          </Typography>
        </GridIconRow>
      )}
      {isWheelchairAccessible && (
        <GridIconRow Icon={AccessibleForwardOutlinedIcon} id="is-wheelchair-accessible">
          <Typography variant="body2" className="profile-gender">
            Handicap Accessible
          </Typography>
        </GridIconRow>
      )}
    </>
  );
}

Location.propTypes = {
  classes: PropTypes.object,
  showQualitySection: PropTypes.bool,
  showDistance: PropTypes.bool,
  GridIconRow: PropTypes.func,
  handleDirectionsClick: PropTypes.func,
  handlePhoneClick: PropTypes.func,
  MAPS_URL: PropTypes.string,
  isPlaceSearch: PropTypes.bool,
  latLong: PropTypes.object,
};

export default Location;
