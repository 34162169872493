import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme, Button, Link, Grid } from '@material-ui/core';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { VALID_HEADING_VARIANTS } from 'utils/utils';
import { thunks } from 'store/toolkit';
import ExpandingPill from '../ExpandingPill';

export default function SearchSuggestion({
  title,
  children,
  suggestion,
  headingLevel,
  onDismiss,
  ...props
}) {
  const { palette } = useTheme();
  const dispatchWithHistory = useSearchDispatchWithHistory();

  const handleSearch = useCallback(
    () => dispatchWithHistory(thunks.results.alternateSuggestedSearch(suggestion)),
    [dispatchWithHistory, suggestion]
  );

  return (
    <ExpandingPill
      label="Search Suggestion"
      labelIcon={<SearchOutlinedIcon htmlColor={palette.warning.main} />}
      title={
        <Typography variant="h3" component={headingLevel}>
          {title}
        </Typography>
      }
      borderColor="primary.main"
      {...props}
    >
      <Box bgcolor="grey.100" p={2} borderRadius={8} mt={1}>
        <Typography variant="subtitle2" component="strong">
          New Search Suggestion:
        </Typography>
        {children}

        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search {suggestion?.name}?
            </Button>
          </Grid>

          <Grid item>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link component="button" underline="always" color="primary" onClick={onDismiss}>
              No thanks
            </Link>
          </Grid>
        </Grid>
      </Box>
    </ExpandingPill>
  );
}

SearchSuggestion.propTypes = {
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS),
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  suggestion: PropTypes.shape({
    specialtyId: PropTypes.number,
    subspecialtyId: PropTypes.number,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onDismiss: PropTypes.func,
};

SearchSuggestion.defaultProps = {
  headingLevel: 'h4',
  onDismiss: () => {},
};
