/* eslint-disable prefer-destructuring */
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { LOCATION_SLICE_NAME } from 'store/slices/slicesNames';
import { executeSearch } from 'store/slices/results/resultsThunks';
import { fetchDefaultLocationFromZipcode } from 'store/slices/location/locationThunks';

import { actions } from 'store/toolkit';
import { closeFeedbackModal } from 'store/slices/ui/uiSlice';
import { setLocalStorageExpire } from 'utils/utils';

// eslint-disable-next-line import/prefer-default-export
export const localStorageMiddleware = createListenerMiddleware();

localStorageMiddleware.startListening({
  matcher: isAnyOf(executeSearch.fulfilled, fetchDefaultLocationFromZipcode.fulfilled),
  effect: (action, listenerApi) => {
    const locationHistory = listenerApi.getState()[LOCATION_SLICE_NAME].locationHistory;

    // if a location from history is selected, it's pushed to the front of the locationHistory array
    // therefore we need to remove its duplicate
    const dedupedHistory = uniqBy(locationHistory, 'id');

    if (dedupedHistory.length > 5) dedupedHistory.pop();

    listenerApi.dispatch(actions.location.setLocationHistory(dedupedHistory));

    localStorage.setItem('locationHistory', JSON.stringify(dedupedHistory));
  },
});

localStorageMiddleware.startListening({
  matcher: isAnyOf(closeFeedbackModal),
  effect: () => {
    setLocalStorageExpire('feedbackPrompted', 'true', 3);
  },
});
