import { PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const processSpecialties = (data) => {
  if (!data?.length) return null;
  const typeMap = {
    P: PROVIDER_RESULT_TYPE,
    F: PLACE_RESULT_TYPE,
  };
  const specialtiesMap = {
    [PROVIDER_RESULT_TYPE]: {},
    [PLACE_RESULT_TYPE]: {},
  };
  const subspecialtiesMap = {
    [PROVIDER_RESULT_TYPE]: {},
    [PLACE_RESULT_TYPE]: {},
  };
  data.forEach((specialty) => {
    specialtiesMap[typeMap[specialty.type]][specialty.specialtyId] = specialty;
    if (specialty.subspecialties.length > 0) {
      specialty.subspecialties.forEach((subspecialty) => {
        // eslint-disable-next-line no-param-reassign
        subspecialty.parentSpecialtyId = specialty.specialtyId;
        subspecialtiesMap[typeMap[specialty.type]][subspecialty.subspecialtyId] = subspecialty;
      });
    }
  });
  return { specialtiesMap, subspecialtiesMap };
};
