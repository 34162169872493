import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';

import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default function GenderCell({ providerId }) {
  const classes = useStyles();
  const { gender } = useSelector(select.provider(providerId).data) || {};

  if (!gender)
    return (
      <div className={classes.iconContainer}>
        <NoDataIcon />
      </div>
    );

  return gender;
}

GenderCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
