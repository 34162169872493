import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(0.5),
  },
}));

export default function EmergencyCareDescription() {
  const classes = useStyles();
  return (
    <>
      <Typography gutterBottom>
        Consider Urgent Care when you need to be seen quickly, but can&apos;t visit your primary
        care provider. Urgent care clinics are typically less expensive and have shorter wait times
        than Emergency Rooms.
      </Typography>

      <Typography gutterBottom className={classes.disclaimer}>
        <WarningIcon color="warning.main" className={classes.icon} titleAccess="Warning:" /> If you
        are experiencing a medical emergency contact a medical professional immediately or call 911.
      </Typography>
    </>
  );
}
