import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Collapse } from '@material-ui/core';

import { select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';
import SearchSuggestion from './SearchSuggestion';

function AlternateSearchSuggester() {
  const dispatch = useDispatch();
  const [wasDismissed, setWasDismissed] = useState(false);
  const altSearch = useSelector(select.results.alternateSearchSuggestion);
  const isMobile = useSelector(select.ui.isSmDown);
  const searchRequestUrl = useSelector(select.results.url);

  useEffect(() => {
    // reset the wasDismissed state every time a new search was performed
    setWasDismissed(false);
  }, [searchRequestUrl, setWasDismissed]);

  const handleDismiss = useCallback(() => setWasDismissed(true), [setWasDismissed]);

  useEffect(() => {
    if (altSearch) {
      // remove non-serializable values
      const { description, ...rest } = altSearch;
      dispatch(_analyticsActions.alternateSearchRecommended(rest));
    }
  }, [dispatch, altSearch]);

  if (!altSearch) return null;

  const children =
    // when strings are passed split into paragraphs and wrap in MUI typography component
    typeof altSearch.description === 'string'
      ? altSearch.description.split('\n').map((p, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography gutterBottom key={i}>
            {p}
          </Typography>
        ))
      : // if a node is passed use it directly
        altSearch.description;

  return (
    <div>
      <Collapse in={!wasDismissed} unmountOnExit>
        <SearchSuggestion
          title={altSearch.title}
          suggestion={altSearch.suggestion}
          variant={isMobile ? 'mobile' : 'desktop'}
          onDismiss={handleDismiss}
        >
          {children}
        </SearchSuggestion>
      </Collapse>
    </div>
  );
}

export default React.memo(AlternateSearchSuggester);
