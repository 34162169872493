/* eslint-disable react/jsx-filename-extension */
import React, { useCallback } from 'react';

import LanguageIcon from '@material-ui/icons/Language';
import { PLACE_RESULT_TYPE, WALMART, MICROSOFT, POLK } from 'utils/constants';
import { select } from 'store/toolkit';
import { useSelector } from 'react-redux';

/** @deprecated */
export default function useGetNetworkContent() {
  const resultType = useSelector(select.results.resultsType);
  const clientName = useSelector(select.client.name);
  const networkSlug = useSelector(select.networks.currentSlug);

  const getNetworkContent = useCallback(
    (provider) => {
      const { healthPlan, curatedOutNetwork } = provider;
      const isPlaceSearch = resultType === PLACE_RESULT_TYPE;

      const LIGHT_BLUE = 'lightBlue';
      const DARK_GREEN = 'darkGreen';
      const APPLE_RED = 'appleRed';

      const networkContentOverrides = {
        [WALMART]: {
          inNetwork: {
            tooltipMessage:
              'This provider is in-network and has been designated by your medical plan as a preferred provider. You will receive your highest benefit when you get care from this provider.',
            textColor: DARK_GREEN,
          },
          preferred: {
            networkText: 'In Network Preferred',
          },
          nonPreferred: {
            networkText: 'In Network Non-Preferred',
            textColor: APPLE_RED,
            networkIcon: <LanguageIcon />,
            tooltipMessage:
              'This provider is in-network but has been designated by your medical plan as a non-preferred provider. Your coverage will be lower when you get care from this provider.',
          },
        },
        [MICROSOFT]: {
          inNetwork: {
            textColor: LIGHT_BLUE,
            showTooltip: true,
          },
          healthConnect: {
            networkText: 'Health Connect Plan',
            tooltipMessage:
              'You’ll generally pay less out of pocket when you receive care from a Health Connect provider versus an Extended Network provider.',
          },
          extendedNetwork: {
            networkText: 'Extended Network',
            textColor: DARK_GREEN,
            tooltipMessage:
              "You'll generally pay more out of pocket when you receive care from an Extended Network provider versus a Health Connect provider.",
          },
        },
        [POLK]: {
          inNetwork: {
            textColor: LIGHT_BLUE,
            showTooltip: true,
          },
        },
      };

      let networkContent = {
        networkText: 'In Network',
        textColor: '',
        networkIcon: <LanguageIcon color="secondary" />,
        tooltipMessage:
          'This provider has been designated as In Network by your medical plan. You will receive the highest benefit when you get care from an In Network provider.',
        showTooltip: false,
        showNetworkStatus: true,
      };

      // Walmart
      if (clientName === WALMART) {
        const nwa = networkSlug === 'northwestarkansas' || networkSlug === 'bcbsarkansas';
        const curatedOut = curatedOutNetwork === networkSlug || provider?.curatedOut;
        networkContent = { ...networkContent, ...networkContentOverrides[WALMART].inNetwork };

        if (!(isPlaceSearch || nwa)) {
          networkContent.showTooltip = true;
          if (curatedOut) {
            networkContent = {
              ...networkContent,
              ...networkContentOverrides[WALMART].nonPreferred,
            };
          } else {
            networkContent = { ...networkContent, ...networkContentOverrides[WALMART].preferred };
          }
        }
      }

      // Microsoft
      if (clientName === MICROSOFT) {
        const planPrefix = networkSlug?.toUpperCase();
        networkContent = { ...networkContent, ...networkContentOverrides[MICROSOFT].inNetwork };

        if (planPrefix === 'YMJ') {
          if (healthPlan === 'health_connect') {
            networkContent = {
              ...networkContent,
              ...networkContentOverrides[MICROSOFT].healthConnect,
            };
          } else {
            networkContent = {
              ...networkContent,
              ...networkContentOverrides[MICROSOFT].extendedNetwork,
            };
          }
        }

        if (isPlaceSearch) {
          networkContent.showNetworkStatus = false;
        }
      }

      // Polk
      if (clientName === POLK) {
        networkContent = { ...networkContent, ...networkContentOverrides[POLK].inNetwork };
      }

      return networkContent;
    },
    [clientName, networkSlug, resultType]
  );

  return getNetworkContent;
}
