import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, select } from 'store/toolkit';
import SkipLink from 'components/a11y/SkipLink';
import { useFocusTo } from 'utils/FocusRefContext';

export default function MapFeaturesA11yLinks() {
  const dispatch = useDispatch();
  const resultLocations = useSelector(select.results.mapFeatures);
  const focusTo = useFocusTo();

  const handleFocus = useCallback(
    (id) => dispatch(actions.results.setHoveredResult(id)),
    [dispatch]
  );
  const handleBlur = useCallback(() => dispatch(actions.results.clearHoveredResult()), [dispatch]);
  const handleClick = useCallback(
    (e, { resultId, latitude, longitude }) => {
      e.preventDefault();
      dispatch(actions.app.mapLocationClicked({ marker: { resultId }, latitude, longitude }));
      focusTo.resultCardMap();
    },
    [dispatch, focusTo]
  );

  return (
    <div role="list" aria-label="Results displayed on map">
      {resultLocations.map(({ srText, featureId, resultId, latitude, longitude }) => (
        <div role="listitem" key={featureId}>
          <SkipLink
            label={srText}
            onBlur={handleBlur}
            onFocus={() => handleFocus(featureId)}
            onClick={(e) => handleClick(e, { resultId, latitude, longitude })}
          />
        </div>
      ))}
    </div>
  );
}
