/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';

import ScrollToTop from 'components/ScrollToTop';
import ErrorBoundary from 'components/ErrorBoundary';
import AccessCheck from 'components/AccessCheck';

import initializeSentry from 'monitoring/initializeSentry';
import { thunks } from 'store/toolkit';
import ThemeProvider from 'themes/ThemeProvider';
import extractSsoParams from 'utils/extractSsoParams';
import store from './store/store';
import App from './App';

const [updatedUrl, { network, network_group: networkGroup }] = extractSsoParams(
  window.location.href
);
window.history.replaceState(null, null, updatedUrl);

initializeSentry();

store.dispatch(thunks.config.fetchClientConfig({ network, networkGroup }));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ErrorBoundary>
            <LastLocationProvider>
              <ScrollToTop>
                <AccessCheck>
                  <App />
                </AccessCheck>
              </ScrollToTop>
            </LastLocationProvider>
          </ErrorBoundary>
        </QueryParamProvider>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
