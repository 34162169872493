import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SECTION_CONTENT } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `2px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
  },
}));

export default function AboutSection({ sectionKey }) {
  const classes = useStyles();
  const section = SECTION_CONTENT[sectionKey];

  if (!section) return null;

  const { title, description, image, link } = section;
  const id = `about-${title.replaceAll(' ', '-').toLowerCase()}`;
  const altText = `${title.toLowerCase()} example`;
  const showLink = link?.to && link?.label;

  return (
    <Grid component="section" classes={{ root: classes.root }} id={id} tabIndex={-1}>
      <Grid item md={4}>
        <img src={image} alt={altText} />
      </Grid>
      <Grid item md={8}>
        <Typography variant="h3">{title}</Typography>
        <Typography>{description}</Typography>
        {showLink && (
          <Button
            classes={{ root: classes.buttonRoot }}
            variant="contained"
            color="primary"
            target="_blank"
            rel="noreferrer"
            href={link.to}
            aria-label={link.ariaLabel || ''}
          >
            {link.label}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

AboutSection.propTypes = {
  sectionKey: PropTypes.string.isRequired,
};
