import React from 'react';
import PropTypes from 'prop-types';

import AboutSection from './AboutSection';

export default function AboutSectionList({ sectionList = [] }) {
  return sectionList.map((sectionKey) => <AboutSection key={sectionKey} sectionKey={sectionKey} />);
}

AboutSectionList.prototypes = {
  sectionList: PropTypes.arrayOf(PropTypes.string),
};
