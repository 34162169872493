import React from 'react';
import { FilterMenuProps } from 'propTypes/index';
import { useSelector } from 'react-redux';
import { FormGroup, FormLabel, Typography } from '@material-ui/core';
import { select } from 'store/toolkit';

import SwitchFilter from './SwitchFilter';
import CheckFilterGroup from './CheckFilterGroup';

import RadiusSlider from './RadiusSlider';

function ProviderFilterMenu({ classes, chipOpen, handleCloseChip }) {
  const showExclusions = useSelector(select.filters.showExclusions); // true if any exclusion filter is not disabled
  const wasNotSubspecialtySearch = useSelector(select.results.wasNotSubspecialtySearch);

  return (
    <>
      <FormGroup row component="fieldset">
        <Typography component="legend" variant="srOnly">
          Provider Filters
        </Typography>
        {/* There is no need to conditionally render switches. The SwitchFilter will render null if filter is disabled in Redux */}
        <SwitchFilter filter="highPerforming" />
        <SwitchFilter filter="inNetworkPreferred" />
        <SwitchFilter filter="hasBenefitDiff" />
        <SwitchFilter filter="healthConnectPlan" />
        <SwitchFilter filter="acceptingNewPatients" />
        <SwitchFilter filter="telehealthAvailable" />
        <SwitchFilter filter="boardCertified" />
        <SwitchFilter filter="outcareCompetent" />
        <SwitchFilter filter="isWheelchairAccessible" />
        <SwitchFilter filter="featured" />
        <SwitchFilter filter="preferredGroup" />
      </FormGroup>

      <RadiusSlider chipOpen={chipOpen} onChipClose={handleCloseChip} />

      <CheckFilterGroup filter="specialties" hideAfter={4} />
      <CheckFilterGroup filter="gender" />
      <CheckFilterGroup filter="languages" hideAfter={4} />
      <CheckFilterGroup filter="credentials" hideAfter={4} />
      <CheckFilterGroup filter="hospitalAffiliations" hideAfter={4} />
      <CheckFilterGroup filter="groupAffiliations" hideAfter={4} />
      {wasNotSubspecialtySearch && <CheckFilterGroup filter="subspecialties" hideAfter={4} />}
      {
        // EXCLUSIONS
        showExclusions && (
          <FormGroup component="fieldset" style={{ display: 'block' }}>
            <FormLabel component="legend" className={classes.filterLabel}>
              Exclude
            </FormLabel>
            <SwitchFilter filter="excludeClientFeatured" />
          </FormGroup>
        )
      }
    </>
  );
}

ProviderFilterMenu.propTypes = FilterMenuProps;

export default ProviderFilterMenu;
