import { useState, useCallback, useMemo } from 'react';

/**
 * This hook is intended to setup a managed state for the MUI v4 Select component.
 * There is an issue with this component where the aria-expanded value is set to either "true" or undefined.
 *
 * Current a11y standards dictate that it must be explicitly set to "true" or "false" - not undefined.
 *
 * This issue is documented and resolved in later versions: https://github.com/mui/material-ui/issues/25399
 *
 * This hook is a reusable way to add the necessary props to any MUI v4 Select component
 * @param {boolean=} initialState;
 * @param {object=} SelectDisplayProps Other key/value pairs passed to the SelectDisplayProps prop
 * @example
 * // using the TextInput
 * const a11ySelectProps = useA11ySelectProps();
 *
 * <TextInput
 *   select
 *   name="mySelectInput"
 *   label="My Select Input"
 *   SelectProps={a11ySelectProps}
 * />
 *
 * @example
 * // using the plain MUI Select
 * const a11ySelectProps = useA11ySelectProps();
 *
 * <Select
 * 	value={val}
 *  onChange={handleChange}
 *  {...a11ySelectProps}
 * />
 */
export default function useA11ySelectProps(initialState = false, selectDisplayProps = {}) {
  const [open, setOpen] = useState(initialState);

  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const props = useMemo(
    () => ({
      open,
      onOpen,
      onClose,
      SelectDisplayProps: {
        ...selectDisplayProps,
        'aria-expanded': String(open),
      },
    }),
    [open, onOpen, onClose, selectDisplayProps]
  );

  return props;
}
