import React, { useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Dialog, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const VideoModal = withStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    overflow: 'hidden',
    maxHeight: '85vh',
    borderRadius: 0,
    '& > video': {
      width: '100%',
      height: '100%',
      maxHeight: '85vh',
    },
    '& #video-modal-close': {
      position: 'fixed',
      zIndex: theme.zIndex.modal + 1,
      top: 0,
      right: theme.spacing(0.5),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
}))(Dialog);

export default function PopupVideo(props) {
  const {
    autoPlay,
    ButtonComponent,
    children,
    disableControls,
    disableDownload,
    disableFullscreen,
    disableRemotePlayback,
    isInitiallyOpen,
    maxWidth,
    onClose,
    onOpen,
    openInFullscreen,
    poster,
    sources = [],
    title,
    tracks = [],
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const videoRef = useRef(null);

  const handleButtonClick = useCallback(() => {
    setIsOpen(true);
    onOpen();
    if (openInFullscreen) {
      try {
        setTimeout(() => videoRef.current?.requestFullscreen(), 100);
      } catch (e) {
        console.error(e);
      }
    }
  }, [setIsOpen, onOpen, openInFullscreen, videoRef]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen, onClose]);

  const videoControls = useMemo(() => {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#controlslist
    const controlList = [];
    if (disableDownload) controlList.push('nodownload');
    if (disableFullscreen) controlList.push('nofullscreen');
    if (disableRemotePlayback) controlList.push('noremoteplayback');

    return controlList.join(' ');
  }, [disableDownload, disableFullscreen, disableRemotePlayback]);

  return (
    <>
      <ButtonComponent onClick={handleButtonClick} {...rest}>
        {children}
      </ButtonComponent>
      <VideoModal
        fullWidth
        maxWidth={maxWidth}
        open={isOpen}
        onClose={handleCloseModal}
        scroll="body"
        closeAfterTransition={false} // https://stackoverflow.com/questions/79006592/aria-hidden-warning-on-closing-mui-dialogue
        aria-labelledby="video-modal-title"
      >
        <Typography component="h2" variant="srOnly" id="video-modal-title">
          {title}
        </Typography>
        <IconButton
          id="video-modal-close"
          aria-label="close video"
          autoFocus
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
        {/* track is provided via props */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption  */}
        <video
          controls={!disableControls}
          controlsList={videoControls}
          ref={videoRef}
          poster={poster}
          autoPlay={autoPlay}
          crossOrigin="true"
        >
          {sources.map((src) => (
            <source src={src.src} type={src.type} key={src.src} />
          ))}
          {tracks.map((trk) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <track kind="captions" key={trk.src} {...trk} />
          ))}
          Sorry, your browser does not support video playback.
        </video>
      </VideoModal>
    </>
  );
}

PopupVideo.propTypes = {
  autoPlay: PropTypes.bool,
  ButtonComponent: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  disableControls: PropTypes.bool,
  disableDownload: PropTypes.bool,
  disableFullscreen: PropTypes.bool,
  disableRemotePlayback: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  openInFullscreen: PropTypes.bool,
  poster: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      srcLang: PropTypes.string,
    })
  ).isRequired,
};

PopupVideo.defaultProps = {
  autoPlay: false,
  ButtonComponent: ButtonBase,
  disableControls: false,
  disableDownload: true,
  disableFullscreen: false,
  disableRemotePlayback: false,
  isInitiallyOpen: false,
  maxWidth: 'xl',
  onClose: () => {},
  onOpen: () => {},
  openInFullscreen: false,
  poster: undefined,
};
