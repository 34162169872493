import { useCallback } from 'react';
import { getLocalStorageExpire } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { actions } from 'store/toolkit';

/**
 * used to check local storage and show a high quality provider modal
 *
 * @export
 * @param {String} key
 * @param {Number} version
 * @param {String} modalToOpen
 * @param {Number} numberOfShows
 */
export default function useCheckStorageAndShowModal(key, version, modalToOpen, numberOfShows) {
  const dispatch = useDispatch();

  return useCallback(() => {
    const modalShownCount = getLocalStorageExpire(key, version);
    if (modalShownCount < numberOfShows) {
      dispatch(actions.ui.openModal(modalToOpen));
    }
  }, [dispatch, key, version, modalToOpen, numberOfShows]);
}
