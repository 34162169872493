import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import NoDataIcon from './NoDataIcon';

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default function AcceptingNewPatientsCell({ providerId }) {
  const classes = useStyles();
  const { acceptingNewPatients } = useSelector(select.provider(providerId).closestLocation) || {};

  if (typeof acceptingNewPatients !== 'boolean')
    return (
      <div className={classes.iconContainer}>
        <NoDataIcon />
      </div>
    );

  const alt = acceptingNewPatients ? 'is accepting new patients' : 'not accepting new patients';

  return (
    <div className={classes.iconContainer}>
      <CheckOrXIcon checked={acceptingNewPatients} titleAccess={alt} />
    </div>
  );
}

AcceptingNewPatientsCell.propTypes = {
  providerId: PropTypes.string.isRequired,
};
