import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, thunks } from 'store/toolkit';

export default function useFetchSpecialties() {
  const dispatch = useDispatch();
  const configIsLoading = useSelector(select.config.isLoading);

  useEffect(() => {
    if (!configIsLoading) {
      dispatch(thunks.specialties.fetchSpecialties());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configIsLoading]);
}
