import { DEFAULT_LOCATIONS, DOLLAR_GENERAL, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import DollarGeneralLoginLogo from 'assets/bcbst-login-logo.svg';

export const apiTokens = {
  dev: 'Token 8efcfae735d260e15f87805b150604ccba55b7d3',
  staging: 'Token f235f9d162b9e9f7008c8ae6180235864d3e52a1',
  prod: 'Token 6baf677e3de9c9681e2f592834f7f0b8346d3a15',
};

const token = selectToken(apiTokens);

const dollarGeneralConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'bcbst',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'dgbcbst',
  EMBOLD_CLIENT: DOLLAR_GENERAL,
  EMBOLD_CLIENT_SLUG: 'dgbcbst',
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Goodlettsville },
  CLOSED_ACCESS: true,
  LOGIN_NETWORK_NAME: 'dollarGeneralUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['dlgtn|dgiga|dgqnh|degmoks|dlzwi|dlhnj|pdgok|jdldc|dgsfl', 'i'],

  // content
  DISPLAY_NAME: 'Dollar General',
  SCHEDULE_PHONE_NUMBER: '1-800-521-9919',
  HEADER_LOGO: DollarGeneralLoginLogo,
  SUPPORT_LINK: 'https://dgsupport.emboldhealth.com/portal/',
  EMBOLD_LOGO_PRESENT: true,
  FORM_VARIANT: DROPDOWN,
  WELCOME_MESSAGE: `Dollar General and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: DollarGeneralLoginLogo,
  NO_WELCOME_LINK: false,
};

export default dollarGeneralConfig;
