import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { MenuItem, makeStyles } from '@material-ui/core';

import { select } from 'store/toolkit';
import TextInput from '../TextInput';

const useStyles = makeStyles((theme) => ({
  condensed: {
    width: 220,
    float: 'right',
    marginTop: 6,
    position: 'relative',
    '& .MuiInputBase-input': {
      padding: '4.5px !important',
      paddingLeft: '17px !important',
      paddingRight: '26px !important',
    },
  },
  root: {
    '& .MuiInputLabel-shrink': {
      paddingLeft: 2,
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    maxHeight: 'calc(100% - 120px)',
  },
}));

function DropdownNetworkSelector({ label, name, submitOnChange }) {
  const classes = useStyles();
  const networks = useSelector(select.networks.all);
  const slugs = useSelector(select.networks.availableSlugs);

  const formik = useFormikContext();
  const [field, , { setValue }] = useField(name);

  const handleChange = useCallback(
    (e, value) => {
      setValue(value.props.value);
      if (submitOnChange) {
        formik.submitForm(e);
      }
    },
    [formik, setValue, submitOnChange]
  );

  // https://v4.mui.com/api/menu/#props
  const anchorPopperToBottomProps = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    getContentAnchorEl: null,
  };

  return (
    <TextInput
      select
      name={name}
      label={label}
      fullWidth
      onChange={handleChange}
      value={field.value}
      className={clsx(classes.root, {
        [classes.condensed]: submitOnChange,
      })}
      inputProps={{
        MenuProps: {
          ...(field.value ? anchorPopperToBottomProps : {}),
          classes: { paper: classes.paper },
        },
      }}
    >
      {slugs.map((slug) => (
        <MenuItem key={slug} value={slug}>
          {networks[slug].text}
        </MenuItem>
      ))}
    </TextInput>
  );
}

DropdownNetworkSelector.defaultProps = {
  submitOnChange: false,
};

DropdownNetworkSelector.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  submitOnChange: PropTypes.bool,
};

export default React.memo(DropdownNetworkSelector);
