import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  ribbonBase: {
    display: 'inline-block',
    position: 'relative',
    height: 'auto',
    width: 'auto',
    fontWeight: 'bold',
    zIndex: 1,
    lineHeight: 1.8,
    fontSize: '.8rem',
  },
  ribbon: {
    maxWidth: '150%',
    margin: '2px 2px 2px -7px',
    paddingLeft: 7,
    background: theme.palette.success.light,
    float: 'left',
    zIndex: 2,
    '&:after': {
      content: '""',
      position: 'absolute',
      border: `11px solid ${theme.palette.success.light}`,
      borderRight: '10px solid transparent',
      top: 0,
      bottom: 0,
      transform: 'translateX(100%)',
      right: 10,
      zIndex: -1,
    },
  },
  redRibbon: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:after': {
      borderColor: theme.palette.error.main,
      borderRight: '10px solid transparent',
    },
  },
  orangeRibbon: {
    background: theme.palette.tertiary.main,
    '&:after': {
      borderColor: theme.palette.tertiary.main,
      borderRight: '10px solid transparent',
    },
  },
  grayRibbon: {
    background: '#ccc',
    '&:after': {
      borderColor: '#ccc',
      borderRight: '10px solid transparent',
    },
  },
  blueRibbon: {
    background: '#1D379C',
    color: 'white',
    whiteSpace: 'nowrap',
    '&:after': {
      border: `11px solid #1D379C`,
      borderRight: '10px solid transparent',
    },
  },
  floatRight: {
    position: 'absolute',
    paddingLeft: 20,
    paddingRight: 0,
    zIndex: 0,
  },
}));

// floatRight is to stack 'high performing" ribbon next to Dr score ribbon
/** @deprecated */
function CardRibbon({ variant, floatRight, children, className }) {
  const classes = useStyles();
  return (
    <div
      className={`card-ribbon ${className}`}
      style={{ position: 'relative', zIndex: floatRight ? 1 : 2 }}
    >
      <Typography
        className={clsx(`${classes.ribbonBase}`, {
          [classes.ribbon]: variant !== 'transparent',
          [classes.grayRibbon]: variant === 'gray',
          [classes.redRibbon]: variant === 'red',
          [classes.orangeRibbon]: variant === 'orange',
          [classes.blueRibbon]: variant === 'blue',
          [classes.floatRight]: floatRight,
        })}
      >
        {children}
      </Typography>
    </div>
  );
}

CardRibbon.defaultProps = {
  floatRight: false,
  children: null,
  className: '',
};

CardRibbon.propTypes = {
  variant: PropTypes.oneOf(['transparent', 'gray', 'red', 'orange', 'blue']).isRequired,
  floatRight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
};

export default CardRibbon;
