export const CONVERSION = 'conversion';
export const UI_INTERACTION = 'ui_interaction';
export const RETURNING_USER_SESSION = 'returning_user_session';
export const NETWORK_SLUG_UPDATE = 'network_slug_update';
export const LOCATION_SEARCH_SUBMISSION = 'location_search_submission';
export const CHAT_TERMS_ACCEPTED = 'chat_terms_accepted';
export const SEARCH_SUBMISSION = 'search_submission';
export const CHAT_VIEW = 'chat_view';
export const RESULT_VIEW = 'result_view';
export const EXPAND_SEARCH = 'expand_search';
export const USER_LOGIN = 'user_login';
export const USER_REDIRECTED = 'user_redirected';

export const VALID_CATEGORIES = [
  CONVERSION,
  UI_INTERACTION,
  RETURNING_USER_SESSION,
  NETWORK_SLUG_UPDATE,
  LOCATION_SEARCH_SUBMISSION,
  CHAT_TERMS_ACCEPTED,
  SEARCH_SUBMISSION,
  CHAT_VIEW,
  RESULT_VIEW,
  EXPAND_SEARCH,
  USER_LOGIN,
  USER_REDIRECTED,
];
