import React, { useMemo } from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import { Box, Link, Typography } from '@material-ui/core';

import { highlightAllMatches } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& button': {
      fontSize: '.9rem',
      textAlign: 'left',
    },
    '& p': {
      fontStyle: 'italic',
      color: theme.palette.grey[700],
    },
  },
}));
export default function SearchAppendixItem({ onClick, title, description, filter }) {
  const classes = useStyles();

  const highlightedTitle = useMemo(
    () => (filter?.length ? highlightAllMatches(filter, title) : title),
    [filter, title]
  );
  const highlightedDescription = useMemo(
    () => (filter?.length ? highlightAllMatches(filter, description) : description),
    [description, filter]
  );
  return (
    <Box classes={classes}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onClick={onClick} component="button" classes={{ root: classes.title }}>
        {highlightedTitle}
      </Link>
      {description && (
        <Typography classes={{ root: classes.description }}>{highlightedDescription}</Typography>
      )}
    </Box>
  );
}

SearchAppendixItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  filter: PropTypes.string,
};

SearchAppendixItem.defaultProps = {
  description: null,
  filter: '',
};
