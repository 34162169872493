import React, { useCallback } from 'react';
import PropTypes, { RefType } from 'propTypes';
import { useField } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';

function CheckboxInput({
  name,
  label,
  innerRef,
  required,
  className,
  CheckboxProps = {},
  ...props
}) {
  const [field, meta, helpers] = useField(name);

  const { onChange: formikChangeHandler, value, ...rest } = field;

  // the default Formik onChange function is built to handle events from a text input, not a checkbox
  // creating this custom handleChange will ensure that the value and touched attributes are tracked correctly
  const handleChange = useCallback(
    (evt) => {
      helpers.setTouched(true);
      helpers.setValue(evt.target.checked);
    },
    [helpers]
  );

  const helperTextId = `${field.name}-helper-text`;

  const isError = Boolean(meta.touched && meta.error);
  return (
    <FormControl error={isError} required={required} {...props}>
      <FormControlLabel
        control={
          <Checkbox
            id={field.name}
            onChange={handleChange}
            value={value}
            checked={value}
            inputRef={innerRef}
            inputProps={{
              required,
              'aria-describedby': helperTextId,
              'aria-invalid': String(isError),
              ...rest,
            }}
            {...CheckboxProps}
          />
        }
        label={label}
        required={required}
        className={className}
      />
      {isError && <FormHelperText id={helperTextId}>{meta.error}</FormHelperText>}
    </FormControl>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  innerRef: RefType,
  required: PropTypes.bool,
  CheckboxProps: PropTypes.shape({}),
  className: PropTypes.string,
};

CheckboxInput.defaultProps = {
  label: undefined,
  required: false,
  innerRef: null,
  CheckboxProps: {},
  className: undefined,
};

export default React.forwardRef((props, ref) => <CheckboxInput innerRef={ref} {...props} />);
