/**
 * https://docs.sentry.io/product/issues/issue-details/breadcrumbs/#breadcrumb-attributes
 * @typedef Breadcrumb
 * @property {String} category
 * @property {String=} message
 * @property {String} type
 * @property {object=} data
 * @property {String} level
 */

/**
 * This function is designed to accept an html element, and return a human readable string that can be used to identify the html element on the page
 * @param {HTMLElement} element
 * @param {number} recursionDepth
 * @returns {string|null}
 */
export function getReadableElementName(element, recursionDepth = 0) {
  const MAX_RECURSION_DEPTH = 5;
  if (recursionDepth > MAX_RECURSION_DEPTH) return null;

  if (element.tagName === 'BODY') return '<body>';
  // return easily identified labels if available
  if (element.id) return `#${element.id}`; // the most unique identifier available
  if (element.ariaLabel) return element.ariaLabel;
  if (element.innerText) return element.innerText;
  if (element.alt) return element.alt;

  // if the element has label elements attached, use the first label element
  if (element.labels?.length) {
    return getReadableElementName(element.labels[0], recursionDepth);
  }
  // check the parent element for the same values
  if (element.parentElement) {
    return getReadableElementName(element.parentElement, recursionDepth);
  }

  return null;
}

/**
 * @param {Breadcrumb} breadcrumb
 * @param {*} hint
 */
export function handleUiClickBreadcrumb(breadcrumb, hint) {
  const { target: htmlElement } = hint.event;
  if (!htmlElement) return breadcrumb;

  const elementName = getReadableElementName(htmlElement);
  if (elementName) breadcrumb.message = elementName;
  return breadcrumb;
}

/**
 * @param {Breadcrumb} breadcrumb
 * @param {*} hint
 */
export function handleFetchBreadcrumb(breadcrumb) {
  const { url } = breadcrumb.data;

  // filter out fetch calls that are not useful
  if (
    url.startsWith('https://atlas.microsoft.com/styling') ||
    url.startsWith('https://atlas.microsoft.com/map/') ||
    url.startsWith('data:image') ||
    url.includes('emboldhealth.com/events/')
  ) {
    return null;
  }

  return breadcrumb;
}

/**
 * This function runs before every breadcrumb is added to the Sentry scope.
 * Returning null will filter the breadcrumb out.
 * @param {Breadcrumb} breadcrumb
 * @param {*} hint
 * @returns {Breadcrumb|null}
 */
export default function beforeBreadcrumb(breadcrumb, hint) {
  switch (breadcrumb.category) {
    case 'ui.click':
    case 'ui.input':
      return handleUiClickBreadcrumb(breadcrumb, hint);
    case 'fetch':
    case 'xhr':
      return handleFetchBreadcrumb(breadcrumb, hint);
    default:
      return breadcrumb;
  }
}
