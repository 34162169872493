import React, { useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  ticketStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '3rem',
    color: theme.palette.success.main,
    marginBottom: theme.spacing(1),
  },
}));

export default function SupportTicketSuccess() {
  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return (
    <div
      className={classes.ticketStatus}
      role="status"
      aria-live="assertive"
      tabIndex={-1}
      ref={ref}
    >
      <CheckCircleOutline className={classes.icon} />
      <Typography variant="h3" component="strong">
        Your ticket was submitted successfully!
      </Typography>
    </div>
  );
}
