import { DEFAULT_LOCATIONS, DROPDOWN_AUTOCOMPLETE, WALMART } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import walmartLogo from 'assets/pg-walmart-header-logo.png';
import walmartLoginLogo from 'assets/wmt.png';
import { getEnv } from 'utils/utils';

export const apiTokens = {
  dev: 'Token 878f51af12d0432d04d00b538c0289d13a8c33a1',
  staging: 'Token 1dede7e7d6641a20bf5c8176614a500548b17752',
  prod: 'Token cdfb7df55a175e0b6c56064012d3c6c8dac52c2f',
};

const token = selectToken(apiTokens);

const walmartConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: getEnv() === 'production' ? 'wpg' : 'walmart',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'walmart',
  EMBOLD_CLIENT: WALMART,
  EMBOLD_CLIENT_SLUG: 'wmt',
  SHOW_BCBS_LINK: true,
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: true,
  HIGH_QUALITY_INTRO_MODAL: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Bentonville },
  HAS_PORTAL_INTEGRATION: true,
  CLOSED_ACCESS: true,
  LOGIN_NETWORK_NAME: 'wmtLoggedIn',
  LOGIN_COOKIE_AUTH_REGEX: [
    '^(sanantonio|houston|dallasfw|tulsaokc|northeastflorida|centralflorida|northwestarkansas|aetnaillinois|aetnaflorida|bcbsarkansas|bcbsflorida|bcbsillinoisindiana|bcbsoklahoma|bcbstexas)$',
  ],

  // content
  DISPLAY_NAME: 'Walmart',
  SCHEDULE_PHONE_NUMBER: null,
  HEADER_LOGO: walmartLogo,
  SUPPORT_LINK: 'https://walmartsupport.emboldhealth.com/portal/home',
  MEMBER_ID_LINK:
    'https://walmartmybenefits.blueadvantagearkansas.net/login/login.aspx?ReturnUrl=%2fsecure%2fid.aspx',
  SHOW_CONTACT_BCBS_ROUTE: true,
  NO_WELCOME_LINK: true,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Walmart and Embold Health have partnered to bring you Embold’s Provider Guide, your guide
  to quickly and easily finding the doctors in your network and area that have proven to
  provide the best care. Select your region to find Embold Health’s highest quality
  providers in your area that are also in-network within your plan.`,
  LOCAL_LOGO_ASSET: walmartLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
};

export default walmartConfig;
