import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { select, actions } from 'store/toolkit';

export default function useEnsembleNetworksOptionsByZipcode(zipcode, inputError) {
  const axios = useSelector(select.axios.axiosInstance);
  const dispatch = useDispatch();

  const availableGroupSlugs = useSelector(select.networks.availableGroupSlugs);

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOptions = useCallback(
    async (groupSlugs, zip) => {
      // create an array of resolved promises
      const responses = await Promise.all(
        // fetch the top sorted and matching network in every available network group
        groupSlugs.map((slug) =>
          axios.get(`networks/?limit=1&zipcode=${zip}&network_group=${slug}`)
        )
      );

      // the responses will be an array of arrays
      // create a new flattened array of the data
      const result = responses.map((res) => res.data).flat();
      return result;
    },
    [axios]
  );

  useEffect(() => {
    if (zipcode?.length === 5 && availableGroupSlugs.length && !inputError) {
      setIsLoading(true);
      setOptions([]);
      fetchOptions(availableGroupSlugs, zipcode)
        .then((res) => setOptions(res))
        .catch((e) => {
          setError(e);
          dispatch(
            actions.notifications.create({
              message:
                'There was an error getting your available networks. Please reenter your ZIP or refresh the page.',
              severity: 'error',
            })
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [axios, zipcode, availableGroupSlugs, fetchOptions, dispatch, inputError]);

  return { options, isLoading, error };
}
