import React, { useMemo } from 'react';
import PropTypes from 'propTypes';
import clsx from 'clsx';
import { ListItem, Box, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Tooltip, { TooltipPropsShape } from 'components/Tooltip';
import useIconNode from 'hooks/useIconNode';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  icon: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    '& > img, svg': {
      width: 20,
      height: 20,
    },
  },
  tooltipIcon: {
    margin: 0,
    marginLeft: 10,
  },
  linkRoot: {
    display: 'inline-flex',
  },
}));

export default function Attribute({
  isListItem,
  text,
  icon,
  iconAltText,
  additionalIconClass,
  className,
  LinkProps,
  TooltipProps,
  TypographyProps,
  ...props
}) {
  const classes = useStyles();

  const hasTooltip = Boolean(TooltipProps?.message);
  const hasLink = Boolean(LinkProps?.href);

  const iconEl = useIconNode(icon, iconAltText);
  const Wrapper = useMemo(() => {
    if (hasTooltip) return Tooltip;
    if (hasLink) return Link;
    return React.Fragment;
  }, [hasLink, hasTooltip]);

  const WrapperProps = useMemo(() => {
    if (hasTooltip)
      return {
        ...TooltipProps,
        title: TooltipProps?.title || text,
        ariaLabel: TooltipProps?.ariaLabel,
        additionalClasses: { icon: classes.tooltipIcon },
      };
    if (hasLink)
      return {
        ...LinkProps,
        classes: { root: classes.linkRoot },
        target: '_blank',
        rel: 'noopener noreferrer',
      };
    return {};
  }, [LinkProps, TooltipProps, classes, hasLink, hasTooltip, text]);

  const Container = isListItem ? ListItem : Box;
  return (
    <Container className={clsx(classes.root, { [className]: className })} {...props}>
      <Wrapper {...WrapperProps}>
        {iconEl && (
          <div
            className={clsx(classes.icon, { [additionalIconClass]: Boolean(additionalIconClass) })}
          >
            {iconEl}
          </div>
        )}
        <Typography {...TypographyProps}>{text}</Typography>
      </Wrapper>
    </Container>
  );
}

Attribute.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  additionalIconClass: PropTypes.string,
  isListItem: PropTypes.bool,
  iconAltText: PropTypes.string,
  TooltipProps: TooltipPropsShape,
  /** MUI Typography Props: https://v4.mui.com/api/typography/#props */
  TypographyProps: PropTypes.shape({}),
  /** MUI Typography Props: https://v4.mui.com/api/link/#props */
  LinkProps: PropTypes.shape({
    href: PropTypes.string,
  }),
};

Attribute.defaultProps = {
  className: '',
  additionalIconClass: undefined,
  isListItem: true,
  iconAltText: '',
  icon: <CheckCircleOutlineIcon color="inherit" />,
  LinkProps: {},
  TooltipProps: {},
  TypographyProps: {},
};
