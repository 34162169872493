import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
}));

export default function ChatNoResults({ searchName, locationName, error }) {
  const classes = useStyles();

  let title = 'No Results';
  let message = `We were unable to find a ${searchName} provider matching your exact needs within 250 miles of 
  ${locationName || 'your location'}`;

  if (error) {
    title = 'Sorry, An Unexpected Error Occurred';
    message = `There was an unexpected error in completing your search for ${searchName}`;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h2" component="strong" color="primary">
        {title}
      </Typography>

      <Typography component="p" variant="subtitle1" color="primary">
        {message}
      </Typography>
    </Paper>
  );
}

ChatNoResults.propTypes = {
  searchName: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

ChatNoResults.defaultProps = {
  error: false,
};
