import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes, { PlaceDataShape, ProviderLocationDataShape } from 'propTypes/index';

import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';

import { showInMaps } from 'utils/utils';
import { select } from 'store/toolkit';

import { _analyticsActions } from 'analytics';
import CardIconButton from './StyledWrappers/CardIconButton';

function DirectionsButton({ resultId, location, distance, additionalClasses }) {
  const { MAPS_URL } = useSelector(select.config.values);
  const resultById = useSelector(select.results.byId(resultId));
  const dispatch = useDispatch();

  const handleDirectionsClick = (e) => {
    e.stopPropagation();
    showInMaps(location, MAPS_URL);

    dispatch(_analyticsActions.directionsOpenedFromResultCard(resultId));
  };

  if (!location || !distance) return null;

  return (
    <CardIconButton
      text={`${distance} mi.`}
      onClick={handleDirectionsClick}
      icon={<DirectionsOutlinedIcon color="secondary" />}
      additionalClasses={additionalClasses}
      role="link"
      aria-label={`${
        resultById.entityName ?? resultById.name
      } Get directions in new tab. ${distance} mi.`}
    />
  );
}

export default React.memo(DirectionsButton);

DirectionsButton.propTypes = {
  resultId: PropTypes.string.isRequired,
  location: PropTypes.oneOfType([ProviderLocationDataShape, PlaceDataShape]).isRequired,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  additionalClasses: PropTypes.string,
};

DirectionsButton.defaultProps = {
  additionalClasses: '',
};
