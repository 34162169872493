import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function SupportPageSection({ title, className, headingLevel, ...props }) {
  const classes = useStyles();

  // Add id to section used in hash routing. If none is provided use the title but strip spaces
  const id = props.id || title?.replaceAll(' ', '') || null;

  return (
    <section className={`${classes.root} ${className}`} id={id} tabIndex={-1}>
      {Boolean(title) && (
        <Typography variant="h2" component={headingLevel}>
          {title}
        </Typography>
      )}
      {props.children}
    </section>
  );
}

SupportPageSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

SupportPageSection.defaultProps = {
  children: null,
  className: '',
  id: undefined,
  title: undefined,
  headingLevel: 'h2',
};
