import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { ICUBA, WALMART, SOCT, SOCT_PROVIDER_LOOKUP } from 'utils/constants';
import WMTBenefitsNotifierModal from './ClientModals/WMTBenefitsNotifierModal';
import IcubaZeroCopayModal from './ClientModals/IcubaZeroCopayModal';
import SOCTProvidersOfDistinction from './ClientModals/SOCTProvidersOfDistinction';

export default function HighQualityModal() {
  const clientName = useSelector(select.client.name);
  const configIsLoading = useSelector(select.config.isLoading);
  const hasHighQualityIntroModal = useSelector(select.featureFlags.highQualityIntroModal);
  const networkSlug = useSelector(select.networks.currentSlug);
  const location = useLocation();

  const isLoginPage = useMemo(() => location?.pathname === '/login', [location]);

  const renderHighQualityModal = useMemo(
    () => [!configIsLoading, networkSlug, !isLoginPage, hasHighQualityIntroModal].every(Boolean),
    [configIsLoading, hasHighQualityIntroModal, isLoginPage, networkSlug]
  );

  if (!renderHighQualityModal) return null;

  if (clientName === WALMART) {
    return <WMTBenefitsNotifierModal />;
  }

  if (clientName === ICUBA) {
    return <IcubaZeroCopayModal />;
  }

  if (clientName === SOCT || clientName === SOCT_PROVIDER_LOOKUP) {
    return <SOCTProvidersOfDistinction />;
  }

  return null;
}
