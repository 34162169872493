import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

export default function StarBoxed(props) {
  const { palette } = useTheme();
  const backgroundColor = palette.success.main;
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM12.2352 3.64712C12.1554 3.42756 11.8449 3.42756 11.7652 3.64712L9.77742 9.12111C9.74252 9.21724 9.65258 9.28242 9.55036 9.28566L3.69703 9.47132C3.46256 9.47876 3.36652 9.77592 3.55229 9.91917L8.17501 13.4838C8.25652 13.5466 8.29118 13.653 8.26233 13.7518L6.63177 19.3359C6.56619 19.5605 6.81749 19.7441 7.01149 19.6133L11.8604 16.3445C11.9449 16.2876 12.0554 16.2876 12.1399 16.3445L16.9888 19.6133C17.1828 19.7441 17.4341 19.5605 17.3686 19.3359L15.738 13.7518C15.7092 13.653 15.7438 13.5466 15.8253 13.4838L20.448 9.91917C20.6338 9.77592 20.5378 9.47876 20.3033 9.47132L14.45 9.28566C14.3478 9.28242 14.2578 9.21724 14.2229 9.12111L12.2352 3.64712Z"
        fill={backgroundColor}
      />
    </SvgIcon>
  );
}
