/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit/';
import { memoize } from 'lodash';

import { distance, resultIsPlaceType, getTierDesignationContent } from 'utils/utils';
import * as selectResults from './selectResults';
import * as selectClient from '../config/selectClient';
import * as selectContent from '../config/selectContent';
import * as selectFeatureFlags from '../config/selectFeatureFlags';
import * as selectNetworks from '../config/selectNetworks';
import { getPlaceNetworkContent } from './placeUtils';

/* ************************************************ */
/* **** Initial selectPlaceById Function ******* */
/* ************************************************ */

/** This function selects a result by id from the results object. It verifies that the given result exists, AND is a place object.
 *  @param {string} id @returns {Function} A selector function
 * */
const placeById = memoize((id) =>
  createSelector([selectResults.all], (resultObject) => {
    const place = resultObject[id];
    if (!place) return undefined;

    if (!resultIsPlaceType(resultObject[id])) {
      return undefined;
    }
    return place;
  })
);

/* ************************************************ */
/* *********** Computed Properties **************** */
/* ************************************************ */

/**
 * Returns the distance between the place, and the coordinates of the last search (from resultsSlice) in miles
 * @returns {Function} */
function distanceInMiles(id) {
  return createSelector([placeById(id), selectResults.coordinates], (place, locationSearched) => {
    if (!place) return null;
    if (!locationSearched.latitude || !locationSearched.longitude) return null; // can't determine a distance if we don't have a location to compare to

    // // if distance has not been calculated by the backend, calculate it here
    return distance(
      locationSearched.latitude,
      locationSearched.longitude,
      place.latitude,
      place.longitude
    );
  });
}

/** @returns {Function} */
function tierDesignation(id) {
  return createSelector([placeById(id), selectNetworks.currentSlug], (place, networkSlug) => {
    if (!place) return null;
    if (!place.tiering) return null;

    const tier = place.tiering[networkSlug];

    if (!tier) return null;
    return getTierDesignationContent(tier);
  });
}

/**
 * @param {string} id Place id
 * @returns {string} A string representation of the place object used for copy and paste feature
 */
export function getPlaceString(id) {
  return createSelector(
    [placeById(id), distanceInMiles(id), selectResults.wasPlaceSearch],
    (place, distanceInMiles, wasPlaceSearch) => {
      if (!wasPlaceSearch) return null;
      const NA = 'N/A'; // constant used when no value is available

      const distance = distanceInMiles || NA;

      let result = ''; // complete string to be returned at the end

      const appendLine = (text) => {
        result += `\n${text}`;
      };
      const appendHeader = (text) => {
        appendLine(`--${text}--`);
      };
      // name
      result = `Name: ${place.name}`;

      // OVERVIEW SECTION
      appendHeader('Overview');

      // get the text used for Network Coverage. This function comes from the <CoverageRibbon />
      appendLine('Network Coverage: In Network');

      appendLine(`Phone Number: ${place.phone}`);

      appendLine(`Distance: ${distance} miles`);
      appendLine(`Hospital Safety Grade: ${place.hospitalSafetyGrade || NA}`);
      appendLine(`Address: ${place.address1}`);
      appendLine(`Handicap Accessible: ${place.isWheelchairAccessible || NA}`);
      appendLine(`NPI: ${place.npi}`);

      return result;
    }
  );
}

export function getPlacesString(ids) {
  const placeStrings = ids.map((id) => getPlaceString(id));
  return createSelector(
    [selectResults.wasPlaceSearch, ...placeStrings],
    (wasPlaceSearch, ...placeStrings) => {
      if (!wasPlaceSearch) return null;
      let result = '';
      if (placeStrings?.length) result += placeStrings.join('\n\n');
      return result;
    }
  );
}

/** @returns {Function} */
function networkContent() {
  return createSelector([selectClient.name], (client) => getPlaceNetworkContent(client));
}

function isClientFeatured(id) {
  return createSelector(
    [placeById(id), selectFeatureFlags.showClientFeatured, selectContent.featuredPlaceText],
    (place, showClientFeatured, featuredPlaceText) => {
      if (!place) return null;
      if (!showClientFeatured) return false; // cannot be featured if the feature is disabled
      if (!featuredPlaceText) return false; // cannot be featured if there is no global text to show

      return Boolean(place.clientFeatured);
    }
  );
}

function clientFeaturedPill(id) {
  return createSelector(
    [isClientFeatured(id), selectContent.featuredPlaceText],
    (isClientFeatured, clientFeaturedContent) => {
      if (!isClientFeatured) return null;
      if (!clientFeaturedContent) return null;

      const { banner, tooltip, color, icon, link } = clientFeaturedContent;
      const props = {
        label: banner,
        color,
        iconUrl: icon,
        TooltipProps: {
          message: tooltip,
          ...(link ? { href: { to: link, label: 'More Info Here' } } : {}),
        },
      };

      return props;
    }
  );
}

function clientFeaturedBadge(id) {
  return clientFeaturedPill(id); // badge uses all of the same props as the pill
}

/* ************************************************ */
/* *************** Master Function **************** */
/* ************************************************ */

/**
 * This master selectPlace function accepts an id string and builds selectors for that given id.
 * Every selector needs to be passed into useSelector or given the argument of state to return a value.
 * @param {string} id
 *
 * @example
 * // in a component
 * const computedProperty = useSelector(selectPlace('abc').computedProperty);
 *
 * // in a thunk
 * const state = getState();
 * const computedProperty = selectPlace('123').computedProperty(state);
 */
function selectPlace(id) {
  // return an object of selectors
  return {
    data: placeById(id),
    distanceInMiles: distanceInMiles(id),
    tierDesignation: tierDesignation(id),
    networkContent: networkContent(),
    isClientFeatured: isClientFeatured(id),
    clientFeaturedPill: clientFeaturedPill(id),
    clientFeaturedBadge: clientFeaturedBadge(id),
  };
}

// this ensures that when we call select.place('abc'), it generates n number of selectors for us. But calling select.place('abc') again does not rebuild the exact same n number of selectors
export default memoize(selectPlace);
