import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { select } from 'store/toolkit';
import PageTemplate from 'components/Pages/PageTemplate';
import aboutContentMap from './aboutContentMap';
import AboutProviderScores from './AboutProviderScores';
import AboutSectionList from './AboutSectionList';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(3)}px ${theme.spacing(30)}px`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
  },
}));

function AboutPage() {
  const classes = useStyles();
  const client = useSelector(select.client.name);
  const smDown = useSelector(select.ui.isSmDown);
  const aboutContent = aboutContentMap[client];
  const profileImage = smDown
    ? aboutContent.providerProfileImageSm
    : aboutContent.providerProfileImageLg;

  return (
    <Grid className={classes.container}>
      <AboutProviderScores
        image={profileImage}
        description={aboutContent.providerScoreDescription}
      />
      <AboutSectionList sectionList={aboutContent.sectionList} />
    </Grid>
  );
}

export default PageTemplate(AboutPage, 'About');
