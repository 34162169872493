import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Chip, Grid } from '@material-ui/core';

import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';

const useStyles = makeStyles((theme) => ({
  listHeader: {
    display: 'block',
  },
  bold: {
    fontWeight: 'bold',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

export function BreadcrumbMessage({ visible, total, searchTerm, ariaHidden }) {
  const classes = useStyles();
  return (
    <Typography
      className={`${classes.listHeader} results-list-header`}
      color="textSecondary"
      component="span"
      role="alert"
      aria-hidden={ariaHidden}
    >
      <span className={classes.bold}>{searchTerm && `${searchTerm}: `}</span>
      <span>
        Showing {visible} of {total} results
      </span>
    </Typography>
  );
}

BreadcrumbMessage.propTypes = {
  visible: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  ariaHidden: PropTypes.bool.isRequired,
};

BreadcrumbMessage.defaultProps = {
  searchTerm: null,
};

export function LoadingStatusMessage() {
  const classes = useStyles();
  return (
    <div className={classes.loadingContainer}>
      <EmboldLoadingSpinner className={classes.spinner} size={20} aria-hidden />
      <Typography className={`${classes.listHeader} results-list-header`} component="span">
        Searching...
      </Typography>
    </div>
  );
}

const BreadcrumbButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    fontWeight: 700,
    fontSize: '.875rem',
    height: 'auto',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}))(Chip);

const Separator = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    color: theme.palette.grey[600],
    padding: '0 6px',
  },
}))(Typography);

export function Breadcrumbs({ visible, total, searchTerm, parentSpecialty, onClick, ariaHidden }) {
  const breadcrumbMessageProps = { visible, total, searchTerm, ariaHidden };
  return parentSpecialty ? (
    <Grid container>
      <BreadcrumbButton
        role="button"
        aria-label={`search for results in ${parentSpecialty.specialtyName}`}
        label={parentSpecialty.specialtyName}
        onClick={onClick}
      />
      <Separator component="span" aria-hidden>
        {'>'}
      </Separator>
      <BreadcrumbMessage {...breadcrumbMessageProps} />
    </Grid>
  ) : (
    <BreadcrumbMessage {...breadcrumbMessageProps} />
  );
}

Breadcrumbs.propTypes = {
  visible: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  parentSpecialty: PropTypes.shape({
    specialtyName: PropTypes.string.isRequired,
  }),
  ariaHidden: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  parentSpecialty: null,
  ariaHidden: false,
};
