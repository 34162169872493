import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Grid, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useToggleState from 'hooks/useToggleState';
import useUuid from 'hooks/useUuid';
import ShowMoreButton from './ShowMoreButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius * 6,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
  labelIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *:first-child': { fontSize: `calc(${theme.typography.body1.fontSize} * 1.7)` },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: ({ isMobile }) => (isMobile ? 'flex-start' : 'center'),
    padding: `0px ${theme.spacing(1)}px`,
  },
  showMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiButton-label': {
      color: theme.palette.grey[700],
    },
  },
  contentContainer: {
    marginTop: theme.spacing(1),
  },
}));

/*
 * a11y pattern followed: https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/
 */

export default function ExpandingPill({
  label,
  labelIcon,
  title,
  additionalClass,
  children,
  variant,
  ButtonProps,
  onOpen,
  onClose,
  ...props
}) {
  const componentId = useUuid();
  const isMobile = variant === 'mobile';
  const classes = useStyles({ isMobile });
  const [isExpanded, toggleIsExpanded] = useToggleState(false);

  const hasChildren = Boolean(children);

  const componentIds = useMemo(
    () => ({
      root: `${componentId}-root`,
      content: `${componentId}-content`,
      showMoreSection: `${componentId}-show-more`,
      titleSection: `${componentId}-title`,
      labelSection: `${componentId}-label`,
    }),
    [componentId]
  );

  const labelSection = useMemo(
    () => (
      <Grid item className={classes.labelContainer} key={componentIds.labelSection}>
        {Boolean(labelIcon) && <div className={classes.labelIcon}>{labelIcon}</div>}
        {label}
      </Grid>
    ),
    [labelIcon, label, classes, componentIds]
  );

  const titleSection = useMemo(
    () => (
      <Grid
        item
        xs={isMobile ? 12 : 7}
        className={classes.titleContainer}
        id={componentIds.titleSection}
        key={componentIds.titleSection}
      >
        {title}
      </Grid>
    ),
    [title, classes, isMobile, componentIds]
  );

  const showMoreSection = useMemo(
    () => (
      <Grid item key={componentIds.showMoreSection} className={classes.showMoreContainer}>
        <ShowMoreButton
          isOpen={isExpanded}
          onClick={() => {
            if (isExpanded) onClose();
            else onOpen();
            toggleIsExpanded();
          }}
          ariaLabel={label}
          ariaControls={componentIds.content}
          {...ButtonProps}
        />
      </Grid>
    ),
    [isExpanded, toggleIsExpanded, label, componentIds, ButtonProps, onOpen, onClose, classes]
  );

  const pillElements = useMemo(() => {
    // determine the order of render based on isMobile
    if (isMobile) return [labelSection, showMoreSection, titleSection];
    return [labelSection, titleSection, showMoreSection];
  }, [labelSection, titleSection, showMoreSection, isMobile]);

  return (
    <Box
      className={clsx(classes.root, { [additionalClass]: additionalClass })}
      id={componentIds.root}
      {...props}
    >
      <Grid container justifyContent="space-between">
        {pillElements}
      </Grid>
      {hasChildren && (
        <Collapse in={isExpanded}>
          <div id={componentIds.content} role="region" aria-labelledby={componentIds.titleSection}>
            {children}
          </div>
        </Collapse>
      )}
    </Box>
  );
}

ExpandingPill.propTypes = {
  children: PropTypes.node,
  additionalClass: PropTypes.string,
  label: PropTypes.node.isRequired,
  labelIcon: PropTypes.node,
  title: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['desktop', 'mobile']),
  ButtonProps: PropTypes.shape({}),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  borderColor: PropTypes.string,
};

ExpandingPill.defaultProps = {
  children: null,
  additionalClass: undefined,
  labelIcon: null,
  variant: 'desktop',
  ButtonProps: {},
  onOpen: () => {},
  onClose: () => {},
  borderColor: 'text.secondary',
};
