import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { setLocalStorageExpire } from 'utils/utils';
import { fetchDefaultLocationFromZipcode } from 'store/slices/location/locationThunks';
import { MEMBER_CREDENTIALS, LOGIN_NETWORK_NAME_VERSION, ENSEMBLE_FORM } from 'utils/constants';
import select from './select';

// eventually there will be more thunks so stick to named exports
/* eslint-disable import/prefer-default-export */
export const userLoggedIn = createAsyncThunk('userLoggedIn', async (formValues, thunkApi) => {
  const { networkSlug } = formValues;
  const { getState, dispatch } = thunkApi;

  let state;
  state = getState();

  // selectors
  const clientSlug = select.client.slug(state);
  const formVariant = select.client.loginFormVariant(state);
  const loginNetworkName = select.client.loginNetworkName(state);
  const networkSelectApiPath = select.config.networkSelectApiPath(state);
  const axios = select.axios.axiosInstance(state);

  // send analytics
  try {
    const networkSelectPayload = {
      client_slug: clientSlug,
      network_slug: networkSlug,
    };

    if (formVariant === MEMBER_CREDENTIALS) {
      networkSelectPayload.member_id = `${networkSlug.toUpperCase()}-${formValues.id}-${
        formValues.suffix
      }`;
    }

    axios.post(`/${networkSelectApiPath}`, networkSelectPayload);
  } catch (e) {
    Sentry.captureException(e);
  }

  if (formVariant === ENSEMBLE_FORM) {
    await dispatch(
      fetchDefaultLocationFromZipcode({
        zipcode: formValues.zipcode,
        networkSlug: formValues.networkSlug,
      })
    );
  }

  // set local storage network
  let expirationInDays = 90; // all clients require login every 90 days
  if (select.client.slug(state) === 'acme') {
    // on demo client, override so that login is required every 30 minutes
    const halfHourInDays = 1 / 24 / 2; // 1 day, divided by 24 hours, divided by 2 to get a half hour
    expirationInDays = halfHourInDays;
  }
  setLocalStorageExpire(
    loginNetworkName,
    networkSlug,
    expirationInDays,
    LOGIN_NETWORK_NAME_VERSION
  );

  // get up-to-moment state values to account for mutations within this thunk
  state = getState();
  const defaultLocation = select.networks.defaultLocationBySlug(networkSlug)(state);
  const locationResolved = select.location.locationResolved(state);

  // return values to reducer
  const payload = {
    ...formValues,
    ...(locationResolved ? {} : { defaultLocation }), // don't set location if it's already set
  };
  return payload;
});

export const userLoggedOut = createAsyncThunk('userLoggedOut', async (formValues, thunkApi) => {
  const { getState } = thunkApi;
  const state = getState();

  const loginNetworkName = select.client.loginNetworkName(state);

  localStorage.removeItem(loginNetworkName);
});
