/* eslint-disable no-shadow */
import React, { useMemo, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CARE_CATEGORY_OPTIONS } from 'utils/constants';
import { actions, select } from 'store/toolkit';
import ResponsivePlaceholder from './ResponsivePlaceholder';

const useStyles = makeStyles((theme) => ({
  forceZIndex: {
    zIndex: '1600!important',
  },
  paper: {
    marginTop: '.25rem',
  },
  menuList: {
    padding: 0,
    '& .MuiButtonBase-root:focus:after': {
      display: 'none',
    },
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      '& .MuiButtonBase-root:focus, .MuiListItem-root.Mui-selected:focus': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
      '& .MuiListItem-root.Mui-selected': {
        background: 'rgba(0, 0, 0, 0)',
      },
    },
    '& .MuiListItem-root.Mui-selected': {
      textDecoration: 'underline',
    },
  },
  selectRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    border: 'none',
    padding: '4px 32px 4px 20px',
  },
  placeholderText: {
    ', &.Mui-disabled': {
      color: theme.palette.grey[600],
      opacity: 1,
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemImage: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
    color: theme.palette.grey[700],
  },
  selectInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0,0,0,0.23)',
      borderLeft: 'none',
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid black',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.secondary.main}`,
      paddingTop: 4,
      marginTop: 0.5,
    },
  },
}));

function CareCategorySelect({ grouped }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const careCategory = useSelector(select.search.type);
  const currentSearchTypeOptions = useSelector(select.search.currentSearchTypeOptions);

  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (evt) => {
      const newSearchType = evt.target.value;
      dispatch(actions.search.changeType(newSearchType));
    },
    [dispatch]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const placeholder = 'What are you searching for?';
  const formLabel = useMemo(
    () => currentSearchTypeOptions?.label || placeholder,
    [currentSearchTypeOptions]
  );

  return (
    <FormControl
      variant="outlined"
      style={{ width: '100%' }}
      className={grouped ? classes.selectInput : ''}
    >
      <Typography variant="srOnly" id="care-category-label">
        Care category select: {formLabel}
      </Typography>
      <ResponsivePlaceholder text={!careCategory ? placeholder : ''} padding="4px 32px 4px 20px" />
      <Select
        labelId="care-category-label"
        id="care-category-select"
        open={open}
        value={careCategory || ''}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        size="small"
        fullWidth
        classes={{ root: classes.selectRoot }}
        SelectDisplayProps={{
          'aria-expanded': open,
        }}
        MenuProps={{
          variant: 'menu',
          className: classes.forceZIndex,
          classes: {
            list: classes.menuList,
            paper: classes.paper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          id: 'care-category-overlay',
        }}
      >
        {careCategory && (
          <MenuItem
            classes={{ root: `${classes.menuItem} ${classes.placeholderText}` }}
            value="placeholder"
            disabled
          >
            {placeholder}
          </MenuItem>
        )}
        {Object.values(CARE_CATEGORY_OPTIONS).map((careCategory) => (
          <MenuItem
            id={`care-category-select-item-${careCategory.value}`}
            value={careCategory.value}
            key={careCategory.value}
            classes={{ root: classes.menuItem }}
          >
            <careCategory.IconComponent className={classes.menuItemImage} /> {careCategory.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CareCategorySelect.defaultProps = {
  grouped: false,
};

CareCategorySelect.propTypes = {
  grouped: PropTypes.bool,
};

export default CareCategorySelect;
