/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Redirect as RouterRedirect, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

/** This is a react-router-dom Redirect component that additionally sends a message to Sentry for us to track when users have attempted to hit a non-existent route */
export default function Redirect(props) {
  const location = useLocation();
  const { to } = props;
  useEffect(() => {
    Sentry.captureMessage(`Redirected from ${location.pathname}`, (scope) => {
      scope.setLevel('warning');
      scope.setExtra('to', to);
    });
  }, [location, to]);

  return <RouterRedirect {...props} />;
}
