import { JPMC } from 'utils/constants';
import JPMCProfileExampleLg from 'assets/jpmc-provider-profile-lg.png';
import JPMCProfileExampleSm from 'assets/jpmc-provider-profile-sm.png';

import { ABOUT_SECTION_OPTIONS } from './constants';

export default {
  [JPMC]: {
    providerProfileImageLg: JPMCProfileExampleLg,
    providerProfileImageSm: JPMCProfileExampleSm,
    providerScoreDescription: `Each provider's quality ranking is displayed next to their name and results are displayed in the following order: "Exceeds Standards," "Meets Standards," "Non-Evaluated," then "Below Standards." If a provider has a"Preferred Group" designation, they will appear above non- preferred providerswithin each quality ranking category. If no ranking is shown, it's due to insufficient data or an unrated specialty. Click the info icon next to the quality ranking to learn more.`,
    sectionList: [
      ABOUT_SECTION_OPTIONS.PREFERRED_GROUP,
      ABOUT_SECTION_OPTIONS.EXCEEDS_STANDARDS,
      ABOUT_SECTION_OPTIONS.MEETS_STANDARDS,
      ABOUT_SECTION_OPTIONS.NON_EVALUATED,
      ABOUT_SECTION_OPTIONS.BELOW_STANDARDS,
      ABOUT_SECTION_OPTIONS.MEASURING_WHAT_MATTERS,
    ],
  },
};
