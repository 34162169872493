import React from 'react';
import PropTypes from 'propTypes';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import NoResults from 'components/ModernExperience/NoResults';
import AlternateSearchSuggester from 'components/SearchSuggestion/AlternateSearchSuggester';
import ServiceInfo from 'components/ModernExperience/ServiceInfo';
import CompareButton from 'components/ModernExperience/CompareButton';

import FeaturedBenefitsList from './Benefit/FeaturedBenefitsList';
import ResultsList from './ResultsList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
  },
  noResults: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  compareButton: {
    position: 'sticky',
    zIndex: theme.zIndex.mobileStepper,
    top: 0,
    width: '100%',
    margin: `0px auto`,
    backgroundColor: theme.palette.common.white,
  },
}));

function ResultsSection({ additionalClass }) {
  const classes = useStyles();

  const isMdDown = useSelector(select.ui.isMdDown);
  const isSmDown = useSelector(select.ui.isSmDown);
  const showResultsMap = useSelector(select.ui.showResultsMap);
  const currentServiceId = useSelector(select.results.serviceId);
  const returnedNoResults = useSelector(select.results.returnedNoResults);
  const featuredBenefits = useSelector(select.promotions.featuredBenefitList);

  if (returnedNoResults) {
    return (
      <section
        className={clsx(classes.root, { [additionalClass]: additionalClass })}
        aria-hidden={isMdDown && showResultsMap}
        data-testid="search-results-list"
      >
        <AlternateSearchSuggester />
        <div className={classes.noResults}>
          <NoResults />
        </div>
      </section>
    );
  }

  return (
    <section
      className={clsx(classes.root, { [additionalClass]: additionalClass })}
      aria-hidden={isMdDown && showResultsMap}
      data-testid="search-results-list"
    >
      <AlternateSearchSuggester />
      <CompareButton className={classes.compareButton} />

      {featuredBenefits.length > 0 && (
        <>
          <Typography variant="srOnly" component="h2">
            Featured Benefits Section
          </Typography>
          <FeaturedBenefitsList />
        </>
      )}

      {Boolean(currentServiceId) && (
        <>
          <Typography variant="srOnly" component="h2">
            Service Information Section
          </Typography>
          <ServiceInfo serviceId={currentServiceId} mobileView={isSmDown} />
        </>
      )}
      <ResultsList />
    </section>
  );
}

ResultsSection.propTypes = {
  additionalClass: PropTypes.string,
};

ResultsSection.defaultProps = {
  additionalClass: '',
};

export default React.memo(ResultsSection);
