import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Promotion from 'icons/Promotion';
import useIconNode from 'hooks/useIconNode';

const useStyles = makeStyles((theme) => ({
  header: {
    justifyContent: 'space-between',
  },
  headerEndItems: {
    // featured benefit and show more
    display: 'flex',
    alignItems: 'center',
    fontSize: 6,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  showMoreButton: {
    marginLeft: 'auto',
    color: theme.palette.grey[700],
    fontSize: 14,
  },
  logo: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
    '& > img, svg': { width: '100%' },
    '& > svg': { height: '100%' },
  },
  inlineTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  stackedTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
  },
}));

export default function FeaturedBenefitTitle({ logo, title, subtitle, headingLevel, isMobile }) {
  const classes = useStyles();

  const iconEl = useIconNode(logo);
  const Logo = <div className={classes.logo}>{iconEl}</div>;

  const Title = (
    <Typography component={headingLevel} variant="h4" className={classes.title}>
      {title}
    </Typography>
  );
  const Subtitle = <Typography>{subtitle}</Typography>;

  if (!isMobile)
    return (
      <Grid item classes={{ root: classes.inlineTitleContainer }}>
        {Logo} {Title} {Subtitle}
      </Grid>
    );

  return (
    <Grid item xs={12} classes={{ root: classes.stackedTitleContainer }}>
      {Logo}
      <div>
        {Title}
        {Subtitle}
      </div>
    </Grid>
  );
}

FeaturedBenefitTitle.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  isMobile: PropTypes.bool,
};

FeaturedBenefitTitle.defaultProps = {
  headingLevel: 'h3',
  isMobile: false,
  logo: <Promotion color="primary" />,
};
