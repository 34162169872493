import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';

import useFocusAnchors, { useFocusTo } from 'utils/FocusRefContext';
import LocationDummy from 'components/LocationSearch/LocationDummy';
import LocationAutocomplete from 'components/LocationSearch/LocationAutocomplete';
import ProviderSearchDummy from 'components/ProviderSearch/ProviderSearchDummy';
import ProviderAutocomplete from 'components/ProviderSearch/ProviderAutocomplete';
import CareCategorySelect from 'components/CareCategory/CareCategorySelect';
import SearchIcon from '@material-ui/icons/Search';
import { actions, select, thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { _analyticsActions } from '../../analytics/index';

const useStyles = makeStyles((theme) => ({
  searchButton: {
    width: '100%',
    minWidth: 40,
    padding: 0,
    height: 40,
    border: 'none',
    borderRadius: '0 50px 50px 0',
  },
  '@media (forced-colors: active) and (prefers-color-scheme: light)': {
    searchButton: {
      background: 'black',
      forcedColorAdjust: 'none',
    },
  },
  searchButtonLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: 32,
      width: 32,
      color: theme.palette.tertiary.contrastText,
    },
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
  },
  submitButtonVertical: {
    display: 'block',
    padding: '9px 28px 11px',
    minWidth: 180,
  },
  locationFlexBox: {
    flex: '1 1 30%',
    minWidth: '150px',
    maxWidth: '300px',
  },
  searchTypeFlexBox: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 30%',
    minWidth: '235px',
    maxWidth: '300px',
  },
  inputFlexBox: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 40%',
    minWidth: '150px',
    maxWidth: '350px',
  },
  submitFlexBox: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 65px',
    minWidth: 35,
  },
}));

function SearchForm({ mobileView, showClearAll, autoSearch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();

  const isReadyForSearch = useSelector(select.search.isReadyForSearch);

  const focusAnchors = useFocusAnchors();
  const focusTo = useFocusTo();

  const handleClearAll = () => {
    dispatch(actions.app.clearSearchForm());
  };

  const handleSubmit = useCallback(
    (e) => {
      e?.preventDefault();
      searchDispatch(thunks.results.executeSearch({ clearArrayFilters: true }));
      dispatch(_analyticsActions.searchFormSubmitted());
      focusTo.searchButton();
    },
    [searchDispatch, focusTo, dispatch]
  );

  if (mobileView) {
    return (
      <form id="search-form" onSubmit={handleSubmit} autoComplete="off">
        <LocationDummy autoSearch={autoSearch} submitSearch={handleSubmit} />
        <CareCategorySelect />
        <ProviderSearchDummy />
        <div className={classes.submitButtonContainer}>
          {showClearAll && (
            <Button
              aria-label="Submit Search"
              form="search-form"
              color="primary"
              variant="text"
              onClick={handleClearAll}
              style={{ textDecoration: 'underline' }}
            >
              Clear All
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isReadyForSearch}
            style={{
              margin: showClearAll ? '0px' : '1.5rem auto 0',
            }}
            className={classes.submitButtonVertical}
            type="submit"
          >
            Search
          </Button>
        </div>
      </form>
    );
  }

  return (
    <Grid
      role="search"
      container
      component="form"
      id="search-form"
      onSubmit={handleSubmit}
      wrap="nowrap"
    >
      <Grid item classes={{ root: classes.locationFlexBox }}>
        <LocationAutocomplete
          autoSearch={autoSearch}
          submitSearch={handleSubmit}
          mobileView={mobileView}
        />
      </Grid>

      <Grid item classes={{ root: classes.searchTypeFlexBox }}>
        <CareCategorySelect grouped />
      </Grid>

      <Grid item classes={{ root: classes.inputFlexBox }}>
        <ProviderAutocomplete grouped />
      </Grid>

      <Grid item classes={{ root: classes.submitFlexBox }}>
        <Button
          aria-label="Submit Search"
          form="search-form"
          color="primary"
          variant="contained"
          disabled={!isReadyForSearch}
          type="submit"
          classes={{
            root: classes.searchButton,
            contained: 'tertiary',
            label: classes.searchButtonLabel,
          }}
          className="search-submit-button"
          ref={focusAnchors.searchButton}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
}

SearchForm.defaultProps = {
  showClearAll: false,
  autoSearch: false,
};

/**
 * mobileView (boolean) - if true, use stacked search "dummy" buttons that open search field in a full screen modal. else, render horizontal search bar
 */
SearchForm.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  showClearAll: PropTypes.bool,
  autoSearch: PropTypes.bool,
};

export default withRouter(SearchForm);
