import { DEFAULT_LOCATIONS, EMSANA_CARE, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import emsanaHeaderLogo from 'assets/emsanacare-header-logo.png';
import emsanaLoginLogo from 'assets/emsanacare-login-logo.png';
import { selectToken } from 'utils/selectToken';

export const apiTokens = {
  dev: 'Token a65285f779964781e60c2330528b574379bbb984',
  staging: 'Token cbb1cd9a1fad8d58763cfb8b80aeb6717ebe07bd',
  prod: 'Token 6cf906054f58ebca035f860d68dc1d9a484e6c9a',
};

const token = selectToken(apiTokens);

const emsanaCareConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'emsanacare',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'emsanacare',
  EMBOLD_CLIENT: EMSANA_CARE,
  EMBOLD_CLIENT_SLUG: 'emsanacare',
  SHOW_SCHEDULE_BUTTON: false,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_REGION_SELECTOR: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Mesa },
  CLOSED_ACCESS: true,
  LOGIN_NETWORK_NAME: 'emsanaCareUserId',
  LOGIN_COOKIE_AUTH_REGEX: [
    'boeingbcbsarizona|boeingbcbsstlouis|boeingaetnaarizona|boeingcignaarizona|crossoveraetnahdhpwashington|crossoverxofirstwashington|expediaaetnaepowashington|expediaaetnahdhpwashington|expediapremerappowashington|linkedinanthemepowashington|linkedinanthemppowashington|boeingbcbsgreaterpugetsound',
    'i',
  ],

  // content
  DISPLAY_NAME: 'EmsanaCare',
  SCHEDULE_PHONE_NUMBER: '',
  SCHEDULE_COPY: '',
  HEADER_LOGO: emsanaHeaderLogo,
  LOCAL_LOGO_ASSET: emsanaLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
  SUPPORT_LINK: 'https://emsanacare.emboldhealth.com/portal/',
  WELCOME_MESSAGE: `EmsanaCare and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default emsanaCareConfig;
