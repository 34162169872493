import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  copyright: {
    fontSize: 9,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
    },
  },
}));

export default function DisclaimerText() {
  const classes = useStyles();
  const disclaimerText = useSelector(select.content.disclaimerText);

  return (
    <Typography component="small" classes={{ root: classes.copyright }} variant="caption">
      {disclaimerText && (
        <>
          {disclaimerText}
          <br />
        </>
      )}
      &copy;{new Date().getFullYear()} Embold Health. All rights reserved.
    </Typography>
  );
}
