import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { actions, select } from 'store/toolkit';
import { Button, Grid, Typography } from '@material-ui/core';
import Modal from 'components/Modals/Modal';
import { setLocalStorageExpire } from 'utils/utils';
import {
  benefitDiffPill as createBenefitDiffPillSelector,
  benefitDiffBadge as createBenefitDiffBadgeSelector,
} from 'store/slices/results/selectProvider';
import useCheckStorageAndShowModal from 'hooks/useCheckStorageAndShowModal';
import HighlyRatedIcons from './HighlyRatedIcons';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  headerText: {
    textAlign: 'center',
    margin: '20px 0',
  },
}));

const ICUBA_COPAY_MODAL_ARIA_ID = 'icuba-zero-copay-modal';

export const ICUBA_COPAY_MODAL_STORAGE_KEY = 'zeroCopayNotifier';
// Increment ICUBA_COPAY_MODAL_VERSION to invalidate storage item
export const ICUBA_COPAY_MODAL_VERSION = 2;

const setStorageValue = () => {
  setLocalStorageExpire(
    ICUBA_COPAY_MODAL_STORAGE_KEY,
    true,
    1 / 24, // 1 hour expiry
    ICUBA_COPAY_MODAL_VERSION
  );
};

export default function IcubaZeroCopayModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(select.ui.highQualityModalOpen);
  const benefitDiffCopy = useSelector(select.content.benefitDiffCopy);
  const benefitDiffTooltipTitle = useSelector(select.content.benefitDiffTooltipTitle);
  const benefitDiffTooltipDescription = useSelector(select.content.benefitDiffTooltipDescription);

  const checkStorageAndShowModal = useCheckStorageAndShowModal(
    ICUBA_COPAY_MODAL_STORAGE_KEY,
    ICUBA_COPAY_MODAL_VERSION,
    'highQuality',
    1
  );

  const handleClose = useCallback(() => {
    dispatch(actions.ui.closeModal('highQuality'));
    setStorageValue();
  }, [dispatch]);

  useEffect(() => {
    /**
     * Conditions to show this modal:
     *
     * 1. The 'zeroCopayNotifier' localStorage is not "true".
     * This value is set to true when the modal is dismissed
     * and expires every two weeks.
     *
     * */
    checkStorageAndShowModal();
  }, [dispatch, checkStorageAndShowModal]);

  // Modal content
  const exampleProvider = { hasBenefitDiff: true }; // mock provider object of a high performing provider
  const exampleBenefitDiffPillProps = createBenefitDiffPillSelector().resultFunc(
    exampleProvider,
    benefitDiffCopy,
    benefitDiffTooltipTitle,
    benefitDiffTooltipDescription
  );
  const exampleBenefitDiffBadgeProps = createBenefitDiffBadgeSelector().resultFunc(
    exampleBenefitDiffPillProps
  );

  return (
    <Modal
      open={open}
      ariaId={ICUBA_COPAY_MODAL_ARIA_ID}
      allowBackdropClick={false}
      showX
      handleClose={handleClose}
    >
      <Grid classes={{ root: classes.gridRoot }}>
        <Typography
          variant="h2"
          id={`${ICUBA_COPAY_MODAL_ARIA_ID}-title`}
          className={classes.headerText}
        >
          Save with $0 Copay!
        </Typography>

        <div id={`${ICUBA_COPAY_MODAL_ARIA_ID}-description`}>
          <Typography paragraph>
            This provider is in your <strong>existing network</strong> of providers and consistently
            delivers high quality care in this specialty. Embold Health is{' '}
            <strong>not a provider network</strong>, we review your{' '}
            <strong>existing network</strong> providers using peer-reviewed, rigorous analytics to
            rank physicians across the United States to make sure you have access to the best
            possible care! Your <strong>copay is waived</strong> if you visit an Embold Health
            reviewed provider.
          </Typography>
        </div>

        <HighlyRatedIcons
          pillProps={exampleBenefitDiffPillProps}
          badgeProps={exampleBenefitDiffBadgeProps}
        />

        <Grid container direction="row" justifyContent="center">
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.acceptButton}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}
