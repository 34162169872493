import React from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    opacity: 1,
    '&.Mui-focused': {
      color: 'inherit',
    },
  },
}));

export default function ResponsivePlaceholder({ text, padding, additionalClasses }) {
  const classes = useStyles();
  return (
    <InputLabel
      shrink={false}
      classes={{
        root: `${classes.root} ${additionalClasses}`,
      }}
      style={{ padding: padding }}
    >
      {text}
    </InputLabel>
  );
}
ResponsivePlaceholder.propTypes = {
  padding: PropTypes.string.isRequired,
  text: PropTypes.string,
  additionalClasses: PropTypes.string,
};
ResponsivePlaceholder.defaultProps = {
  text: '',
  additionalClasses: '',
};
