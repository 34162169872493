/* eslint-disable import/prefer-default-export */

/**
 * When toggled on, boolean filters will append a query param of `${filterKey}=true` by default.
 * Some boolean filters require a value other than "true" in order to perform the correct filter in ES.
 *
 * For instance, when the `inNetworkPreferred` filter is toggled on, the proper
 * parameter to add to the ES query is `curated_out=false`, not `curated_out=true`.
 * The filter "key" value of `curated_out` is from `filterKeyMap.js`
 */
export const booleanFilterCustomMappings = {
  inNetworkPreferred: false,
  healthConnectPlan: 'health_connect',
  preferredGroup: 1,
};
