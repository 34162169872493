/* eslint-disable import/prefer-default-export */
import { logDevMessage } from 'utils/utils';

/**
 * @param state location state as defined in locationSlice
 * @param action.payload.defaultLocation location from client or network config. constructed in 'configUtils > getNetworkDefaultLocation'
 * @param {string} action.payload.defaultLocation.city
 * @param {string} action.payload.defaultLocation.state
 * @param {string} action.payload.defaultLocation.lat
 * @param {string} action.payload.defaultLocation.long
 */
export function handleSetDefaultLocation(state, action) {
  const { defaultLocation } = action?.payload || {};
  if (!defaultLocation) return;

  const { city, state: usState, lat, long } = defaultLocation;
  if (!city || !usState || !lat || !long) {
    logDevMessage("Malformed location passed to 'handleSetDefaultLocation'");
    return;
  }

  state.locationInput = `${city}, ${usState}`;
  state.latLong = { latitude: lat, longitude: long };
  state.locationComponents = { city, state: usState };
  state.locationResolved = true;
}
