import React from 'react';
import PropTypes from 'propTypes';

import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import select from 'store/select';

const useStyles = makeStyles((theme) => ({
  linkRoot: {
    display: 'inline-block',
    '&:visited': { color: theme.palette.primary.main },
    '& h3': {
      fontSize: '1.125rem', // 18px,
      margin: 0,
    },
  },
}));

function ResultTitle({ resultId, children }) {
  const classes = useStyles();
  const profileLink = useSelector(select.results.profileDetailsLink(resultId));

  return (
    <Box>
      <Link
        classes={{ root: classes.linkRoot }}
        underline="hover"
        href={profileLink}
        target="_blank"
        rel="noreferrer"
      >
        <Typography variant="h3" id={`result-title-${resultId}`}>
          {children}
        </Typography>
      </Link>
    </Box>
  );
}

export default ResultTitle;

ResultTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  resultId: PropTypes.string.isRequired,
};
