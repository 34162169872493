import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { actions, select } from 'store/toolkit';
import { _analyticsActions } from 'analytics';
import Modal from './Modal';

const useStyles = makeStyles(() => ({
  headerText: {
    margin: '20px 0',
  },
  acceptButton: {
    margin: '20px 0',
  },
}));

export default function MercyHealthRedirectModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(select.ui.mercyHealthRedirectOpen);

  const handleClose = useCallback(() => {
    dispatch(actions.ui.closeModal('mercyHealthRedirect'));
  }, [dispatch]);

  const handleContinue = useCallback(() => {
    dispatch(_analyticsActions.alternateProviderDirectoryRedirected());
  }, [dispatch]);

  return (
    <Modal open={open} ariaId="soct-benefits-modal" allowBackdropClick={false} showX={false}>
      <Container component="section">
        <Typography variant="h2" id="soct-benefits-modal-title" className={classes.headerText}>
          You are about to be redirected to Mercy Health’s Provider Directory
        </Typography>

        <div>
          <Typography paragraph>
            To continue with this request, please select ‘Continue’ below. If you wish to stay on
            this page, click on the cancel button below. Please select a new network/state to search
            Walmart + Embold’s Provider Guide
          </Typography>
        </div>

        <Grid container direction="row" justifyContent="center" style={{ columnGap: 8 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              role="link"
              className={classes.acceptButton}
              onClick={handleContinue}
              href="https://healthplan.mercy.net/directory/search?employer="
            >
              Continue
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              className={classes.acceptButton}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}
