import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { SUR } from 'utils/constants';
import { select } from 'store/toolkit';
import useFocusAnchors from 'utils/FocusRefContext';
import Tooltip from 'components/Tooltip';
import msLogo from 'assets/Microsoft_logo.svg';
import HipaaModal from './HipaaModal';
import SubmitButton from '../SubmitButton';
import CheckboxInput from '../CheckboxInput';
import AgreeToTermsLabel from './AgreeToTermsLabel';
import { CHECKBOX_ERROR_TEXT, MEMBER_CREDENTIALS_ERROR_TEXT } from './errorText';
import LoginFormErrors from './LoginFormErrors';
import TextInput from '../TextInput';
import FieldErrorText from '../FieldErrorText';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 12,
    },
  },
  headerSection: {
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  formRow: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      '& .MuiGrid-item': {
        marginBottom: theme.spacing(1.5),
      },
    },
  },
}));

const initialValues = {
  networkSlug: '',
  id: '',
  suffix: '',
  termsChecked: false,
  hipaaAgreed: false,
};

export default function MemberCredentialsLogin({ classes, onSubmit }) {
  const focusAnchors = useFocusAnchors();

  const localClasses = useStyles();
  const availableSlugs = useSelector(select.networks.availableSlugs);

  const formGroupLabelId = 'member-id-form-label'; // this is an a11y requirement for labelling the form group controls
  const [suffixDisabled, setsuffixDisabled] = useState(false);

  /* disable if SUR is entered */
  const handleChange = useCallback((e) => {
    setsuffixDisabled(e.target.value.toLowerCase() === SUR);
  }, []);

  const validationSchema = Yup.object()
    .shape({
      // the individual validation for each field
      networkSlug: Yup.string('').required('Required'),
      id: Yup.string('').required('Required'),
      suffix: suffixDisabled ? Yup.string('') : Yup.string('').required('Required'),
      termsChecked: Yup.bool().oneOf([true], CHECKBOX_ERROR_TEXT),
      hipaaAgreed: Yup.bool().oneOf([true], 'You must agree to the HIPAA terms'), // This text is not shown in the UI anywhere
    })
    .test('memberError', 'Prefix, Id and Suffix match our records', (formValues) => {
      /* the reason we are using this custom validation test, rather than the simple
       * Yup methods like normal, is because we want only one error message to show for the
       * entire group of prefix, id, and suffix. We don't want to indicate to the user which of
       * these three fields is incorrect, only that the combination is incorrect.
       */
      const errors = [];
      if (formValues.networkSlug && formValues.id && formValues.suffix) {
        // only run group validation if all required fields have been entered
        if (!availableSlugs.includes(formValues.networkSlug.toLowerCase())) errors.push('Prefix'); // validate prefix
        const idRegex = /([0-9]){9,}$/;
        if (!idRegex.test(formValues.id)) errors.push('ID');
        const suffixRegex = /([0-9]){2,}$/;
        if (!suffixRegex.test(formValues.suffix)) errors.push('Suffix');
      }

      if (errors.length) {
        return new Yup.ValidationError(MEMBER_CREDENTIALS_ERROR_TEXT, null, 'memberError');
      }
      return true;
    });

  const handleSubmit = useCallback(
    (formValues, formikTools) => {
      // in this form, we actually need to transform the value of network slug to lowercase before passing it to the onSubmit function from the parent
      formValues.networkSlug = formValues.networkSlug.toLowerCase();

      /* disable if SUR is entered and suffix was previously entered */
      if (formValues.networkSlug === SUR) {
        formValues.suffix = '';
      }
      // now call the parent onSubmit function with the transformed values
      onSubmit(formValues, formikTools);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form className={`${classes.root} ${localClasses.root}`} noValidate>
        <HipaaModal fieldName="hipaaAgreed" />
        <Grid container className={localClasses.headerSection}>
          <Grid item xs={8}>
            <Tooltip
              title="Member ID"
              message="Please enter the prefix, identification number, and suffix found on your insurance card. We need this information to find providers in your health plan. If you are a Surest member, enter the prefix SUR."
            >
              <Typography id={formGroupLabelId}>Member ID</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4} component="img" src={msLogo} alt="Microsoft logo" />
        </Grid>

        <Grid
          container
          className={localClasses.formRow}
          role="group"
          aria-labelledby={formGroupLabelId}
        >
          <Grid
            item
            component={TextInput}
            name="networkSlug"
            label="Prefix"
            required
            inputProps={{
              maxLength: 3,
              autoComplete: 'off',
              ref: focusAnchors.prefixField,
              onChange: handleChange,
            }}
            xs={12}
            sm={3}
          />
          <Grid
            item
            component={TextInput}
            name="id"
            label="Identification #"
            required
            type="tel"
            inputProps={{
              maxLength: 9,
              autoComplete: 'off',
              ref: focusAnchors.idField,
            }}
            xs={12}
            sm={5}
          />
          <Grid
            item
            component={TextInput}
            name="suffix"
            label={suffixDisabled ? 'N/A' : 'Suffix'}
            required
            type="tel"
            disabled={suffixDisabled}
            inputProps={{
              maxLength: 2,
              autoComplete: 'off',
              ref: focusAnchors.suffixField,
            }}
            xs={12}
            sm={3}
          />
          <Grid
            item
            xs={12}
            component={FieldErrorText}
            name="memberError"
            className={classes.groupErrorText}
          />
        </Grid>
        <CheckboxInput
          ref={focusAnchors.termsChecked}
          required
          name="termsChecked"
          label={<AgreeToTermsLabel />}
        />
        <SubmitButton className={classes.submitButton}>
          Continue <LoginFormErrors />
        </SubmitButton>
      </Form>
    </Formik>
  );
}

MemberCredentialsLogin.propTypes = {
  classes: PropTypes.shape({
    submitButton: PropTypes.string,
    root: PropTypes.string,
    groupErrorText: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
