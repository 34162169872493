import React, { useRef, useCallback } from 'react';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { ChildrenType } from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { actions, select } from 'store/toolkit';
import useFocusAnchors from 'utils/FocusRefContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
  },
  icon: {
    position: 'relative',
    padding: 0,
    marginLeft: '0.25rem',
  },
  clickTarget: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '&:focus': {
      outlineOffset: 2,
    },
  },
}));

function Tooltip({
  children,
  title,
  ariaLabel,
  message,
  link,
  href,
  additionalClasses,
  deactivated,
  IconProps,
  ...props
}) {
  const classes = useStyles();
  const {
    root: additionalRootClass,
    icon: additionalIconClass,
    clickTarget: additionalClickTargetClass,
  } = additionalClasses;

  const dispatch = useDispatch();
  const tooltipOpen = useSelector(select.tooltip.isOpen);

  const iconRef = useRef(null);
  const tooltipRef = useRef(null);
  const focusAnchors = useFocusAnchors();

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (tooltipOpen) {
        // close an existing tooltip if open
        dispatch(actions.tooltip.close());
      }

      focusAnchors.tooltip = tooltipRef;
      focusAnchors.tooltipAnchor = iconRef;

      const payload = { title, message };
      if (link) payload.link = link;
      if (href) payload.href = href;

      dispatch(actions.tooltip.open(payload));
    },
    [dispatch, focusAnchors, link, href, title, message, tooltipOpen]
  );

  return (
    <div
      className={clsx(classes.root, {
        [additionalRootClass]: Boolean(additionalRootClass),
      })}
    >
      {children}
      {!deactivated && (
        <>
          <InfoOutlinedIcon
            className={clsx(classes.icon, {
              [additionalIconClass]: Boolean(additionalIconClass),
            })}
            ref={iconRef}
            color="action"
            fontSize="small"
            {...IconProps}
          />
          <ButtonBase
            className={clsx(classes.clickTarget, {
              [additionalClickTargetClass]: Boolean(additionalClickTargetClass),
            })}
            onClick={handleClick}
            aria-describedby="tooltip-popper"
            aria-label={`More information about ${ariaLabel || title}`}
            ref={tooltipRef}
            {...props}
          />
        </>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  children: ChildrenType,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
  href: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
  additionalClasses: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    clickTarget: PropTypes.string,
  }),
  deactivated: PropTypes.bool,
  IconProps: PropTypes.shape({
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'error', 'disabled', 'action']),
    fontSize: PropTypes.oneOf(['default', 'inherit', 'large', 'medium', 'small']),
    htmlColor: PropTypes.string,
  }),
};

/**
 * As far as I can tell, we cannot use this shape for Tooltip.propTypes,
 * so we have to define these twice if we want to export the TooltipPropsShape
 * for use in other components
 * */
export const TooltipPropsShape = PropTypes.shape({
  children: ChildrenType,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
  href: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
  additionalClasses: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    clickTarget: PropTypes.string,
  }),
  deactivated: PropTypes.bool,
  IconProps: PropTypes.shape({
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'error', 'disabled', 'action']),
    fontSize: PropTypes.oneOf(['default', 'inherit', 'large', 'medium', 'small']),
    htmlColor: PropTypes.string,
  }),
});

Tooltip.defaultProps = {
  children: null,
  title: '',
  ariaLabel: null,
  message: '',
  link: undefined,
  href: undefined,
  additionalClasses: {},
  deactivated: false,
  IconProps: {},
};

export default Tooltip;
