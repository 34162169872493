import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    width: '100%',
    '&.Mui-disabled': {
      color: theme.palette.grey[600],
    },
  },
  filterLabel: {
    '&.Mui-focused': {
      color: theme.palette.grey[900],
    },
  },
}));

export default function CheckFilterOption({ label, value, checked, onChange, autoFocus }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={onChange}
          checked={checked}
          className={classes.filterLabel}
          autoFocus={autoFocus}
        />
      }
      value={value}
      classes={{ label: classes.filterContainer }}
      label={label || value}
    />
  );
}

CheckFilterOption.propTypes = {
  label: PropTypes.string,
  // value needs to be a string because the DOM input will convert to a string anyways. See https://github.com/mui/material-ui/issues/9592
  // if you pass a number, DOM will convert to string, then onChange a string would be added to the filter value array, and would not match the value passed to this component.
  // Therefore it would never appear as checked.
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  // this should only ever be true for the first hidden chicken, so that invoking the "show more" button will automatically focus on the first vewly visible checkbox
  autoFocus: PropTypes.bool,
};

CheckFilterOption.defaultProps = {
  label: undefined,
  onChange: () => {}, // this will be added as a prop by the parent <CheckFilterGroup />
  autoFocus: false,
};
