import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/* Sort and anchor utils */
export const sortBySpecialtyName = (list) =>
  list.sort((a, b) => a.specialtyName.localeCompare(b.specialtyName));

export const addLetterAnchors = (alphabeticList) => {
  let currentAnchor = '';

  return alphabeticList.map((item) => {
    const firstLetter = item.specialtyName.charAt(0).toUpperCase();
    if (firstLetter !== currentAnchor) {
      // Add anchor property
      currentAnchor = firstLetter;
      return { ...item, anchor: firstLetter };
    }
    return item;
  });
};

export const sortAndAddLetterAnchors = (list) => {
  const sorted = sortBySpecialtyName(list);
  return addLetterAnchors(sorted);
};

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const getLettersWithSpecialty = (specialties) => {
  if (!specialties?.length) return alphabet.map((letter) => ({ letter, disabled: true }));
  return alphabet.map((letter) => {
    // Check if any specialtyName starts with the current letter
    const disabled = !specialties.some((item) => item.specialtyName.startsWith(letter));
    return { letter, disabled };
  });
};

/* Filter Utils */
export const specialtyMatch = (spec, lowerCaseSearchString) =>
  spec.specialtyName.toLowerCase().includes(lowerCaseSearchString) ||
  spec.description?.toLowerCase().includes(lowerCaseSearchString);

export const subspecialtyMatch = (subspec, lowerCaseSearchString) =>
  subspec.subspecialtyName.toLowerCase().includes(lowerCaseSearchString) ||
  subspec.description?.toLowerCase().includes(lowerCaseSearchString);

export const hasSubspecialtyMatch = (subspecialties, lowerCaseSearchString) =>
  subspecialties.some((subspec) => subspecialtyMatch(subspec, lowerCaseSearchString));

export const getMatchingSpecialties = (specialties, searchString) => {
  if (!specialties?.length) return [];
  if (!searchString?.length) return specialties;

  // Convert search string to lowercase for case-insensitive search
  const lowerCaseSearchString = searchString?.toLowerCase();

  return specialties.filter((spec) => {
    if (specialtyMatch(spec, lowerCaseSearchString)) return true; // specialty match
    if (!spec.subspecialties?.length) return false; // no subspecialties
    return hasSubspecialtyMatch(spec.subspecialties, lowerCaseSearchString); // subspecialty match
  });
};

export function getHighlightedSegments(searchString, originalString) {
  if (!originalString?.length) return [];

  if (!searchString?.length) {
    return [{ text: originalString, isMatch: false }];
  }

  const regex = new RegExp(`(${searchString})`, 'gi'); // Case-insensitive regex to find all matches
  const parts = originalString.split(regex); // Split the string into matching/non-matching parts

  return parts.filter(Boolean).map((part) => ({
    text: part,
    isMatch: part.toLowerCase() === searchString.toLowerCase(),
  }));
}

export function highlightAllMatches(searchString, originalString) {
  if (!originalString?.length) return '';
  if (!searchString?.length) return <span>{originalString}</span>;

  const segments = getHighlightedSegments(searchString, originalString);
  return (
    <span>
      {segments.map(({ text, isMatch }) => (isMatch ? <b key={uuidv4()}>{text}</b> : text))}
    </span>
  );
}
