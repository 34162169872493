import { createSlice } from '@reduxjs/toolkit';
import { PROVIDER_RESULT_TYPE, PLACE_RESULT_TYPE } from 'utils/constants';
import { fetchSpecialties } from './specialtiesThunks';
import { SPECIALTIES_SLICE_NAME } from '../slicesNames';
import { processSpecialties } from './specialtiesUtils';

const specialtiesSlice = createSlice({
  name: SPECIALTIES_SLICE_NAME,
  initialState: {
    specialties: {
      [PROVIDER_RESULT_TYPE]: {},
      [PLACE_RESULT_TYPE]: {},
    },
    subspecialties: {
      [PROVIDER_RESULT_TYPE]: {},
      [PLACE_RESULT_TYPE]: {},
    },
    loadingSpecialties: false,
    failedToLoadSpecialties: false,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSpecialties.pending, (state) => {
        state.loadingSpecialties = true;
        state.failedToLoadSpecialties = false;
      })
      .addCase(fetchSpecialties.rejected, (state) => {
        state.loadingSpecialties = false;
        state.failedToLoadSpecialties = true;
      })
      .addCase(fetchSpecialties.fulfilled, (state, action) => {
        state.loadingSpecialties = false;
        state.failedToLoadSpecialties = false;

        const data = action.payload;
        if (data?.length > 0) {
          const { specialtiesMap, subspecialtiesMap } = processSpecialties(data);
          state.specialties = specialtiesMap;
          state.subspecialties = subspecialtiesMap;
        }
      });
  },
});

export default specialtiesSlice;
export const specialtiesActions = specialtiesSlice.actions;
