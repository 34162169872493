import { createAsyncThunk } from '@reduxjs/toolkit';

import { ENDPOINTS } from 'store/fusionServices/fusionConstants';
import {
  searchUrl as selectSearchUrl,
  endpoint as selectEndpoint,
} from 'store/fusionServices/selectFusion';
import { distance } from 'utils/utils';
import { actions, thunks } from 'store/toolkit';
import { getExpandSearchUrl, parseSearchResponseData } from './resultUtils';
import * as selectFilters from '../filters/selectFilters';
import * as selectSearch from '../search/selectSearch';
import * as selectMap from '../map/selectMap';
import * as selectLocation from '../location/selectLocation';
import * as selectResults from './selectResults';
import * as selectServices from '../services/selectServices';
import * as selectAxios from '../config/selectAxios';
import * as selectNetworks from '../config/selectNetworks';
import { fetchService } from '../services/servicesThunks';
import { updateSearchFromResults } from '../search/searchThunks';

export const fetchExpandSearchSuggestion = createAsyncThunk(
  'fetchExpandSearchSuggestion',
  async (args, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();
    const { originalUrl } = args;

    if (!originalUrl) throw new Error('No Search URL passed');

    const axios = selectAxios.axiosInstance(state);
    const expandSearchUrl = getExpandSearchUrl(originalUrl);

    const response = await axios(expandSearchUrl);

    const locationSearched = selectLocation.latLong(state); // the location used in the search
    const { location: closestLocation } = response.data; // the nearest location to the input location

    if (!closestLocation) return { distance: null };

    // get the distance between the two locations
    const distanceToNearest = +distance(
      locationSearched.latitude,
      locationSearched.longitude,
      closestLocation.lat,
      closestLocation.lon
    );

    // return that distance to the reducer
    return { distance: distanceToNearest };
  }
);

/** This is the most generic form of search.
 * Using state from all other slices it performs a search for providers or places.
 * Upon completion of the search it returns the response from the API, as well as data that made up the query sent to the API.
 *
 * Other more specific search thunks should rely on this thunk */

export const executeSearch = createAsyncThunk('executeSearch', async (args, thunkApi) => {
  const { getState, dispatch, requestId } = thunkApi;
  const state = getState();

  // create an axios instance for making a fetch call
  const axios = selectAxios.axiosInstance(state);

  // select the needed data from redux slices
  const searchSlice = selectSearch.slice(state);

  // if this is a service search, we also want the service data to be loaded into the servicesSlice
  if (selectSearch.isServiceSearch(state)) {
    // check to see if we have already fetched and cached this service data
    const serviceIsAlreadyCached = Boolean(selectServices.byId(searchSlice.serviceId)(state));
    // if we haven't already cached the given service, fetch it using the fetchService thunk
    if (!serviceIsAlreadyCached) dispatch(fetchService());
  }

  // get the url for fetching the results from fusion
  const url = selectSearchUrl(state);
  const { data: responseData } = await axios.get(url);

  // after the the fetch is complete, check if this is a subspecialty search with no results
  if (responseData.count === 0) {
    // when no results were returned by a subspecialty search, we want to find the closest provider with the parent specialty
    // passing in the originally searched URL and request ID so that the reducer can relate this expansion to it's original search
    await dispatch(fetchExpandSearchSuggestion({ originalUrl: url, originalRequestId: requestId }));
  }

  // build our payload to send to resultsSlice
  const payload = {
    // the parsed response should have replaced the results array, with an indexed results object, along with a resultIdList array
    response: parseSearchResponseData(responseData),

    // request should represent all of the search and filter params that led to these results
    request: {
      url,
      endpoint: selectEndpoint(state),
      filters:
        selectEndpoint(state) === ENDPOINTS.PROVIDERS
          ? selectFilters.providerFilterValues(state)
          : selectFilters.placeFilterValues(state),
      ordering:
        selectEndpoint(state) === ENDPOINTS.PROVIDERS
          ? selectFilters.providerSort(state)
          : selectFilters.placeSort(state),
      location: {
        input: selectLocation.locationInput(state),
        city: selectLocation.city(state),
        state: selectLocation.state(state),
        // other fields for location get added conditionally below
      },
      search: searchSlice,
    },
  };

  if (selectSearch.isBoundingBoxSearch(state)) {
    payload.request.location.radius = null;
    payload.request.location.boundingBox = selectMap.bounds(state);
  } else {
    // radius search
    payload.request.location.radius = selectFilters.radius(state);
    payload.request.location.boundingBox = null;
  }
  payload.request.location.coordinates = selectLocation.latLong(state);

  // return the payload to the reducer
  return payload;
});

/** This thunk is used for loading in more results from the previous search by using the "next" link from the last response */
export const loadMoreResults = createAsyncThunk('loadMoreResults', async (args, thunkApi) => {
  const { getState } = thunkApi;
  const state = getState();

  const searchRequestId = selectResults.lastRequestId(state);

  // get the pagination link from results slice
  const url = selectResults.paginationPathname(state);
  if (!url) throw new Error('No pagination link available');

  // fetch the next results
  const axios = selectAxios.axiosInstance(state);
  const response = await axios.get(url);

  return { newResults: parseSearchResponseData(response.data), searchRequestId };
});

/**
 * @type {Function}
 * @param {string} shareId The share id code to fetch
 * */
export const fetchShare = createAsyncThunk('fetchShare', async (shareId, thunkApi) => {
  const { getState, rejectWithValue } = thunkApi;

  if (!shareId) throw new Error('No share id passed');

  const axios = selectAxios.axiosInstance(getState());

  let response;
  try {
    response = await axios.get(`${ENDPOINTS.SHARE}/${shareId}`);
  } catch (e) {
    return rejectWithValue({ status: e.response?.status });
  }
  return response.data;
});

/* ********************************************* */
/* *********** Higher Level Searches *********** */
/* ********************************************* */

// These searches perform some additional dispatch to update state, followed by a more standard search call

/** This thunk first updates our state to be a bounding box search, and then performs a new search with executeSearch */
export const searchThisArea = createAsyncThunk('searchThisArea', async (args, thunkApi) => {
  const { dispatch } = thunkApi;
  const { mapCenter } = args;

  await dispatch(thunks.location.geolocateMapCenter({ mapCenter }));

  // now that the searchSlice is set to boundingBox search, we perform our regular executeSearch thunk
  await dispatch(executeSearch());
});

/** This thunk first updates our state to be a single provider search, and then performs a new search with executeSearch */
export const searchProviderById = createAsyncThunk(
  'searchProviderById',
  async ({ id }, thunkApi) => {
    const { dispatch } = thunkApi;

    // update search state to reflect single provider search
    dispatch(actions.search.setSingleProviderSearch(id));

    // perform our regular executeSearch thunk
    await dispatch(executeSearch());
  }
);

/** This thunk first updates our state to be a multi provider search, and then performs a new search with executeSearch */
export const searchProvidersByIds = createAsyncThunk(
  'searchProvidersByIds',
  async ({ npis }, thunkApi) => {
    const { dispatch } = thunkApi;

    // update search state to reflect multi provider search
    dispatch(actions.search.setMultiProviderSearch(npis));

    // perform our regular executeSearch thunk
    await dispatch(executeSearch());
  }
);

/** This thunk first updates our state to be a multi place search, and then performs a new search with executeSearch */
export const searchPlacesByIds = createAsyncThunk(
  'searchPlacesByIds',
  async ({ ids }, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(actions.search.setPlacesByIdsSearch(ids));

    await dispatch(executeSearch());
  }
);

export const fetchProfileFromUrl = createAsyncThunk(
  'fetchProfileFromUrl',
  async (urlParams, thunkApi) => {
    const { dispatch } = thunkApi;
    const requiredParams = [
      'care_category',
      'entity_id',
      'location',
      'location_input',
      'network_slug',
    ];
    if (requiredParams.some((param) => !urlParams[param])) {
      throw new Error('Missing url parameter');
    }

    // update search state to reflect single provider search
    dispatch(actions.app.updateStoreFromUrl(urlParams));

    // perform our regular executeSearch thunk
    await dispatch(executeSearch());
  }
);

/**
 * @param {Object} topSearch The quickSearchSelected payload
 * This thunk first dispatches the top object to the searchSlice to update state, then performs a new search with executeSearch */
export const executeTopSearch = createAsyncThunk(
  'executeTopSearch',
  async (topSearch, thunkApi) => {
    const { dispatch } = thunkApi;

    // dispatch the selected top search to the search slice by passing our args to the action creator
    dispatch(actions.search.quickSearchSelected(topSearch));

    // now that the searchSlice is updated with the top search, we perform our regular executeSearch thunk
    await dispatch(executeSearch());
  }
);

/** This thunk is intended to be used when a user has searched for a single provider. Executing this thunk will search for more providers with the same specialty as the single provider.
 * The reducer will populate the results list with the new providers but keep the single provider at the top of the list.
 */
export const searchBySingleProvidersSpecialty = createAsyncThunk(
  'searchBySingleProvidersSpecialty',
  async (args, thunkApi) => {
    const { getState, dispatch } = thunkApi;

    const state = getState();
    // select the first provider object from the results index
    const initialProvider = selectResults.list(state)[0];

    if (!initialProvider) throw new Error('No providers in results list.');

    // update the search slice with the specialty id and text
    dispatch(actions.search.showMoreProvidersBySpecialty(initialProvider));

    // execute a normal search
    const executeSearchAction = await dispatch(executeSearch());
    const searchRequestId = executeSearchAction?.meta?.requestId;

    // return the single provider so the reducer can add it to the top of the list
    return { initialProvider, searchRequestId };
  }
);

/** This thunk is intended to be dispatched when a users selects a specialty breadcrumb.
 * It will first dispatch an action to set the search slice to a specialty search with the provided specialty;
 * @param {string} args.specialtyName The name of the specialty we will be searching.
 * @param {number} args.specialtyId The id of the specialty we will be searching.
 *
 * @example
 * dispatch(breadcrumbsSpecialtySearch({ specialtyName: 'Primary Care', specialtyId: 5 }));
 */
export const breadcrumbSearch = createAsyncThunk('breadcrumbSearch', async (args, thunkApi) => {
  const { dispatch } = thunkApi;
  const { specialtyName, specialtyId } = args;

  // update the search slice to reflect the new specialty search
  dispatch(actions.search.breadcrumbClicked({ specialtyName, specialtyId }));

  // execute a normal search with the new state
  await dispatch(executeSearch());
});

export const alternateSuggestedSearch = createAsyncThunk(
  'alternateSuggestedSearch',
  async (arg, thunkApi) => {
    const { dispatch } = thunkApi;
    await dispatch(executeSearch());
  }
);

export const urlDirectSearch = createAsyncThunk('urlDirectSearch', async (urlParams, thunkApi) => {
  const { dispatch, getState } = thunkApi;

  // detect mismatch between network slug in local storage and network slug in URL params
  const currentNetworkSlug = selectNetworks.currentSlug(getState());
  const { network_slug: networkSlugParam } = urlParams || {};
  if (networkSlugParam && networkSlugParam !== currentNetworkSlug) {
    dispatch(
      actions.notifications.create({
        message:
          "We've updated this search to use details from your health plan. Some results may look different",
        duration: 5000,
        returnFocusKey: 'searchButton',
        severity: 'warning',
      })
    );
  }

  dispatch(actions.app.updateStoreFromUrl(urlParams));

  const isReadyForSearch = selectSearch.isReadyForSearch(getState());

  if (!isReadyForSearch)
    throw new Error('Missing required search state, cannot complete url direct search');

  await dispatch(executeSearch({ isUrlSearch: true }));
});

/** This think is dispatched when a user clicks the "Apply Filters" button
 * It will collect the appropriate search state based on the previous search and perform a new search
 * Note how there is no need to update the filter state before executing the search, that state is already
 * updated when the as interacts with the filter menu.
 * @param {boolean} args.isBoundsSearch After applying filter, will this be a bounds search or now
 *
 * @example
 * dispatch(applyFilterSearch({ isBoundsSearch: false }));
 */
export const applyFilterSearch = createAsyncThunk('applyFilterSearch', async (args, thunkApi) => {
  const { dispatch } = thunkApi;
  const { isBoundsSearch } = args;

  // reset all search parameters to match the current result set
  dispatch(updateSearchFromResults());

  // match the search type depending on the "chip" UI in the filter menu
  dispatch(actions.search.setIsBoundingBoxSearch(isBoundsSearch));

  // execute a normal search with the new state
  await dispatch(executeSearch());
});

export const expandedRadiusSearch = createAsyncThunk(
  'expandedRadiusSearch',
  async (args, thunkApi) => {
    const { getState, dispatch } = thunkApi;

    const suggestedRadius = selectResults.suggestedExpandedRadius(getState());

    if (!suggestedRadius) throw new Error('No suggested radius in resultsSlice');

    // set new radius
    dispatch(actions.filters.setFilterRadius(suggestedRadius));

    await dispatch(executeSearch());
  }
);
