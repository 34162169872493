import React from 'react';
import { Formik, Form } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import usStates from 'utils/usStates';
import useA11ySelectProps from 'hooks/useA11ySelectProps';
import validationSchema from './supportTicketForm.schema';
import TextInput from '../TextInput';
import SubmitButton from '../SubmitButton';
import PhoneInput from '../PhoneInput';
import SupportTicketError from './SupportTicketError';
import SupportTicketSuccess from './SupportTicketSuccess';
import useSubmitSupportForm from './useSubmitSupportForm';

const DESCRIPTION_MAX_CHARS = 65535; // dup description-max-chars
const ERROR_STATUS = 'error';
const SUCCESS_STATUS = 'success';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  description: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      '& fieldset': {
        borderRadius: theme.shape.borderRadius * 3,
      },
      '& .MuiSelect-root': {
        borderRadius: theme.shape.borderRadius * 3,
      },
    },
  },
  inputRow: {
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiGrid-item:first-child': {
      paddingLeft: 0, // on the first input of a row, remove padding at the start that is set by the spacing prop
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiGrid-item': { padding: 0 }, // on xs screens, the spacing prop makes inputs inside the grid a different width from the rest, so override this
    },
  },
}));

export default function SupportTicketForm() {
  const classes = useStyles();
  const handleSubmit = useSubmitSupportForm({
    errorStatus: ERROR_STATUS,
    successStatus: SUCCESS_STATUS,
  });

  const a11ySelectProps = useA11ySelectProps();

  const FormEditor = (
    <Form className={classes.root} noValidate>
      <Grid container className={classes.inputRow} spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextInput
            name="firstName"
            label="First Name"
            required
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            name="lastName"
            label="Last Name"
            required
            fullWidth
            autoComplete="family-name"
          />
        </Grid>
      </Grid>
      <PhoneInput name="phone" label="Phone" fullWidth />
      <TextInput
        name="email"
        label="Email"
        fullWidth
        required
        autoComplete="email"
        inputProps={{
          autoCapitalize: 'off', // This prevents iOS safari from autocapitalizing email
        }}
      />

      <Grid container className={classes.inputRow} spacing={1}>
        <Grid item xs={12} sm={7}>
          <TextInput name="city" label="City" fullWidth autoComplete="address-level2" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput
            select
            SelectProps={a11ySelectProps}
            name="state"
            label="State"
            fullWidth
            inputProps={{ autoComplete: 'address-level1' }}
          >
            {usStates.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextInput>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInput
            name="zip"
            label="Zip"
            fullWidth
            type="tel" // This is to make mobile devices open a numeric keyboard, but not affect any input validation
            inputProps={{ maxLength: 5 }}
            autoComplete="postal-code"
          />
        </Grid>
      </Grid>

      <TextInput name="subject" label="Subject" required fullWidth />
      <TextInput
        name="description"
        label="Description"
        required
        fullWidth
        multiline
        inputProps={{ maxLength: DESCRIPTION_MAX_CHARS }}
      />

      <SubmitButton>Submit Ticket</SubmitButton>
    </Form>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ status }) => {
        switch (status) {
          case ERROR_STATUS:
            return (
              <>
                {FormEditor}
                <SupportTicketError />
              </>
            );
          case SUCCESS_STATUS:
            return <SupportTicketSuccess />;
          default:
            return FormEditor;
        }
      }}
    </Formik>
  );
}
