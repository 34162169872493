import { CHECKMARK, DEFAULT_LOCATIONS, JPMC } from 'utils/constants';
import { selectToken } from 'utils/utils';
import emboldLogo from 'assets/embold-icon.png';
import emboldLoginLogo from 'assets/embold-login-logo.svg';

export const apiTokens = {
  dev: 'Token 114239562ca194f430bbd66dbdd8c29d566a1078',
  staging: 'Token 9c532d3f65cd4c336dcec997f80353143747314e',
  prod: 'Token 1a38cdb9769d70eb5759168ee420f73cf54ba3e8',
};

const token = selectToken(apiTokens);

const jpmcConfig = {
  CONFIG_NAME: 'jpmc-cigna',
  API_TOKEN: token,

  EMBOLD_DATA_TENANT: 'jpmc-cigna',
  EMBOLD_CLIENT: JPMC,
  EMBOLD_CLIENT_SLUG: JPMC,

  CLOSED_ACCESS: true,
  HAS_PORTAL_INTEGRATION: true,
  FORM_VARIANT: CHECKMARK,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Chicago },
  SHOW_DR_SCORE: false,

  LOGIN_NETWORK_NAME: 'jpmcLoggedIn',
  LOGIN_COOKIE_AUTH_REGEX: ['jpmc', 'i'],
  SHOW_REGION_SELECTOR: false,

  WELCOME_MESSAGE: `Cigna and Embold Health have partnered to bring you Embold’s Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  DISPLAY_NAME: 'Cigna',
  HEADER_LOGO: emboldLogo,
  LOCAL_LOGO_ASSET: emboldLoginLogo,
  EMBOLD_LOGO_PRESENT: false,
  NO_WELCOME_LINK: false,

  SCHEDULE_PHONE_NUMBER: null,
};

export default jpmcConfig;
