import { DEFAULT_LOCATIONS, PASSWORD, REFERRAL_GUIDE_DEMO } from 'utils/constants';

import acmeHeaderLogo from 'assets/pg-acme-header-logo.svg';
import acmeLoginLogo from 'assets/pg-acme-login-logo.png';
import { selectToken } from 'utils/utils';

export const apiTokens = {
  dev: 'Token f6283dad8d25ad493e14031e256941d00864461a',
  staging: 'Token c644972ffa2b628ce368a2087af1c3944d46abe6',
  prod: 'Token 45586194700dcf527f00172d0fedbcb3884cb04c',
};

const token = selectToken(apiTokens);

const referralGuideDemoConfig = {
  CONFIG_NAME: 'referralguide-demo',

  API_TOKEN: token,

  EMBOLD_DATA_TENANT: REFERRAL_GUIDE_DEMO,
  EMBOLD_CLIENT: REFERRAL_GUIDE_DEMO,
  EMBOLD_CLIENT_SLUG: REFERRAL_GUIDE_DEMO,

  // toggles
  SHOW_COST: false,
  SHOW_DISCLAIMER: false,
  SHOW_DR_SCORE: true,

  // login
  LOGIN_NETWORK_NAME: 'referralGuideDemoUserId',
  SHOW_REGION_SELECTOR: true,
  LOGIN_COOKIE_AUTH_REGEX: [
    'referraldemo|aetnamedicareplanhmo|aetnamedicareplanppo|floridawmrhhn|healthspring|bluemedicareclassichmo|bluemedicarevalueppo|humanamedicareppo|humanacareplus|preferredmedicareadvantageflhmo|aarpmedicareadvantagefromuhcep',
    'i',
  ],

  // other
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Orlando },

  // content
  DISPLAY_NAME: 'ACME',
  SCHEDULE_PHONE_NUMBER: '',
  HEADER_LOGO: acmeHeaderLogo,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  FORM_VARIANT: PASSWORD,
  EMBOLD_LOGO_PRESENT: false,
  WELCOME_MESSAGE:
    'ACME and Embold Health have partnered to bring you Embold‘s Referral Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  LOCAL_LOGO_ASSET: acmeLoginLogo,
  NO_WELCOME_LINK: false,
};

export default referralGuideDemoConfig;
