/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';

import PageTemplate from 'components/Pages/PageTemplate';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { LAYOUT_SIDEBAR } from 'components/Pages/constants';
import ChatTerms from 'components/Modals/ChatModal/ChatTerms/ChatTerms';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

const ACCESSING_THE_WEBSITE_ID = 'accessing-the-website';
const INTELLECTUAL_PROPERTY_RIGHTS_ID = 'intellectual-property-rights';
const TRADEMARKS_ID = 'trademarks';
const PROHIBITED_USES_ID = 'prohibited-uses';
const USER_CONTRIBUTIONS_ID = 'user-contributions';
const USER_SUBMISSIONS_ID = 'user-submissions';
const MONITORING_AND_ENFORCEMENT_ID = 'monitoring-and-enforcement';
const CONTENT_STANDARDS_ID = 'content-standards';
const COPYRIGHT_INFRINGEMENT_ID = 'copyright-infringement';
export const RELIANCE_ON_INFORMATION_POSTED_ID = 'reliance-on-information-posted';
const INFORMATION_ABOUT_YOU_ID = 'information-about-you';
const CHANGES_TO_THE_WEBSITE_ID = 'changes-to-the-website';
const DISCLAIMER_OF_WARRANTY_ID = 'disclaimer-of-warranty';
const LIMITATION_LIABILITY_ID = 'limitation-liability';
const INDEMNIFICATION_ID = 'indemnification';
const GOVERNING_LAW_ID = 'governing-law';
const LIMITATION_ON_TIME_ID = 'limitation-on-time';
const CLASS_ACTION_WAIVER_ID = 'class-action-waiver';
const WAIVER_SEVERABILITY_ID = 'waiver-severability';
const ASSIGNMENT_ID = 'assignment';
const REMEDIES_ID = 'remedies';
const ENTIRE_AGREEMENT_ID = 'entire-agreement';
const COMMENTS_ID = 'comments';
const LINKS_FROM_ID = 'links-from';
const COMMUNICATION_ID = 'communication';
export const CHAT_ID = 'beta-testing';

const TERMS_SECTIONS = {
  // [id]: title
  [ACCESSING_THE_WEBSITE_ID]: 'Accessing the Website and Account Security',
  [INTELLECTUAL_PROPERTY_RIGHTS_ID]: 'Intellectual Property Rights',
  [TRADEMARKS_ID]: 'Trademarks',
  [PROHIBITED_USES_ID]: 'Prohibited Uses',
  [USER_CONTRIBUTIONS_ID]: 'User Contributions',
  [USER_SUBMISSIONS_ID]: 'User Submissions',
  [MONITORING_AND_ENFORCEMENT_ID]: 'Monitoring and Enforcement; Termination',
  [CONTENT_STANDARDS_ID]: 'Content Standards',
  [COPYRIGHT_INFRINGEMENT_ID]: 'Copyright Infringement Policy',
  [RELIANCE_ON_INFORMATION_POSTED_ID]: 'Reliance on Information Posted',
  [CHANGES_TO_THE_WEBSITE_ID]: 'Changes to the Website',
  [INFORMATION_ABOUT_YOU_ID]: 'Information About You and Your Visits to the Website',
  [LINKS_FROM_ID]: 'Links from the Website',
  [COMMUNICATION_ID]: 'Mobile and Email Communication Terms',
  [DISCLAIMER_OF_WARRANTY_ID]: 'Disclaimer of Warrantee',
  [LIMITATION_LIABILITY_ID]: 'Limitation on Liability',
  [INDEMNIFICATION_ID]: 'Indemnification',
  [GOVERNING_LAW_ID]: 'Governing Law and Jurisdiction',
  [LIMITATION_ON_TIME_ID]: 'Limitation on Time to File Claims',
  [CLASS_ACTION_WAIVER_ID]: 'Class Action Waiver',
  [WAIVER_SEVERABILITY_ID]: 'Waiver and Severability',
  [ASSIGNMENT_ID]: 'Assignment',
  [REMEDIES_ID]: 'Our Remedies',
  [ENTIRE_AGREEMENT_ID]: 'Entire Agreement',
  [COMMENTS_ID]: 'Your Comments and Concerns',
};

const useTermsSections = () => {
  const enableChat = useSelector(select.featureFlags.enableChat);
  return useMemo(
    () => ({
      ...TERMS_SECTIONS,
      ...(enableChat && {
        [CHAT_ID]: 'Embold Beta Terms and Conditions',
      }),
    }),
    [enableChat]
  );
};

function Terms() {
  const enableChat = useSelector(select.featureFlags.enableChat);
  return (
    <>
      <Typography variant="h1" className="terms-title">
        Terms of Use
      </Typography>

      <Typography variant="subtitle1" component="h2">
        LAST REVISED: FEBRUARY 23, 2024
      </Typography>
      <Typography variant="body2" paragraph>
        The following terms and conditions (these “<strong>Terms of Use</strong>”) constitute an
        agreement between you and Embold Health, Inc. (“<strong>Embold Health</strong>,” or “
        <strong>We</strong>",), the operator of emboldhealth.com including Embold Health Provider
        Guide (collectively, the “<strong>Website</strong>”). These Terms constitute a contract
        between you and us that governs your access and use of the Services, and govern your access
        to and use of the Website, including any content, functionality and services offered on or
        through the Website, whether as a guest or as a user.
      </Typography>
      <Typography variant="body2" paragraph>
        PLEASE READ THE TERMS OF USE CAREFULLY BEFORE YOU START TO USE THE WEBSITE.{' '}
        <strong>
          BY USING THE WEBSITE OR BY CLICKING TO ACCEPT OR AGREE TO THE TERMS OF USE WHEN THIS
          OPTION IS MADE AVAILABLE TO YOU, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS
          OF USE AND OUR PRIVACY STATEMENT, FOUND AT{' '}
          <Link target="_blank" rel="noreferrer" href={window.location.origin.concat('/privacy')}>
            {window.location.origin.concat('/privacy')}
          </Link>
          , INCORPORATED HEREIN BY REFERENCE.
        </strong>{' '}
        IF YOU DO NOT WANT TO AGREE TO THESE TERMS OF USE OR THE PRIVACY STATEMENT, YOU MUST NOT
        ACCESS OR USE THE WEBSITE.
      </Typography>
      <Typography variant="body2" paragraph>
        The Website is offered and available to users who 18 years of age or older. By using this
        Website, you represent and warrant that you are of legal age to form a binding contract with
        Embold Health and meet all of the foregoing eligibility requirements. If you do not meet all
        of these requirements, you must not access or use the Website.
      </Typography>
      <Typography variant="body2" paragraph>
        Embold Health reserves the right to refuse service without prior notice to any user for any
        or no reason. Embold Health also reserves the right to suspend any existing user account for
        any or no reason.
      </Typography>
      <Typography variant="h2">Not Medical Advice</Typography>
      <Typography variant="body2" paragraph>
        Please note that Website is not intended to diagnose, treat, cure, or prevent any disease or
        medical condition. The content on the Website are there for informational purposes only and
        cannot replace the services of health professionals or physicians. You should always consult
        a physician before making any changes to your caregiving based on the content of the
        Website, or if you have any questions regarding a medical condition. Never disregard
        professional medical advice or delay in seeking it because of something you have read on or
        through the Website. We are not responsible for any health problems that may result from the
        content you learn about through the Website. If you make any change to your caregiving based
        on the Website, you agree that you do so fully at your own risk.‍
      </Typography>
      <Typography variant="h2">Changes to the Terms of Use</Typography>
      <Typography variant="body2" paragraph>
        We may revise and update these Terms of Use from time to time in our sole discretion. All
        changes are effective immediately when we post them and apply to all access to and use of
        the Website thereafter. Your continued use of the Website following the posting of revised
        Terms of Use means that you accept and agree to the changes. You are expected to check this
        page from time to time so you are aware of any changes, as they are binding on you.
      </Typography>
      <AnchorTitle id={ACCESSING_THE_WEBSITE_ID} />
      <Typography variant="body2" paragraph>
        We reserve the right to withdraw or amend the Website, and any service or material we
        provide on the Website, in our sole discretion without notice. We will not be liable if, for
        any reason, the Website or any parts of the Website is unavailable at any time or for any
        period.
      </Typography>
      <Typography variant="body2" paragraph>
        You are responsible for:
      </Typography>
      <ul>
        <li>Making all arrangements necessary for you to have access to the Website.</li>
        <li>
          Ensuring that all persons who access the Website through your Internet connection are
          aware of these Terms of Use and comply with them.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        To access the Website or some of the resources it offers, you may be asked to provide
        certain registration details or other information. It is a condition of your use of the
        Website that all the information you provide on the Website is correct, current and
        complete. You agree that all information you provide to register with the Website or
        otherwise, including but not limited to using any interactive features on the Website, is
        governed by our Privacy Statement and you consent to all actions we take with respect to
        your information consistent with our Privacy Statement.
      </Typography>
      <Typography variant="body2" paragraph>
        If you choose, or are provided with, a username, password or any other piece of information
        as part of our security procedures, you must treat such information as confidential, and you
        must not disclose it to any other person or entity. You also acknowledge that your account
        is personal to you and agree not to provide any other person with access to this Website or
        portions of it using your username, password or other security information. You agree to
        notify us immediately of any unauthorized access to or use of your username or password or
        any other breach of security. You also agree to ensure that you exit from your account at
        the end of each session. You should use particular caution when accessing your account from
        a public or shared computer so that others are not able to view or record your password or
        other personal information.
      </Typography>
      <Typography variant="body2" paragraph>
        We have the right to refuse service and/or disable any username, password or other
        identifier, whether chosen by you or provided by us, at any time in our sole discretion for
        any or no reason, including if, in our opinion, you have violated any provision of these
        Terms of Use.
      </Typography>
      <AnchorTitle id={INTELLECTUAL_PROPERTY_RIGHTS_ID} />
      <Typography variant="body2" paragraph>
        The Website and its entire contents, features, and functionality (including but not limited
        to all information, software, text, displays, images, video and audio, and the design,
        selection and arrangement thereof), are owned by Embold Health, its licensors, or other
        providers of such material and are protected by United States and international copyright,
        trademark, patent, trade secret and other intellectual property or proprietary rights laws.
        Embold Health and its licensors retain all of our and their respective right, title, and
        interest to the Website, its content, and the intellectual property rights therein. Any
        rights not expressly granted are reserved.
      </Typography>
      <Typography variant="body2" paragraph>
        You must not reproduce, distribute, modify, create derivative works of, publicly display,
        publicly perform, republish, download, store or transmit any of the material on our Website:
      </Typography>
      <Typography variant="body2" paragraph>
        You must not:
      </Typography>
      <ul>
        <li>Modify copies of any materials from the Website.</li>
        <li>
          Use any illustrations, photographs, video or audio sequences or any graphics separately
          from the accompanying text.
        </li>
        <li>
          Delete or alter any copyright, trademark, or other proprietary rights notices from copies
          of materials from the Website.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        You must not access or use for any commercial purposes any part of the Website or any
        services or materials available through the Website.
      </Typography>
      <Typography variant="body2" paragraph>
        If you wish to make any use of material on the Website other than that set out in this
        section, please address your request to:{' '}
        <Link href="mailto:support@emboldhealth.com">support@emboldhealth.com</Link>.
      </Typography>
      <Typography variant="body2" paragraph>
        If you print, copy, modify, download or otherwise use or provide any other person with
        access to any part of the Website in breach of the Terms of Use, your right to use the
        Website will cease immediately and you must, at our option, return or destroy any copies of
        the materials you have made. No right, title or interest in or to the Website or any content
        on the Website is transferred to you, and all rights not expressly granted are reserved by
        Embold Health. Any use of the Website not expressly permitted by these Terms of Use is a
        breach of these Terms of Use and may violate copyright, trademark and other laws.
      </Typography>
      <AnchorTitle id={TRADEMARKS_ID} />
      <Typography variant="body2" paragraph>
        The Embold Health name, the Embold Health logo and all related names, logos, product and
        service names, designs and slogans are trademarks of Embold Health or its affiliates or
        licensors. You must not use such marks without the prior written permission of Embold
        Health. All other names, logos, product and service names, designs and slogans on this
        Website are the trademarks of their respective owners.
      </Typography>
      <AnchorTitle id={PROHIBITED_USES_ID} />
      <Typography variant="body2" paragraph>
        You may use the Website only for lawful purposes and in accordance with these Terms of Use.
        You agree not to use the Website:
      </Typography>
      <ul>
        <li>
          In any way that violates any applicable federal, state, local or international law or
          regulation (including, without limitation, any laws regarding the export of data or
          software to and from the US or other countries).
        </li>
        <li>
          For the purpose of exploiting, harming or attempting to exploit or harm minors in any way
          by exposing them to inappropriate content, asking for personally identifiable information
          or otherwise.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional material, including
          any “junk mail”, “chain letter” or “spam” or any other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate Embold Health, an Embold Health employee, another
          user or any other person or entity (including, without limitation, by using e-mail
          addresses or screen names associated with any of the foregoing).
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the
          Website, or which, as determined by us, may harm Embold Health or users of the Website or
          expose them to liability.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        Additionally, you agree not to:
      </Typography>
      <ul>
        <li>Sell, trade, or transfer your account to a third party.</li>
        <li>
          Use the Website in any manner that could disable, overburden, damage, or impair the site
          or interfere with any other party’s use of the Website, including their ability to engage
          in real time activities through the Website.
        </li>
        <li>
          Use any robot, spider or other automatic device, process or means to access the Website
          for any purpose, including monitoring or copying any of the material on the Website.
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on the Website or for any
          other unauthorized purpose without our prior written consent.
        </li>
        <li>
          Use any device, software or routine that interferes with the proper working of the
          Website.
        </li>
        <li>
          Introduce any viruses, trojan horses, worms, logic bombs or other material which is
          malicious or technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the
          Website, the server on which the Website is stored, or any server, computer or database
          connected to the Website.
        </li>
        <li>
          Attack the Website via a denial-of-service attack or a distributed denial-of-service
          attack.
        </li>
        <li>Otherwise attempt to interfere with the proper working of the Website.</li>
      </ul>
      <AnchorTitle id={USER_CONTRIBUTIONS_ID} title="Feedback" />
      <Typography variant="body2" paragraph>
        We may provide you with a mechanism to provide feedback, suggestions and ideas, if you
        choose, about our products, services and the Website (“<strong>Feedback</strong>”). You
        agree that we may, in our sole discretion, use the Feedback you provide to us in any way,
        including in future modifications of the Website or in other Embold Health products or
        services. You hereby grant Embold Health a perpetual, worldwide, irrevocable, fully-paid,
        royalty-free, sub-licensable and transferable license to use, modify, create derivative
        works from, distribute, display, and otherwise exploit the Feedback for any purpose.
      </Typography>
      <AnchorTitle id={USER_SUBMISSIONS_ID} />
      <Typography variant="body2" paragraph>
        To the extent that you post, upload, input, submit, or otherwise transmit (collectively,
        “Post”) any text, data, information, and/or other materials provided by you to or in
        connection with the Website (collectively, “User Submissions”), you agree to provide true,
        accurate, and complete information and to refrain from impersonating or falsely representing
        your affiliation with any person or entity. All Posting of User Contributions is your sole
        and exclusive responsibility. YOU AGREE THAT YOU MUST EVALUATE AND BEAR ALL RISK RELATED TO
        THE UPLOADING, INPUTTING, OR TRANSMISSION OF USER SUBMISSIONS TO EMBOLD. By Posting User
        Submissions to the Website, you grant, and you represent and warrant that you have the right
        to grant, to Embold an irrevocable, perpetual, non-exclusive, royalty-free, worldwide
        license to use, copy, perform, display, and distribute User Submissions and to prepare
        derivative works of, or incorporate into other works, User Submissions, and to grant and
        authorize sublicenses of the foregoing. You further warrant that the use of User Submissions
        by Embold and its representatives will not infringe upon or misappropriate the intellectual
        property rights or otherwise violate the rights of any third parties. Embold will not pay
        you for User Submissions or to exercise any rights related to User Submissions set forth in
        this Section.
      </Typography>
      <AnchorTitle id={MONITORING_AND_ENFORCEMENT_ID} />
      <Typography variant="body2" paragraph>
        We have the right to:
      </Typography>
      <ul>
        <li>
          Remove or refuse to post any User Contributions for any or no reason in our sole
          discretion.
        </li>
        <li>
          Take any action with respect to any User Contribution that we deem necessary or
          appropriate in our sole discretion, including if we believe that such User Contribution
          violates the Terms of Use, including the Content Standards, infringes any intellectual
          property right or other right of any person or entity, threatens the personal safety of
          users of the Website or the public or could create liability for Embold Health.
        </li>
        <li>
          Disclose your identity or other information about you to any third party who claims that
          material posted by you violates their rights, including their intellectual property rights
          or their right to privacy.
        </li>
        <li>
          Take appropriate legal action, including without limitation, referral to law enforcement,
          for any illegal or unauthorized use of the Website.
        </li>
        <li>
          Terminate or suspend your access to all or part of the Website for any or no reason,
          including without limitation, any violation of these Terms of Use.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        Without limiting the foregoing, we have the right to fully cooperate with any law
        enforcement authorities or court order requesting or directing us to disclose the identity
        or other information of anyone posting any materials on or through the Website. YOU WAIVE
        AND HOLD HARMLESS EMBOLD HEALTH AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY
        CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT
        OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
        EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </Typography>
      <Typography variant="body2" paragraph>
        However, we cannot review all material before it is posted on the Website, and cannot ensure
        prompt removal of objectionable material after it has been posted. Accordingly, we assume no
        liability for any action or inaction regarding transmissions, communications or content
        provided by any user or third party. We have no liability or responsibility to anyone for
        performance or nonperformance of the activities described in this section.
      </Typography>
      <AnchorTitle id={CONTENT_STANDARDS_ID} />
      <Typography variant="body2" paragraph>
        These content standards apply to any and all User Contributions and use of Interactive
        Services. User Contributions must in their entirety comply with all applicable federal,
        state, local and international laws and regulations. Without limiting the foregoing, User
        Contributions must not:
      </Typography>
      <ul>
        <li>
          Contain any material which is defamatory, obscene, indecent, abusive, offensive,
          harassing, violent, hateful, inflammatory or otherwise objectionable.
        </li>
        <li>
          Promote sexually explicit or pornographic material, violence, or discrimination based on
          race, sex, religion, nationality, disability, sexual orientation or age.
        </li>
        <li>
          Infringe any patent, trademark, trade secret, copyright or other intellectual property or
          other rights of any other person.
        </li>
        <li>
          Violate the legal rights (including the rights of publicity and privacy) of others or
          contain any material that could give rise to any civil or criminal liability under
          applicable laws or regulations or that otherwise may be in conflict with these Terms of
          Use and our Privacy Statement
        </li>
        <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
        <li>
          Impersonate any person, or misrepresent your identity or affiliation with any person or
          organization.
        </li>
        <li>
          Give the impression that they emanate from or are endorsed by us or any other person or
          entity, if this is not the case.
        </li>
      </ul>
      <AnchorTitle id={COPYRIGHT_INFRINGEMENT_ID} />
      <Typography variant="body2" paragraph>
        We take claims of copyright infringement seriously. We will respond to notices of alleged
        copyright infringement that comply with applicable law. If you believe any materials
        accessible on or from this Website infringe your copyright, you may request removal of those
        materials (or access to them) from the Website by submitting written notification to our
        copyright agent designated below. In accordance with the Online Copyright Infringement
        Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (”DMCA”),
        the written notice (the “DMCA Notice”) must include the following:
      </Typography>
      <ul>
        <li>Your physical or electronic signature.</li>
        <li>
          Identification of the copyrighted work you believe to have been infringed or, if the claim
          involves multiple works on the Website, a representative list of such works.
        </li>
        <li>
          Identification of the material you believe to be infringing in a sufficiently precise
          manner to allow us to locate that material.
        </li>
        <li>
          Adequate information by which we can contact you (including your name, postal address,
          telephone number, and, if available, email address).
        </li>
        <li>
          A statement that you have a good faith belief that use of the copyrighted material is not
          authorized by the copyright owner, its agent, or the law.
        </li>
        <li>A statement that the information in the written notice is accurate.</li>
        <li>
          A statement, under penalty of perjury, that you are authorized to act on behalf of the
          copyright owner.
        </li>
      </ul>
      <Typography variant="body2" paragraph>
        Our designated copyright agent to receive DMCA Notices is:
      </Typography>
      <Typography variant="body2" paragraph>
        Name:
      </Typography>
      <Typography variant="body2" paragraph>
        Jurgen Degros
      </Typography>
      <Typography variant="body2" paragraph>
        Company:
      </Typography>
      <Typography variant="body2" paragraph>
        Embold Health, Inc.
      </Typography>
      <Typography variant="body2" paragraph>
        Address:
      </Typography>
      <Typography variant="body2" paragraph>
        1801 West End Ave, Suite 800
        <br aria-hidden="true" />
        Nashville, TN, 37203
      </Typography>
      <Typography variant="body2" paragraph>
        Phone:
      </Typography>
      <Typography variant="body2" paragraph>
        877-EMBOLD1
      </Typography>
      <Typography variant="body2" paragraph>
        Email:
      </Typography>
      <Typography variant="body2" paragraph>
        jurgen.degros@emboldhealth.com
      </Typography>
      <Typography variant="body2" paragraph>
        If you fail to comply with all the requirements of Section 512(c)(3) of the DMCA, your DMCA
        Notice may not be effective.
      </Typography>
      <Typography variant="body2" paragraph>
        Please be aware that if you knowingly materially misrepresent that material or activity on
        the Website is infringing your copyright, you may be held liable for damages (including
        costs and attorneys’ fees) under Section 512(f) of the DMCA.
      </Typography>
      <Typography variant="body2" paragraph>
        It is our policy in appropriate circumstances to disable and/or terminate the accounts of
        users who are repeat infringers.
      </Typography>
      <AnchorTitle id={RELIANCE_ON_INFORMATION_POSTED_ID} />
      <Typography variant="body2" paragraph>
        The information presented on or through the Website is made available solely for general
        information purposes. Embold Health cannot guarantee the authenticity of any jobs or job
        postings that we display on our Website, nor do we warrant the accuracy, completeness or
        usefulness of such information. Any reliance you place on such information is strictly at
        your own risk. We disclaim all liability and responsibility arising from any reliance placed
        on such materials by you or any other visitor to the Website, or by anyone who may be
        informed of any of its contents.
      </Typography>
      <Typography variant="body2" paragraph>
        The Website includes content provided by third parties, including materials provided by
        third-party licensors. All statements and/or opinions expressed in these materials, and all
        articles and responses to questions and other content, other than the content provided by
        Embold Health, are solely the opinions and the responsibility of the person or entity
        providing those materials. These materials do not necessarily reflect the opinion of Embold
        Health. We are not responsible, or liable to you or any third party, for the content or
        accuracy of any materials provided by any third parties.
      </Typography>
      <AnchorTitle id={CHANGES_TO_THE_WEBSITE_ID} />
      <Typography variant="body2" paragraph>
        We will update the content on the Website from time to time, but its content is not
        necessarily complete or up-to-date. Any of the material on the Website may be out of date at
        any given time, and we are under no obligation to update such material.
      </Typography>
      <AnchorTitle id={INFORMATION_ABOUT_YOU_ID} />
      <Typography variant="body2" paragraph>
        All information we collect on the Website is subject to our Privacy Statement. By using the
        Website, you consent to all actions taken by us with respect to your information in
        compliance with the Privacy Statement.
      </Typography>
      <AnchorTitle id={LINKS_FROM_ID} />
      <Typography variant="body2" paragraph>
        If the Website contains links to other sites and resources provided by third parties, these
        links are provided for your convenience only. This includes links contained in
        advertisements, including banner advertisements and sponsored links. We have no control over
        the contents of those sites or resources and accept no responsibility for them or for any
        loss or damage that may arise from your use of them. If you decide to access any of the
        third-party websites linked to the Website, you do so entirely at your own risk and subject
        to the terms and conditions of use for such websites.
      </Typography>
      <AnchorTitle id={COMMUNICATION_ID} />
      <Typography variant="body2" paragraph>
        Embold Health may use email, phone calls, and/ or text messages to communicate with you on a
        recurring basis. By providing your telephone number and/or email address to Embold Health,
        you consent to receive phone calls, text messages, and emails from or on behalf of Embold
        Health and/or our partners and suppliers, including messages using automated dialing
        technology.
      </Typography>
      <Typography variant="body2" paragraph>
        Frequency of calls, text messages and notifications will vary depending upon your
        transactions with us. You represent that you have the permission of the account holder for
        the mobile telephone number(s) that you provide to opt in to receive phone calls and/or text
        messages. You are responsible for notifying Embold Health immediately if you change your
        mobile telephone number. Message and data rates may apply. To unsubscribe to such
        communications, you may click the “unsubscribe” button in our email or reply STOP to our
        text message.
      </Typography>
      <Typography variant="body2" paragraph>
        You understand that text messages have inherent privacy risks, including that unencrypted
        text messages are not secure and could be accessed by an unauthorized party, intercepted, or
        altered without your knowledge or authorization. For additional information on our data
        collection and use, please read our Privacy Statement, which is incorporated by reference
        into these Terms of Use.
      </Typography>
      <AnchorTitle id={DISCLAIMER_OF_WARRANTY_ID} />
      <Typography variant="body2" paragraph>
        You understand that we cannot and do not guarantee or warrant that files available for
        downloading from the Internet or the Website will be free of viruses or other destructive
        code. You are responsible for implementing sufficient procedures and checkpoints to satisfy
        your particular requirements for anti-virus protection and accuracy of data input and
        output, and for maintaining a means external to our site for any reconstruction of any lost
        data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
        ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
        WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY
        MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
      </Typography>
      <Typography variant="body2" paragraph>
        YOUR USE OF THE WEBSITE, RELATED CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH
        THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF
        ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER EMBOLD HEALTH NOR ANY PERSON ASSOCIATED WITH
        EMBOLD HEALTH MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING
        THE FOREGOING, NEITHER EMBOLD HEALTH NOR ANYONE ASSOCIATED WITH EMBOLD HEALTH REPRESENTS OR
        WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
        WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
        OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      </Typography>
      <Typography variant="body2" paragraph>
        EMBOLD HEALTH HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
        STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
        NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
      </Typography>
      <Typography variant="body2" paragraph>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
        APPLICABLE LAW.
      </Typography>
      <AnchorTitle id={LIMITATION_LIABILITY_ID} />
      <Typography variant="body2" paragraph>
        IN NO EVENT WILL EMBOLD HEALTH, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS,
        EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
        THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
        WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR
        ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
        SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
        PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
        WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
        FORESEEABLE.
      </Typography>
      <Typography variant="body2" paragraph>
        BY ACCESSING THE WEBSITE, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO
        CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU
        ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF
        SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY,
        WHICH PROVIDES AS FOLLOWS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
        DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH
        IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
      </Typography>
      <Typography variant="body2" paragraph>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
        APPLICABLE LAW.
      </Typography>
      <AnchorTitle id={INDEMNIFICATION_ID} />
      <Typography variant="body2" paragraph>
        You agree to defend, indemnify and hold harmless Embold Health, its affiliates, licensors
        and service providers, and its and their respective officers, directors, employees,
        contractors, agents, licensors, suppliers, successors and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including
        reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of
        Use or your use of the Website, including, but not limited to, any use of the Website’s
        content, services and products other than as expressly authorized in these Terms of Use or
        your use of any information obtained from the Website.
      </Typography>
      <AnchorTitle id={GOVERNING_LAW_ID} />
      <Typography variant="body2" paragraph>
        All matters relating to the Website and these Terms of Use and any dispute or claim arising
        therefrom or related thereto (in each case, including non-contractual disputes or claims),
        shall be governed by and construed in accordance with the internal laws of the State of
        Tennessee without giving effect to any choice or conflict of law provision or rule (whether
        of the State of Tennessee or any other jurisdiction).
      </Typography>
      <Typography variant="body2" paragraph>
        Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or
        the Website shall be instituted exclusively in the federal courts of the United States or
        the courts of the State of Tennessee in each case located in the City of Nashville although
        we retain the right to bring any suit, action or proceeding against you for breach of these
        Terms of Use in your country of residence or any other relevant country. You waive any and
        all objections to the exercise of jurisdiction over you by such courts and to venue in such
        courts.
      </Typography>
      <AnchorTitle id={LIMITATION_ON_TIME_ID} />
      <Typography variant="body2" paragraph>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE
        OR THE WEBSITES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
        OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
      </Typography>
      <AnchorTitle id={CLASS_ACTION_WAIVER_ID} />
      <Typography variant="body2" paragraph>
        IN ANY DISPUTE, NEITHER YOU NOR ANY OTHER PERSON SHALL BE ENTITLED TO JOIN OR CONSOLIDATE
        CLAIMS BY OR AGAINST OTHER AFFILIATES OR PERSONS, OR ARBITRATE ANY CLAIM AS A REPRESENTATIVE
        OR CLASS ACTION OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. YOU ACKNOWLEDGE THAT YOU ARE
        GIVING UP YOUR RIGHTS TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT
        TO ANY SUCH CLAIM.
      </Typography>
      <AnchorTitle id={WAIVER_SEVERABILITY_ID} />
      <Typography variant="body2" paragraph>
        No waiver of by Embold Health of any term or condition set forth in these Terms of Use shall
        be deemed a further or continuing waiver of such term or condition or a waiver of any other
        term or condition, and any failure of Embold Health to assert a right or provision under
        these Terms of Use shall not constitute a waiver of such right or provision.
      </Typography>
      <Typography variant="body2" paragraph>
        If any provision of these Terms of Use is held by a court or other tribunal of competent
        jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
        eliminated or limited to the minimum extent such that the remaining provisions of the Terms
        of Use will continue in full force and effect.
      </Typography>
      <AnchorTitle id={ASSIGNMENT_ID} />
      <Typography variant="body2" paragraph>
        We may assign these Terms or any part of them without restriction or condition. You may not
        assign or otherwise transfer these Term or your rights under these Terms without our prior
        written consent and any assignment in violation of this prohibition will be null and void.
      </Typography>
      <AnchorTitle id={REMEDIES_ID} />
      <Typography variant="body2" paragraph>
        You agree that any violation, or threatened violation, by you of these Terms constitutes an
        unlawful and unfair business practice that will cause us irreparable and unquantifiable
        harm. You also agree that monetary damages would be inadequate for such harm and consent to
        our obtaining any injunctive or equitable relief that we deem necessary or appropriate.
        These remedies are in addition to any other remedies we may have at law or in equity.
      </Typography>
      <AnchorTitle id={ENTIRE_AGREEMENT_ID} />
      <Typography variant="body2" paragraph>
        The Terms of Use and our Privacy Statement constitute the sole and entire agreement between
        you and Embold Health with respect to the Website and supersede all prior and
        contemporaneous understandings, agreements, representations and warranties, both written and
        oral, with respect to the Website.
      </Typography>
      <AnchorTitle id={COMMENTS_ID} />
      <Typography variant="body2" paragraph>
        This Website is operated by Embold Health, Inc.
      </Typography>
      <Typography variant="body2" paragraph>
        All feedback, comments, requests for technical support and other communications relating to
        the Website should be directed to{' '}
        <Link href="mailto:support@emboldhealth.com">support@emboldhealth.com</Link> or by mail to
        Embold Health, Inc. 1801 West End Ave, Suite 800 Nashville, TN 37203.
      </Typography>
      <AnchorTitle id={CHAT_ID} hideHeader />
      {enableChat && <ChatTerms />}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  anchorWrap: {
    position: 'relative',
  },
  anchorTarget: {
    position: 'absolute',
    top: -120,
    [theme.breakpoints.down('sm')]: {
      top: -112,
    },
  },
}));

function AnchorTitle({ id, title, hideHeader }) {
  const termsSections = useTermsSections();
  const classes = useStyles();
  return (
    <div className={classes.anchorWrap}>
      <span className={classes.anchorTarget} id={id} />
      {!hideHeader && (
        <Typography variant="h3">
          <strong>{title || termsSections[id]}</strong>
        </Typography>
      )}
    </div>
  );
}

AnchorTitle.propTypes = {
  id: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  title: PropTypes.string,
};

AnchorTitle.defaultProps = {
  hideHeader: false,
  title: undefined,
};

function LI({ href, text }) {
  const listItemStyle = {
    padding: 0,
    color: 'inherit',
  };

  return (
    <ListItem style={listItemStyle}>
      <Link href={href}>
        <ListItemText primary={text} />
      </Link>
    </ListItem>
  );
}

LI.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function Sidebar() {
  const termsSections = useTermsSections();
  return (
    <>
      <Typography variant="h2" id="terms-sidebar-title">
        Contents
      </Typography>
      <Divider style={{ margin: '.15rem 0' }} />
      <nav aria-labelledby="terms-sidebar-title">
        <List dense disablePadding>
          {Object.keys(termsSections).map((key) => (
            <LI href={`#${key}`} text={termsSections[key]} key={key} />
          ))}
        </List>
      </nav>
    </>
  );
}

export default PageTemplate(Terms, 'Terms of Use', LAYOUT_SIDEBAR, Sidebar);
