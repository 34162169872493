import { createAsyncThunk } from '@reduxjs/toolkit';

import { distance, isNotNullish } from 'utils/utils';
import * as selectAxios from '../config/selectAxios';
import * as selectConfig from '../config/selectConfig';
import * as selectChat from './selectChat';
import { buildApiUrl, findSmallestRadius } from './chatUtils';
import { CHAT_URL, ANALYZE_CHAT_URL, QUICK_FEEDBACK_URL } from './chatConstants';

export const askEva = createAsyncThunk('askEva', async (args, thunkApi) => {
  const { getState, rejectWithValue } = thunkApi;
  const state = getState();
  const payload = selectChat.chatPayload(state);
  const API_URL = selectConfig.apiUrl(state);
  const axios = selectAxios.axiosInstance(state);

  const url = `${API_URL}/${CHAT_URL}`;

  try {
    const res = await axios.post(url, payload);
    return res.data;
  } catch (e) {
    return rejectWithValue(e?.response?.data?.detail);
  }
});

export const analyzeChat = createAsyncThunk('analyzeChat', async (args, thunkApi) => {
  const { getState, rejectWithValue } = thunkApi;
  const state = getState();
  const payload = selectChat.chatPayload(state);
  const API_URL = selectConfig.apiUrl(state);
  const axios = selectAxios.axiosInstance(state);

  const url = `${API_URL}/${ANALYZE_CHAT_URL}`;

  try {
    const res = await axios.post(url, payload);
    return res.data;
  } catch (e) {
    return rejectWithValue(e?.response?.data?.detail);
  }
});

export const providerSearch = createAsyncThunk('providerSearch', async (searchParams, thunkApi) => {
  const { getState } = thunkApi;

  const axios = selectAxios.axiosInstance(getState());

  if (!searchParams.fetchUrl) throw new Error('No URL provided');
  const response = await axios.get(searchParams.fetchUrl);

  return response.data;
});

export const expandProviderSearch = createAsyncThunk(
  'expandProviderSearch',
  async (initialSearchAction, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    const state = getState();
    const axios = selectAxios.axiosInstance(state);

    // get all of the values from the initial search that was forced to expand
    const { meta, payload: initialSearchResponse } = initialSearchAction;
    const requestKey = meta.requestId;
    const searchParams = meta.arg;

    // get the nearest location based on search params
    const { findClosestLocationUrl } = searchParams;
    const closestLocationResponse = await axios.get(findClosestLocationUrl);
    const { location: closestLocation } = closestLocationResponse.data;

    // if no closest location was found, return original result but with updated radius to max
    if (!closestLocation) return { ...initialSearchResponse, requestKey, newRadius: 250 };

    // get distance to closest location
    const distanceToClosestLocation = distance(
      searchParams.location.latitude,
      searchParams.location.longitude,
      closestLocation.lat,
      closestLocation.lon
    );
    const newRadius = findSmallestRadius(distanceToClosestLocation);
    if (!newRadius) return rejectWithValue('No new radius to expand to');

    // if we have a new radius, perform search with that
    const newParams = { ...searchParams, radius: newRadius };
    const newUrl = buildApiUrl({ endpoint: 'providers', params: newParams });
    const response = await axios.get(newUrl);

    return { ...response.data, requestKey, newRadius };
  }
);

export const performAllSearches = createAsyncThunk('performAllSearches', async (args, thunkApi) => {
  const { getState, dispatch } = thunkApi;
  const state = getState();

  const searches = selectChat.searches(state);

  const allSearches = searches.map((search) =>
    dispatch(providerSearch(search)).then((fulfilledAction) => {
      // start expand search process for any search that returned no results
      if (fulfilledAction.payload?.count === 0) {
        dispatch(expandProviderSearch(fulfilledAction));
      }
    })
  );
  await Promise.all(allSearches);
});

export const submitQuickFeedback = createAsyncThunk(
  'submitQuickFeedback',
  async (args, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    const state = getState();
    const { thumbsUp, message } = args;

    const chatKey = selectChat.chatKey(state);
    const API_URL = selectConfig.apiUrl(state);
    const axios = selectAxios.axiosInstance(state);

    const payload = {
      chatKey,
      ...(isNotNullish(thumbsUp) && { thumbsUp }),
      ...(isNotNullish(message) && { message }),
    };

    const url = `${API_URL}/${QUICK_FEEDBACK_URL}`;

    try {
      const res = await axios.post(url, payload);
      return res.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data?.detail);
    }
  }
);
