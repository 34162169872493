/* eslint-disable prefer-template */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'propTypes';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SkipLink from 'components/a11y/SkipLink';
import { useSelector, useDispatch } from 'react-redux';
import { select, actions } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    margin: 0,
  },
  label: {
    fontSize: '.875rem', // 14px
    color: theme.palette.grey[600],
    marginRight: 2,
  },
  checkboxRoot: {
    padding: 2,
    color: theme.palette.grey[600],
  },
}));

function CompareCheckbox({ resultId, labelClasses, labelRootClasses, checkboxRootClasses }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const enableCompareModal = useSelector(select.results.enableCompareModal);
  const compareButtonText = useSelector(select.results.compareButtonText);

  const individualResult = useSelector(select.results.byId(resultId));
  const handleSkipLink = useCallback(() => {
    history.replace({ ...history.location, pathname: '/results/compare' }); // update path but maintain query params
  }, [history]);

  const isCompareListFull = useSelector(select.results.isCompareListFull);
  const isSelectedForComparison = useSelector(select.results.isIdInCompareList(resultId));

  const handleCompareCheckbox = useCallback(() => {
    dispatch(actions.results.toggleIdInCompareList(resultId));
  }, [dispatch, resultId]);

  return (
    <>
      <FormControlLabel
        htmlFor={`compare-checkbox-${resultId}`}
        classes={{
          root: `${labelRootClasses} ${classes.labelRoot}`,
          label: `${labelClasses} ${classes.label}`,
        }}
        control={
          <Checkbox
            classes={{
              root: `${checkboxRootClasses} ${classes.checkboxRoot}`,
            }}
            disabled={!isSelectedForComparison && isCompareListFull}
            onChange={handleCompareCheckbox}
            value="compare"
            checked={isSelectedForComparison}
            inputProps={{ id: `compare-checkbox-${resultId}` }}
          />
        }
        label="Compare"
        labelPlacement="start"
        aria-label={`${individualResult.entityName ?? individualResult.name} 
            ${isSelectedForComparison ? 'remove ' : 'add '}
           ${isSelectedForComparison ? ` from compare list` : ` to compare list`}
           `}
      />
      {enableCompareModal && <SkipLink handleClick={handleSkipLink} label={compareButtonText} />}
    </>
  );
}

export default React.memo(CompareCheckbox);

CompareCheckbox.propTypes = {
  resultId: PropTypes.string.isRequired,
  labelClasses: PropTypes.string,
  labelRootClasses: PropTypes.string,
  checkboxRootClasses: PropTypes.string,
};

CompareCheckbox.defaultProps = {
  labelClasses: '',
  labelRootClasses: '',
  checkboxRootClasses: '',
};
