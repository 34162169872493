/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { PLACE_RESULT_TYPE } from 'utils/constants';
import EmergencyCareDescription from './EmergencyCareDescription';

export const specialtySuggestionMap = {};

/* **************** */
/* subspecialties */
/* **************** */
const EMERGENCY_CARE_HOSPITAL_SS_ID = 4001;
const EMERGENCY_CARE_STANDALONE_SS_ID = 416;

const EMERGENCY_CARE_TITLE = 'Try Urgent Care';
const EMERGENCY_CARE_DESCRIPTION = <EmergencyCareDescription />;

const URGENT_CARE_SUGGESTION = {
  subspecialtyId: 406,
  specialtyId: 300,
  name: 'Urgent Care',
  type: PLACE_RESULT_TYPE,
};

export const subspecialtySuggestionMap = {
  [EMERGENCY_CARE_HOSPITAL_SS_ID]: {
    title: EMERGENCY_CARE_TITLE,
    description: EMERGENCY_CARE_DESCRIPTION,
    suggestion: URGENT_CARE_SUGGESTION,
  },
  [EMERGENCY_CARE_STANDALONE_SS_ID]: {
    title: EMERGENCY_CARE_TITLE,
    description: EMERGENCY_CARE_DESCRIPTION,
    suggestion: URGENT_CARE_SUGGESTION,
  },
};
