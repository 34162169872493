import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { AzureMapDataSourceProvider, AzureMapFeature } from 'react-azure-maps';

import { select, actions } from 'store/toolkit';

/** This component creates a data provider with the results from Redux. It then renders children layers that can access that data that was added to the provider */
export default function ResultDataProvider({ dataProviderId, children }) {
  const dispatch = useDispatch();
  const resultLocations = useSelector(select.results.mapFeatures);
  const isBoundingBoxSearch = useSelector(select.search.isBoundingBoxSearch);

  useEffect(() => {
    if (resultLocations.length > 0 && !isBoundingBoxSearch) {
      dispatch(actions.map.updateBoundsToFitResults({ coordinates: resultLocations }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultLocations]);

  return (
    // Create the data provider
    <AzureMapDataSourceProvider
      id={dataProviderId}
      options={{
        cluster: true,
        clusterRadius: 20,
        clusterProperties: {
          // this creates an aggregation of all of the resultIds within the cluster
          // example: if "123", "456" and "789" are the result id's of each feature in the cluster
          // cluster would have a "resultIds" property with value of "123,456,789,"
          resultIds: ['concat', ['concat', ['get', 'resultId'], ',']],
        },
      }}
    >
      {/* Add data points to the map */}
      {resultLocations.map(({ latitude, longitude, featureId, srText, ...rest }) => (
        <AzureMapFeature
          key={featureId}
          id={featureId} // unique id required for azure maps data points
          type="Point"
          variant="shape"
          coordinate={[longitude, latitude]}
          properties={{ featureId, ...rest }} // any data that should be associated with this data point
        />
      ))}

      {/* Render children layers that can access the data that was added */}
      {children}
    </AzureMapDataSourceProvider>
  );
}

ResultDataProvider.propTypes = {
  dataProviderId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
