/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { select } from 'store/toolkit';
import { PERCENTILE_THRESHOLD } from 'utils/constants';
import Tooltip from 'components/Tooltip';

const useStyles = makeStyles(() => ({
  strengthContainer: {
    padding: '6px 8px',
  },
}));

export const strengthLanguageMap = {
  diagnosing: {
    preface: 'This provider is',
    top10: {
      performanceLanguage: 'Excellent',
    },
    top25: {
      performanceLanguage: 'Above Average',
    },
    categoryLanguage: 'at using the proper tests and procedures for diagnosis and screening.',
    supplementalExplanatoryLanguage:
      'Diagnosing may include completing more screenings and tests according to guidelines, as well as ordering fewer unnecessary tests.',
  },
  treatment: {
    preface: 'This provider is',
    top10: {
      performanceLanguage: 'Excellent',
    },
    top25: {
      performanceLanguage: 'Above Average',
    },
    categoryLanguage: 'at providing effective patient-centered treatment, care, and management.',
    supplementalExplanatoryLanguage:
      'Treatment may include achieving good medication adherence rates, appropriately monitoring patients’ health status, and providing guideline recommended care.',
  },
  outcomes: {
    preface: 'This provider achieves',
    top10: {
      performanceLanguage: 'Excellent',
    },
    top25: {
      performanceLanguage: 'Above Average',
    },
    categoryLanguage: 'outcomes for their patients',
    supplementalExplanatoryLanguage:
      'Patient Outcomes may include reducing procedure-related complications, lowering ED visits or hospitalizations, and better managing chronic conditions.',
  },
};

const getTopPercentileNumber = (percentile) => {
  const roundedPercent = Math.floor(percentile * 100);
  const percentIncrements = [95, 90, 85, 80, 75];
  const percentIncrementMatch = percentIncrements.find(
    (incrementNumber) => roundedPercent >= incrementNumber
  );

  return percentIncrementMatch ? 100 - percentIncrementMatch : null;
};

export function ProviderStrengthPerformanceLanguage({ context, percentile, lowercase }) {
  if (percentile >= 0.9) {
    return (
      <strong>
        {lowercase
          ? context.top10.performanceLanguage.toLowerCase()
          : context.top10.performanceLanguage}
      </strong>
    );
  }
  if (percentile >= 0.75) {
    return (
      <strong>
        {lowercase
          ? context.top25.performanceLanguage.toLowerCase()
          : context.top25.performanceLanguage}
      </strong>
    );
  }
  return '';
}

function ProviderStrengthSection({ title, context, percentile }) {
  const classes = useStyles();
  return percentile >= PERCENTILE_THRESHOLD ? (
    <div className={classes.strengthContainer}>
      <Typography variant="h4">
        {title}
        <Tooltip
          message={context.supplementalExplanatoryLanguage}
          aria-label={`More information about ${title}`}
        />
      </Typography>

      <Typography>
        {context.preface}{' '}
        <ProviderStrengthPerformanceLanguage context={context} percentile={percentile} lowercase />{' '}
        {context.categoryLanguage}
      </Typography>
    </div>
  ) : (
    ''
  );
}

function ProviderOverallScore({ percentile, specialty }) {
  const classes = useStyles();
  const topPercentileNumber = percentile ? getTopPercentileNumber(percentile) : null;
  return topPercentileNumber ? (
    <div className={classes.strengthContainer}>
      <Typography variant="h3">Overall Doctor Quality</Typography>
      <Typography>
        This provider ranks in the <strong>Top {topPercentileNumber}%</strong> compared to other{' '}
        {specialty} doctors in the area.
      </Typography>
    </div>
  ) : null;
}

/** @deprecated */
export function ProviderStrengths({ profileData }) {
  const {
    diagnosingPercentile,
    treatmentPercentile,
    outcomesPercentile,
    overallPercentile,
    specialty,
  } = profileData;

  const activeSubspecialtyId = useSelector(select.results.subspecialtyId);

  return (
    <>
      {activeSubspecialtyId && (
        <ProviderOverallScore percentile={overallPercentile} specialty={specialty} />
      )}
      <ProviderStrengthSection
        title="Diagnosing"
        context={strengthLanguageMap.diagnosing}
        percentile={diagnosingPercentile}
      />
      <ProviderStrengthSection
        title="Treatment"
        context={strengthLanguageMap.treatment}
        percentile={treatmentPercentile}
      />
      <ProviderStrengthSection
        title="Patient Outcomes"
        context={strengthLanguageMap.outcomes}
        percentile={outcomesPercentile}
      />
    </>
  );
}
