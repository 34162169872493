/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import {
  CARE_CATEGORIES,
  CARE_CATEGORY_OPTIONS,
  PLACE_RESULT_TYPE,
  PROVIDER_RESULT_TYPE,
} from 'utils/constants';

import * as selectLocation from '../location/selectLocation';
import { SEARCH_SLICE_NAME } from '../slicesNames';

// Basic selectors

/** Returns the entire searchSlice - should be used sparingly. In most cases you should use a more granular selector. */
export const slice = (state) => state[SEARCH_SLICE_NAME];

/** @returns {string} The type of search being performed - from CARE_CATEGORIES */
export const type = (state) => state[SEARCH_SLICE_NAME].type;

/** @returns {string} The text shown in the ProviderAutocomplete text box */
export const text = (state) => state[SEARCH_SLICE_NAME].text;

export const specialtyId = (state) => state[SEARCH_SLICE_NAME].specialtyId;
export const subspecialtyId = (state) => state[SEARCH_SLICE_NAME].subspecialtyId;
export const entityId = (state) => state[SEARCH_SLICE_NAME].entityId;
export const entityIds = (state) => state[SEARCH_SLICE_NAME].entityIds;
export const serviceId = (state) => state[SEARCH_SLICE_NAME].serviceId;

/** @returns {PLACE_RESULT_TYPE|PROVIDER_RESULT_TYPE|null} The type of service to search for */
export const serviceType = (state) => state[SEARCH_SLICE_NAME].serviceType;

/** @returns {boolean} Indicates if the user has selected an option from the search autocomplete suggestions */
export const isSuggestionSelected = (state) => state[SEARCH_SLICE_NAME].isSuggestionSelected;

/** @returns {boolean} If true, then searches are performed using a bounding box rather than location + radius */
export const isBoundingBoxSearch = (state) => state[SEARCH_SLICE_NAME].isBoundingBoxSearch;

// Advanced selectors
/** @returns {boolean} True for all place by name, place by type, or place type service searches */
export const isPlaceSearch = createSelector([type, serviceType], (searchType, serviceType) => {
  if (searchType === CARE_CATEGORIES.SERVICE) return serviceType === PLACE_RESULT_TYPE;
  return (
    searchType === CARE_CATEGORIES.FACILITY_NAME || searchType === CARE_CATEGORIES.FACILITY_TYPE
  );
});

/** @returns {boolean} True for provider by name and provider by specialty */
export const isProviderSearch = createSelector([type, serviceType], (searchType, serviceType) => {
  if (searchType === CARE_CATEGORIES.SERVICE) return serviceType === PROVIDER_RESULT_TYPE;
  return (
    searchType === CARE_CATEGORIES.PROVIDER_NAME ||
    searchType === CARE_CATEGORIES.PROVIDER_SPECIALTY
  );
});

/** @returns {boolean} True for service searches with a valid service type */
export const isServiceSearch = createSelector([type, serviceType], (searchType, serviceType) => {
  if (serviceType !== PROVIDER_RESULT_TYPE && serviceType !== PLACE_RESULT_TYPE) return false; // service searches require a service type to be valid
  return searchType === CARE_CATEGORIES.SERVICE;
});

/** @returns {boolean} A loose place search suggests that we are searching for a place, but have not selected a specific place from autocomplete suggestions */
export const isLoosePlaceSearch = createSelector(
  [type, isSuggestionSelected],
  (searchType, suggestionSelected) =>
    searchType === CARE_CATEGORIES.FACILITY_NAME && !suggestionSelected
);

/** @returns {boolean} True if doing a place by name search and autocomplete suggestion was chosen */
export const isPlaceByExactNameSearch = createSelector(
  [type, isLoosePlaceSearch],
  (searchType, isLoosePlaceSearch) =>
    searchType === CARE_CATEGORIES.FACILITY_NAME && !isLoosePlaceSearch
);

/** @returns {Object|undefined} Returns the corresponding options object from CARE_CATEGORY_OPTIONS for the current search type */
export const currentSearchTypeOptions = createSelector(
  [type],
  (searchType) => CARE_CATEGORY_OPTIONS[searchType]
);

/** @returns {boolean} Indicates that we are searching for one single provider via entityId */
export const isSingleProviderSearch = createSelector(
  [entityId, type],
  (entityId, type) => Boolean(entityId) && type === CARE_CATEGORIES.PROVIDER_NAME
);

/** @returns {boolean} Indicates that we are searching for one single place via entityId */
export const isSinglePlaceSearch = createSelector(
  [entityId, type],
  (entityId, type) => Boolean(entityId) && type === CARE_CATEGORIES.FACILITY_NAME
);

/** @returns {boolean} True if the type is a valid value in the CARE_CATEGORIES object */
export const isValidSearchType = createSelector([type], (searchType) => {
  const validSearchTypes = Object.values(CARE_CATEGORIES);
  return validSearchTypes.includes(searchType);
});

/** @returns {boolean} For a given searchType, 'true' if there enough information in the searchInput to perform a search, 'false' otherwise */
export const isValidSearchInput = createSelector(
  [type, specialtyId, subspecialtyId, entityId, serviceId, serviceType, text],
  (type, specialtyId, subspecialtyId, entityId, serviceId, serviceType, text) => {
    switch (type) {
      case CARE_CATEGORIES.FACILITY_NAME:
        return Boolean(text?.length >= 2);
      case CARE_CATEGORIES.FACILITY_TYPE:
        return Boolean(specialtyId || subspecialtyId);
      case CARE_CATEGORIES.PROVIDER_NAME:
        return Boolean(entityId || text?.length >= 2);
      case CARE_CATEGORIES.PROVIDER_SPECIALTY:
        return Boolean(specialtyId || subspecialtyId);
      case CARE_CATEGORIES.SERVICE:
        return Boolean(
          serviceId &&
            serviceType &&
            (serviceType === PLACE_RESULT_TYPE || serviceType === PROVIDER_RESULT_TYPE)
        );
      default:
        return false;
    }
  }
);

/** @returns {boolean} True if we have enough data to perform a search */
/** note: "searchThisArea" does not require validLatLong, only validSearchInput */
export const isReadyForSearch = createSelector(
  [isValidSearchInput, selectLocation.validatedLatLong, selectLocation.locationResolved],
  (validSearchInput, validLatLong, locationResolved) => {
    if (validLatLong && validSearchInput && locationResolved) return true;
    return false;
  }
);
