import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { specialtiesUrl as selectSpecialtiesUrl } from 'store/fusionServices/selectFusion';
import { axiosInstance } from '../config/selectAxios';
import { SPECIALTIES_SLICE_NAME } from '../slicesNames';

// eslint-disable-next-line import/prefer-default-export
export const fetchSpecialties = createAsyncThunk(
  `${SPECIALTIES_SLICE_NAME}/fetch`,
  async (args, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;

    const state = getState();
    const axios = axiosInstance(state);

    // get service url
    const url = selectSpecialtiesUrl(state);

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(e);
    }
  }
);
