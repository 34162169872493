import { DEMO, DEFAULT_LOCATIONS, PASSWORD } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import acmeHeaderLogo from 'assets/pg-acme-header-logo.svg';
import acmeLoginLogo from 'assets/pg-acme-login-logo.png';

export const apiTokens = {
  dev: 'Token 74fec37dc920f23166de2c27c1e6f132dec99e1e',
  staging: 'Token 4f1a091af010806e664f27ffcbd0a9b19cc9a060',
  prod: 'Token 8b54554bda38e770fcdcfe3690c85b8f78a875d9',
};

const token = selectToken(apiTokens);

const demoConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'demo',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'acme',
  EMBOLD_CLIENT: DEMO,
  EMBOLD_CLIENT_SLUG: 'acme',

  // toggles
  SHOW_COST: true,
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,

  // login
  LOGIN_NETWORK_NAME: 'demoUserId',
  SHOW_REGION_SELECTOR: true,
  LOGIN_COOKIE_AUTH_REGEX: ['acmehomegoods|acmetechnology|surgeryplus|acmegrocery|demo'],

  // other
  HIDE_CLIENT_PROMOTE_HEADER: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },

  // content
  DISPLAY_NAME: 'ACME',
  SCHEDULE_PHONE_NUMBER: '800-555-1234',
  HEADER_LOGO: acmeHeaderLogo,
  SUPPORT_LINK: 'https://emboldhealth.com/',
  FORM_VARIANT: PASSWORD,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE:
    'ACME and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.',
  LOCAL_LOGO_ASSET: acmeLoginLogo,
  NO_WELCOME_LINK: false,
};

export default demoConfig;
