import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  FormControl,
  Button,
  Collapse,
  TextField,
  Box,
  CircularProgress,
} from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SmileyFace from '@mui/icons-material/TagFaces';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'propTypes/index';
import { checkInvalidTextField } from 'utils/utils';
import { select, actions } from 'store/toolkit';

const THUMBS_UP = 'thumbs-up';
const THUMBS_DOWN = 'thumbs-down';

const BUTTONS = {
  [THUMBS_UP]: {
    value: true,
    buttonLabel: 'Good',
    messageLabel: 'What did you like about this experience?',
    icon: <ThumbUpIcon />,
  },
  [THUMBS_DOWN]: {
    value: false,
    buttonLabel: 'Poor',
    messageLabel: 'How can we improve?',
    icon: <ThumbDownIcon />,
  },
};

const SENDING = 'Sending';
const SENT = 'Sent';

const SENDING_ICONS = {
  [SENDING]: <CircularProgress size={20} />,
  [SENT]: <CheckIcon size={20} color="success" />,
};

/* 
CHAT_QUICK_FEEDBACK_PROGRESS
0: 'not-started'
1: 'thumb-rating-submitted'
2: 'message-submitted'
*/

function ChatQuickFeedbackForm({ handleSubmit }) {
  const dispatch = useDispatch();
  const quickFeedbackProgress = useSelector(select.chat.chatQuickFeedbackProgress);

  const endOfFeedbackForm = useRef();
  const [thumbValue, setThumbValue] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [sendingIcon, setSendingIcon] = useState(null);

  const scrollMessageIntoView = useCallback(() => {
    if (endOfFeedbackForm?.current)
      endOfFeedbackForm.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [endOfFeedbackForm]);

  const handleThumbClick = useCallback(
    (key) => {
      setThumbValue(key);
      dispatch(actions.chat.setQuickFeedbackProgress(1));
      handleSubmit({ thumbsUp: BUTTONS[key].value });
      setTimeout(scrollMessageIntoView, 500);
    },
    [dispatch, handleSubmit, scrollMessageIntoView]
  );

  const handleChange = useCallback((e) => {
    setError('');
    setMessage(e.target.value);
  }, []);

  const sentTimeout = useCallback(() => {
    setTimeout(() => dispatch(actions.chat.setQuickFeedbackProgress(2)), 2000);
  }, [dispatch]);

  const sendingTimeout = useCallback(() => {
    setSendingIcon(SENDING);
    setTimeout(() => {
      setSendingIcon(SENT);
      sentTimeout();
    }, 1000);
  }, [sentTimeout]);

  const handleMessageSubmit = useCallback(() => {
    const errorMessage = checkInvalidTextField(message);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError('');
      handleSubmit({ message });
      sendingTimeout();
    }
  }, [handleSubmit, message, sendingTimeout]);

  return (
    <Grid container justifyContent="center">
      <FormControl component="fieldset">
        <Typography variant="h3" component="legend" color="primary">
          How was your overall chat experience with Embold&apos;s Virtual Assistant?
        </Typography>
        <Collapse in={quickFeedbackProgress === 0}>
          <Grid container justifyContent="center">
            <ThumbButton value={THUMBS_UP} onClick={() => handleThumbClick(THUMBS_UP)} />
            <ThumbButton value={THUMBS_DOWN} onClick={() => handleThumbClick(THUMBS_DOWN)} />
          </Grid>
        </Collapse>
        <Collapse in={quickFeedbackProgress > 0}>
          <Grid style={{ padding: '8px 0' }} container justifyContent="center">
            <SmileyFace style={{ marginRight: 6 }} />
            <Typography align="center" color="primary">
              Thank you for your feedback!
            </Typography>
          </Grid>
        </Collapse>
        <Collapse in={quickFeedbackProgress === 1}>
          <Box align="center">
            <TextField
              variant="outlined"
              margin="dense"
              label={BUTTONS[thumbValue]?.messageLabel || 'How can we improve?'}
              fullWidth
              multiline
              minRows={4}
              value={message}
              onChange={handleChange}
              inputProps={{ maxLength: 1024 }}
              InputLabelProps={{ style: { textAlign: 'left', maxWidth: '95%' } }}
              error={Boolean(error)}
              disabled={Boolean(sendingIcon)}
            />
            <Button
              disabled={!message || Boolean(sendingIcon)}
              variant="contained"
              color="primary"
              onClick={handleMessageSubmit}
              style={{ margin: '8px 0' }}
              endIcon={Boolean(sendingIcon) && SENDING_ICONS[sendingIcon]}
            >
              {sendingIcon || 'Submit'}
            </Button>
            {error && <Typography color="error">{error}</Typography>}
          </Box>
        </Collapse>
        <div ref={endOfFeedbackForm} />
      </FormControl>
    </Grid>
  );
}

ChatQuickFeedbackForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    minWidth: 135,
  },
  [THUMBS_UP]: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  [THUMBS_DOWN]: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
}));

function ThumbButton({ value, ...props }) {
  const classes = useStyles();
  const { icon, buttonLabel } = BUTTONS[value];

  return (
    <Button
      variant="outlined"
      classes={{
        root: clsx(classes.button, classes[value]),
      }}
      startIcon={icon}
      aria-label={`I had a ${buttonLabel} overall chat experience with Embold's Virtual Assistant`}
      {...props}
    >
      {buttonLabel}
    </Button>
  );
}

ThumbButton.propTypes = {
  value: PropTypes.oneOf([THUMBS_UP, THUMBS_DOWN]).isRequired,
};

export default ChatQuickFeedbackForm;
