import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ScrollToTop({ children }) {
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      // if hash is present, scroll element with hash id into view
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
        // always focus on element being scrolled to for accessibility, this requires the element to be focusable
        element.focus();
      }
    }
  }, [pathname, hash, key]);

  return children || null;
}

ScrollToTop.propTypes = {
  children: PropTypes.node,
};

ScrollToTop.defaultProps = {
  children: null,
};
