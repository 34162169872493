import * as results from './slices/results/resultsThunks';
import config from './slices/config/configThunks';
import * as filters from './slices/filters/filterThunks';
import * as search from './slices/search/searchThunks';
import * as services from './slices/services/servicesThunks';
import * as specialties from './slices/specialties/specialtiesThunks';
import * as location from './slices/location/locationThunks';
import * as chat from './slices/chat/chatThunks';
import * as rewards from './slices/rewards/rewardsThunks';
import * as promotions from './slices/promotions/promotionsThunks';
import * as cost from './slices/cost/costThunks';
import * as app from './appThunks';

const thunks = {
  app,
  chat,
  config,
  cost,
  filters,
  results,
  search,
  services,
  location,
  rewards,
  promotions,
  specialties,
};

export default thunks;
